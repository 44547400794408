import {
  Document,
  Text,
  Page,
  StyleSheet,
  Image,
  View,
} from "@react-pdf/renderer";

import Logo from "../assets/images/gminmediauto/logo1.jpg";
import AvisoPrivacidad from "../assets/images/gminmediauto/aviso-privacidad.png";
import { Watermark } from "antd";

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  watermark: {
    position: 'absolute',
    width: '50%',
    height: '10%',
    top: 210,
    left: 130,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.2,
  },
  sectionTitle: {
    margin: 0,
  },
  sectionHeader: {
    backgroundColor: "rgba(238, 49, 49, 1)",
    padding: 3,
  },
  section: {
    border: "1px solid rgba(0, 0, 0, 1)",
    borderRadius: 0,
    padding: 3,
    marginBottom: 10,
  },
  title: {
    fontSize: 9,
    fontWeight: 700,
    color: "rgba(255, 255, 255, 1)",
  },
  label: {
    fontSize: 4,
  },
  mainTitle: {
    fontSize: 12,
    fontWeight: 700,
    color: "rgba(238, 49, 49, 1)",
  },
  boxPromotion: {
    width: "100%",
    height: 100,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 1)",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 3,
  },
  rowContainer: {
    flexDirection: "row",
    margin: 1,
    padding: 1,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 1)",
  },
  columnDateText: {
    fontSize: 6,
    textAlign: "center",
  },
  columnDate: {
    fontSize: 6,
    borderBottomWidth: 1,
    borderBottomColor: "black",
  },
  columnTitle: {
    fontSize: 8,
    fontWeight: 700,
  },
  column: {
    fontSize: 8,
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "black",
    paddingLeft: 5,
  },
  columnFlex2: {
    fontSize: 8,
    flex: 2,
    borderBottomWidth: 1,
    borderBottomColor: "black",
    paddingLeft: 5,
  },
  columnFlex3: {
    fontSize: 8,
    flex: 3,
    borderBottomWidth: 1,
    borderBottomColor: "black",
    paddingLeft: 5,
  },
  columnFlex4: {
    fontSize: 8,
    flex: 4,
    borderBottomWidth: 1,
    borderBottomColor: "black",
    paddingLeft: 5,
  },
  columnFlex5: {
    fontSize: 8,
    flex: 5,
    borderBottomWidth: 1,
    borderBottomColor: "black",
    paddingLeft: 5,
  },
  columnFlex6: {
    fontSize: 8,
    flex: 6,
    borderBottomWidth: 1,
    borderBottomColor: "black",
    paddingLeft: 5,
  },
});

const PDF = (props) => {
  const { data } = props;

  const getCurrentDate = () => {
    const currentDate = new Date();
    const options = { year: "2-digit", month: "2-digit", day: "2-digit" };
    return currentDate.toLocaleDateString("es-ES", options).replace(/\//g, "-");
  };

  const getCurrency2FormatMX = (number) => {
    //get type of variable number
    if (typeof number === "string") {
      if (number === "") {
        number = 0;
      }
      else {
        number = parseFloat(number);
      }
    }
    return number.toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN", // Puedes cambiar 'MXN' por el código de moneda correspondiente
    });
  };

  return (
    <Document>
      <Page style={styles.page}>
      <Image style={styles.watermark} src={{ uri: '../img/logo1.jpg', method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} />
        <View style={styles.row}>
          <Text style={styles.mainTitle}>SOLICITUD DE PREAPROBACIÓN</Text>
          <Image src={{ uri: '../img/logo1.jpg', method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} style={{ width: 130, textAlign: "right" }} />
        </View>

        <View>
          <View style={styles.row}>
            <View style={{width: '70%'}}>
              <View style={styles.boxPromotion}>
                <Text style={styles.columnTitle}>PROMOCIÓN:</Text>
              </View>
              <View style={styles.row}>
                <Text></Text>
                <Text style={styles.label}>SELLO DE AUTORIZACIÓN/CANCELACIÓN</Text>
              </View>
            </View>

            <View>
              <View>
                <View style={styles.rowContainer}>
                  <Text style={styles.columnDateText}>
                    FECHA: {getCurrentDate()}
                  </Text>
                </View>
              </View>
              <View>
                <Image src={{ uri: '../img/aviso-privacidad.png', method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} style={{ width: 80 }} />
              </View>
            </View>
          </View>
        </View>

        <View>
          <View style={styles.row}>
            <Text style={{fontSize: 7, fontWeight:700, marginLeft: 150, marginTop: 10, marginBottom: 10}}>GM ORIENTACIÓN FINANCIERA AUTOMOTRIZ S.A.S. DE C.V.</Text>
            <Text></Text>
          </View>
        </View>

        <View style={styles.sectionHeader}>
          <Text style={styles.title}>1.- DATOS PERSONALES</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.columnTitle}>NOMBRE</Text>
            <Text style={styles.column}>
              {data.personal.general.nombre +
                " " +
                data.personal.general.apellidoPaterno +
                " " +
                data.personal.general.apellidoMaterno}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.columnTitle}>CURP</Text>
            <Text style={styles.column}>{data.personal.general.curp}</Text>

            <Text style={styles.columnTitle}>FECHA DE NAC.</Text>
            <Text style={styles.column}>
              {data.personal.general.fechaNacimiento}
            </Text>

            <Text style={styles.columnTitle}>E. CIVIL</Text>
            <Text style={styles.column}>
              {data.personal.general.estadoCivil}
            </Text>

            <Text style={styles.columnTitle}>TELÉFONO</Text>
            <Text style={styles.column}>{data.personal.general.telefono}</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.columnTitle}>NOMBRE DE CÓNYUGE</Text>
            <Text style={styles.column}>{data.personal.general.conyuge}</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.columnTitle}>DOMICILIO (CALLE Y COLONIA)</Text>
            <Text style={styles.column}>
              {data.personal.direccion.calle +
                " no." +
                data.personal.direccion.numeroExterior +
                (data.personal.direccion.numeroInterior !== ""
                  ? " int." + data.personal.direccion.numeroInterior
                  : "") +
                " - " +
                data.personal.direccion.colonia}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.columnTitle}>C.P.</Text>
            <Text style={styles.column}>{data.personal.direccion.cp}</Text>
            <Text style={styles.columnTitle}>MUNICIPIO, CIUDAD, ESTADO</Text>
            <Text style={styles.columnFlex4}>
              {data.personal.direccion.municipio +
                ", " +
                data.personal.direccion.ciudad +
                ", " +
                data.personal.direccion.estado}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.columnTitle}>VIVE CON</Text>
            <Text style={styles.column}>
              {data.personal.direccion.vivesCon}
            </Text>

            <Text style={styles.columnTitle}>CASA PROPIA/RENTADA</Text>
            <Text style={styles.column}>
              {data.personal.direccion.tipoDeDomicilio}
            </Text>

            <Text style={styles.columnTitle}>DEP. ECON.</Text>
            <Text style={styles.column}>
              {data.personal.general.numeroDependientesEconomicos}
            </Text>
          </View>
        </View>

        <View style={styles.sectionHeader}>
          <Text style={styles.title}>2.- DATOS LABORALES</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.columnTitle}>NOMBRE O RAZÓN SOCIAL</Text>
            <Text style={styles.column}>{data.empleo.empleo.empresa}</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.columnTitle}>DOMICILIO</Text>
            <Text style={styles.column}>
              {data.empleo.direccionDeLaEmpresa.calle +
                " no." +
                data.empleo.direccionDeLaEmpresa.numeroExterior +
                (data.empleo.direccionDeLaEmpresa.numeroInterior !== ""
                  ? " int." + data.empleo.direccionDeLaEmpresa.numeroInterior
                  : "") +
                "; " +
                data.empleo.direccionDeLaEmpresa.ciudad +
                ", " +
                data.empleo.direccionDeLaEmpresa.estado}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.columnTitle}>C.P.</Text>
            <Text style={styles.column}>
              {data.empleo.direccionDeLaEmpresa.cp}
            </Text>

            <Text style={styles.columnTitle}>Puesto</Text>
            <Text style={styles.columnFlex4}>{data.empleo.empleo.puesto}</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.columnTitle}>ANTIGÜEDAD</Text>
            <Text style={styles.column}>
              {data.empleo.empleo.tiempoEnElEmpleo}
            </Text>

            <Text style={styles.columnTitle}>FECHA DE INICIO LABORAL</Text>
            <Text style={styles.column}>
              {data.empleo.empleo.fechaDeInicioDeTrabajo}
            </Text>

            <Text style={styles.columnTitle}>INGRESO MENS.</Text>
            <Text style={styles.column}>
              {getCurrency2FormatMX(data.empleo.empleo.ingresoMensual)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.columnTitle}>INGRESOS EXTRA</Text>
            <Text style={styles.column}>{getCurrency2FormatMX(data.empleo.empleo.ingresoExtra)}</Text>

            <Text style={styles.columnTitle}>CRÉDITOS DISP.</Text>
            <Text style={styles.column}>
              {getCurrency2FormatMX(data.empleo.empleo.creditosDisponibles)}
            </Text>

            <Text style={styles.columnTitle}>TARJETA BANC.</Text>
            <Text style={styles.column}>
              {data.empleo.empleo.tarjetasBancarias}
            </Text>
          </View>
        </View>

        <View style={styles.sectionHeader}>
          <Text style={styles.title}>3.- DATOS DE FINANCIAMIENTO</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.columnTitle}>MARCA</Text>
            <Text style={styles.columnFlex3}>{data.financiamiento.marca}</Text>

            <Text style={styles.columnTitle}>LÍNEA</Text>
            <Text style={styles.columnFlex3}>{data.financiamiento.linea}</Text>

            <Text style={styles.columnTitle}>MODELO</Text>
            <Text style={styles.column}>{data.financiamiento.modelo}</Text>

            <Text style={styles.columnTitle}>DESCRIPCIÓN</Text>
            <Text style={styles.columnFlex6}>
              {data.financiamiento.version}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.columnTitle}>TIPO DE USO</Text>
            <Text style={styles.column}>{data.financiamiento.tipoDeUso}</Text>

            <Text style={styles.columnTitle}>OTRO:</Text>
            <Text style={styles.columnFlex3}>
              {data.financiamiento.otroUso}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.columnTitle}>MONTO</Text>
            <Text style={styles.column}>{getCurrency2FormatMX(data.financiamiento.monto)}</Text>

            <Text style={styles.columnTitle}>PLAZO</Text>
            <Text style={styles.column}>
              {data.financiamiento.plazo + " meses"}
            </Text>

            <Text style={styles.columnTitle}>MENSUALIDADES</Text>
            <Text style={styles.column}>
              {getCurrency2FormatMX(data.financiamiento.mensualidades)}
            </Text>

            <Text style={styles.columnTitle}>APERTURA</Text>
            <Text style={styles.column}>{getCurrency2FormatMX(data.financiamiento.apertura)}</Text>
          </View>
        </View>

        <View style={styles.sectionHeader}>
          <Text style={styles.title}>4.- DATOS DE REFERENCIAS</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.columnTitle}>NOMBRE REF.1</Text>
            <Text style={styles.column}>
              {data.referencias.referenciaPersonal.nombre +
                " " +
                data.referencias.referenciaPersonal.apellidoPaterno +
                " " +
                data.referencias.referenciaPersonal.apellidoMaterno}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.columnTitle}>TELÉFONO</Text>
            <Text style={styles.column}>
              {data.referencias.referenciaPersonal.telefono}
            </Text>

            <Text style={styles.columnTitle}>RELACIÓN</Text>
            <Text style={styles.column}>
              {data.referencias.referenciaPersonal.relacionConUsted}
            </Text>

            <Text style={styles.columnTitle}>TIEMPO DE CONOCERLE</Text>
            <Text style={styles.column}>
              {data.referencias.referenciaPersonal.tiempoDeConocerALaPersona}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.columnTitle}>NOMBRE REF.2</Text>
            <Text style={styles.column}>
              {data.referencias.referenciaPersonalSegundo.nombre +
                " " +
                data.referencias.referenciaPersonalSegundo.apellidoPaterno +
                " " +
                data.referencias.referenciaPersonalSegundo.apellidoMaterno}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.columnTitle}>TELÉFONO</Text>
            <Text style={styles.column}>
              {data.referencias.referenciaPersonalSegundo.telefono}
            </Text>

            <Text style={styles.columnTitle}>RELACIÓN</Text>
            <Text style={styles.column}>
              {data.referencias.referenciaPersonalSegundo.relacionConUsted}
            </Text>

            <Text style={styles.columnTitle}>TIEMPO DE CONOCERLE</Text>
            <Text style={styles.column}>
              {
                data.referencias.referenciaPersonalSegundo
                  .tiempoDeConocerALaPersona
              }
            </Text>
          </View>
        </View>

        <Text style={{ fontSize: 6, textAlign: "center" }}>Al firmar esta solicitud usted da a constar toda la información proporcionada para su elaboración es verdadera y fideligna, así mismo acepta los términos del aviso de privacidad que se menciona en el sitio web de la empresa y la utilización de sus datos para todo lo que conlleve un proceso crediticio de investigación de su perfil y/o la corroboración de datos a sus referencias mencionadas por usted mismo anteriormente.</Text>
        <Text style={{ fontSize: 6, textAlign: "center" }}>Todo seguimiento de su caso se dará por el asesor que firme la solicitud y cualquier duda o aclaración quedará al mismo por medio de la presente, al menos durante el tiempo que sea pertinente en caso por separado y esto se determinará en último caso por los superiores a este.</Text>

        <Text style={{ fontSize: 6, textAlign: "center", marginTop: 5 }}>Todos los derechos reservados, el llenado y el cumplimiento correcto de esta solicitud dependerá única y exclusivamente del asesor encargado del caso y su uso correcto no garantiza por si solo que se apruebe ninguno de los beneficios de campaña que se manejan sin antes pasar por el departamento de validación. La empresa no tiene el derecho de vender los datos que se manejen a continuación para beneficio privado o malversación para nada que no tenga que ver con fines estadísticos, financieros y administrativos</Text>
        
        {/* Pedir firma del solicitante y del asesor de ventas*/}
        <View style={styles.row}>
          <Text style={{ fontSize: 8, textAlign: "center", flex: 1 }}></Text>
          <Text style={{ fontSize: 8, textAlign: "center", flex: 1, borderTopWidth: 1, borderColor: 'black', marginTop: 100, paddingTop: 3 }}>Firma del solicitante</Text>
          <Text style={{ fontSize: 8, textAlign: "center", flex: 1 }}></Text>
          <Text style={{ fontSize: 8, textAlign: "center", flex: 1, borderTopWidth: 1, borderColor: 'black', marginTop: 100, paddingTop: 3 }}>Asesor de ventas</Text>
          <Text style={{ fontSize: 8, textAlign: "center", flex: 1 }}></Text>
        </View>
      </Page>
    </Document>
  );
};

export default PDF;
