import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import SettingsIcon from "@mui/icons-material/Settings";
import HandshakeIcon from "@mui/icons-material/Handshake";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import ImgNissan from "../assets/images/brands/nissan.png";
import ImgVolkswagen from "../assets/images/brands/volkswagen.png";
import ImgChevrolet from "../assets/images/brands/chevrolet.png";
import ImgSuzuki from "../assets/images/brands/suzuki.png";
import ImgSeat from "../assets/images/brands/seat.png";
import ImgFaw from "../assets/images/brands/faw.png";
import ImgFiat from "../assets/images/brands/fiat.png";
import ImgKia from "../assets/images/brands/kia.png";
import ImgBMW from "../assets/images/brands/bmw.png";
import ImgChangan from "../assets/images/brands/changan.png";
import ImgAudi from "../assets/images/brands/audi.png";
import ImgHonda from "../assets/images/brands/honda.png";
import ImgFord from "../assets/images/brands/ford.png";
import ImgMini from "../assets/images/brands/mini.png";
import ImgToyota from "../assets/images/brands/toyota.png";
import ImgJeep from "../assets/images/brands/jeep.png";
import ImgHyundai from "../assets/images/brands/hyundai.png";
import ImgMazda from "../assets/images/brands/mazda.png";
import ImgMitsubishi from "../assets/images/brands/mitsubishi.png";
import ImgMG from "../assets/images/brands/mg.png";
import ImgMercedesBenz from "../assets/images/brands/mercedesbenz.png";
import ImgRenault from "../assets/images/brands/renault.png";
import ImgRam from "../assets/images/brands/ram.png";
import ImgDodge from "../assets/images/brands/dodge.png";
import ImgJac from "../assets/images/brands/jac.png";
import ImgGMC from "../assets/images/brands/gmc.png";

const AboutSlider = () => {
  const swiperRef = React.useRef(null);
  return (
    <section className="content-inner-2">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="section-head row align-items-end">
              <div className="col-sm-8">
                <div className="search-bx ">
                  <h2 className="title">Manejamos diferentes marcas</h2>
                </div>
              </div>
              <div className="col-sm-4 text-sm-end">
                <div className="portfolio-pagination d-inline-block">
                  <div
                    className="btn-prev swiper-button-prev3 cursorPointer"
                    onClick={() => {
                      swiperRef.current.swiper.slidePrev();
                    }}
                  >
                    <i className="las la-arrow-left"></i>
                  </div>
                  <div
                    className="btn-next swiper-button-next3 cursorPointer"
                    onClick={() => {
                      swiperRef.current.swiper.slideNext();
                    }}
                  >
                    <i className="las la-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <Swiper
              className="swiper-container city-swiper"
              style={{ overflowX: "hidden" }}
              spaceBetween={30}
              slidesPerView={5}
              speed={1200}
              ref={swiperRef}
              modules={[Autoplay]}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                1200: {
                  slidesPerView: 5,
                },
                1100: {
                  slidesPerView: 4,
                },
                775: {
                  slidesPerView: 3,
                },
                500: {
                  slidesPerView: 2,
                },
                240: {
                  slidesPerView: 1,
                },
              }}
            >
              <SwiperSlide className="swiper-slide">
                <Link to="vehiculos?marca=Nissan">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect m-b30 img-focus">
                    <div className="icon-md m-b20">
                      <img src={ImgNissan} alt="Nissan" className="img-white" />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Nissan
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
                <Link to="vehiculos?marca=Volkswagen">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect img-focus">
                    <div className="icon-md m-b20">
                      <img
                        src={ImgVolkswagen}
                        alt="Volkswagen"
                        className="img-white"
                      />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Volkswagen
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
              </SwiperSlide>

              <SwiperSlide className="swiper-slide">
                <Link to="vehiculos?marca=Chevrolet">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect m-b30 img-focus">
                    <div className="icon-md m-b20">
                      <img
                        src={ImgChevrolet}
                        alt="Chevrolet"
                        className="img-red img-white"
                      />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Chevrolet
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
                <Link to="vehiculos?marca=Suzuki">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect img-focus">
                    <div className="icon-md m-b20">
                      <img
                        src={ImgSuzuki}
                        alt="Suzuki"
                        className="img-red img-white"
                      />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Suzuki
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
              </SwiperSlide>

              <SwiperSlide className="swiper-slide">
                <Link to="vehiculos?marca=Seat">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect m-b30 img-focus">
                    <div className="icon-md m-b20">
                      <img src={ImgSeat} alt="Seat" className="img-white" />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Seat
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
                <Link to="vehiculos?marca=FAW">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect img-focus">
                    <div className="icon-md m-b20">
                      <img src={ImgFaw} alt="Faw" className="img-white" />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          FAW
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
              </SwiperSlide>

              <SwiperSlide className="swiper-slide">
                <Link to="vehiculos?marca=FIAT">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect m-b30 img-focus">
                    <div className="icon-md m-b20">
                      <img
                        src={ImgFiat}
                        alt="Fiat"
                        className="img-red img-white"
                      />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          FIAT
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
                <Link to="vehiculos?marca=KIA">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect img-focus">
                    <div className="icon-md m-b20">
                      <img
                        src={ImgKia}
                        alt="Kia"
                        className="img-red img-white"
                      />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          KIA
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
              </SwiperSlide>

              <SwiperSlide className="swiper-slide">
                <Link to="vehiculos?marca=BMW">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect m-b30 img-focus">
                    <div className="icon-md m-b20">
                      <img src={ImgBMW} alt="BMW" className="img-white" />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Bmw
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
                <Link to="vehiculos?marca=Changan">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect img-focus">
                    <div className="icon-md m-b20">
                      <img
                        src={ImgChangan}
                        alt="Changan"
                        className="img-white"
                      />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Changan
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
              </SwiperSlide>

              <SwiperSlide className="swiper-slide">
                <Link to="vehiculos?marca=Audi">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect m-b30 img-focus">
                    <div className="icon-md m-b20">
                      <img
                        src={ImgAudi}
                        alt="Audi"
                        className="img-red img-white"
                      />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Audi
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
                <Link to="vehiculos?marca=Honda">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect img-focus">
                    <div className="icon-md m-b20">
                      <img
                        src={ImgHonda}
                        alt="Honda"
                        className="img-red img-white"
                      />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Honda
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
              </SwiperSlide>

              <SwiperSlide className="swiper-slide">
                <Link to="vehiculos?marca=Ford">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect m-b30 img-focus">
                    <div className="icon-md m-b20">
                      <img src={ImgFord} alt="Ford" className="img-white" />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Ford
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
                <Link to="vehiculos?marca=Mini">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect img-focus">
                    <div className="icon-md m-b20">
                      <img src={ImgMini} alt="Mini" className="img-white" />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Mini
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
              </SwiperSlide>

              <SwiperSlide className="swiper-slide">
                <Link to="vehiculos?marca=Toyota">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect m-b30 img-focus">
                    <div className="icon-md m-b20">
                      <img
                        src={ImgToyota}
                        alt="Toyota"
                        className="img-red img-white"
                      />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Toyota
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
                <Link to="vehiculos?marca=Jeep">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect img-focus">
                    <div className="icon-md m-b20">
                      <img
                        src={ImgJeep}
                        alt="Jeep"
                        className="img-red img-white"
                      />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Jeep
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
              </SwiperSlide>

              <SwiperSlide className="swiper-slide">
                <Link to="vehiculos?marca=Hyundai">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect m-b30 img-focus">
                    <div className="icon-md m-b20">
                      <img
                        src={ImgHyundai}
                        alt="Hyundai"
                        className="img-white"
                      />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Hyundai
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
                <Link to="vehiculos?marca=Mazda">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect img-focus">
                    <div className="icon-md m-b20">
                      <img src={ImgMazda} alt="Mazda" className="img-white" />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Mazda
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
              </SwiperSlide>

              <SwiperSlide className="swiper-slide">
                <Link to="vehiculos?marca=Mitsubishi">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect m-b30 img-focus">
                    <div className="icon-md m-b20">
                      <img
                        src={ImgMitsubishi}
                        alt="Mitsubishi"
                        className="img-red img-white"
                      />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Mitsubishi
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
                <Link to="vehiculos?marca=MG">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect img-focus">
                    <div className="icon-md m-b20">
                      <img src={ImgMG} alt="MG" className="img-red img-white" />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          MG
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
              </SwiperSlide>

              <SwiperSlide className="swiper-slide">
                <Link to="vehiculos?marca=Mercedes-Benz">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect m-b30 img-focus">
                    <div className="icon-md m-b20">
                      <img
                        src={ImgMercedesBenz}
                        alt="MercedezBenz"
                        className="img-white"
                      />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Mercedez Benz
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
                <Link to="vehiculos?marca=Renault">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect img-focus">
                    <div className="icon-md m-b20">
                      <img
                        src={ImgRenault}
                        alt="Renault"
                        className="img-white"
                      />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          Renault
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
              </SwiperSlide>

              <SwiperSlide className="swiper-slide">
                <Link to="vehiculos?marca=RAM">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect m-b30 img-focus">
                    <div className="icon-md m-b20">
                      <img
                        src={ImgRam}
                        alt="Ram"
                        className="img-red img-white"
                      />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          RAM
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
                <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect img-focus">
                  <div className="icon-md m-b20">
                    <img
                      src={ImgDodge}
                      alt="Dodge"
                      className="img-red img-white"
                    />
                  </div>
                  <div className="icon-content">
                    <h6 className="title m-t10 m-b0">
                      <Link
                        to="used-car-search-result"
                        className="text-effect-1"
                        data-splitting
                      >
                        Dodge
                      </Link>
                    </h6>
                  </div>
                  <div className="effect bg-primary"></div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="swiper-slide">
                <Link to="vehiculos?marca=JAC">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect m-b30 img-focus">
                    <div className="icon-md m-b20">
                      <img src={ImgJac} alt="Jac" className="img-white" />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          JAC
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
                <Link to="vehiculos?marca=GMC">
                  <div className="icon-bx-wraper style-1 box-sm text-center shadow-none border hover-overlay-effect img-focus">
                    <div className="icon-md m-b20">
                      <img src={ImgGMC} alt="GMC" className="img-white" />
                    </div>
                    <div className="icon-content">
                      <h6 className="title m-t10 m-b0">
                        <Link
                          to="used-car-search-result"
                          className="text-effect-1"
                          data-splitting
                        >
                          GMC
                        </Link>
                      </h6>
                    </div>
                    <div className="effect bg-primary"></div>
                  </div>
                </Link>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSlider;
