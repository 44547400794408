import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import { useSession } from "../contexts/SessionContext";

const FormularioInicioSesionAdminPagina = (props) => {
  const { route } = props;

  const { login } = useSession();
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  const handleUserChange = (event) => {
    setUser(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let formData = new FormData();
    formData.append("user", user);
    formData.append("password", password);

    try {
      const response = await axios.post(route, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.code === 200) {
        login({ user: response.data.data.usuario });
        navigate("/administrar-vehiculos");
        alert("Inicio de sesión exitoso");
      } else {
        alert("Inicio de sesión fallido");
      }
    } catch (error) {
      console.error("Error al iniciar sesión", error);
    }
  };

  return (
    <>
      <div className="col-xl-6 m-b40">
        <div className="contact-area1">
          <form className="dlab-form style-1 dzForm" onSubmit={handleSubmit}>
            <div className="dzFormMsg"></div>

            <div className="row sp10">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Usuario</label>
                  <div className="input-group">
                    <input
                      value={user}
                      onChange={handleUserChange}
                      type="text"
                      required
                      className="form-control"
                      name="dzOther[subject]"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="form-group">
                  <label>Contraseña</label>
                  <div className="input-group">
                    <input
                      value={password}
                      onChange={handlePasswordChange}
                      type="password"
                      required
                      className="form-control"
                      name="dzOther[subject]"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <button
                  name="submit"
                  type="submit"
                  value="submit"
                  className="btn btn-primary w-100 d-block btn-rounded"
                >
                  Iniciar Sesión
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormularioInicioSesionAdminPagina;
