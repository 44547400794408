import React, { useState, useEffect } from "react";
import axios from "axios";
import SearchCarBanner from "../components/SearchCarBanner";
import Home3bannerSlide from "../element/Home3bannerSlide";
import CityCardSlider from "../components/CityCardSlider";
import ContectUs from "../components/ContectUs";
import Pasos from "../components/Pasos";
import Carlist from "../element/ListaAutosCuatro";
import { act } from "react-dom/test-utils";

import { useBaseUrl } from "../contexts/BaseUrlContext";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import FiltroCotizador from "../element/FiltroCotizador";
import DetalleAutoCotizador from "../components/DetalleAutoCotizador";
import { IMAGE } from "../constent/theme";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { G, pdf, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PDF from "../components/cotizacionPDF";

const Cotizador = () => {
  const [cars, setCars] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const { baseUrl } = useBaseUrl();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialBrand = queryParams.get("marca");
  const initialLine = queryParams.get("vehiculo");
  const initialModel = queryParams.get("modelo");

  const title = "Leasing";
  const [data, setData] = useState({
    auto: {},
    filtros: {
      regimen: "",
      plazos: 24,
      aseguradora: "",
      gps: "",
      seguroDesempleo: "",
      seguroVida: "",
      pagoApertura: "",
    },
    cotizacion: {
      precioIVA: 0,
      precioSinIVA: 0,
      renta: {
        rentaMensual: [0, 0, 0], // 24, 36, 48 meses
        IVA: [0, 0, 0], // 24, 36, 48 meses
        rentaTotal: [0, 0, 0], // 24, 36, 48 meses
      },
      pagoInicial: {
        aportacionInicial: [0, 0, 0], // 24, 36, 48 meses
        seguroPrimerAnio: [0, 0, 0], // 24, 36, 48 meses
        localizador: [0, 0, 0], // 24, 36, 48 meses
        comisionApertura: [0, 0, 0], // 24, 36, 48 meses
        totalPagoInicial: [0, 0, 0], // 24, 36, 48 meses
      },
      valorResidualConIVA: {
        valorResidualConIVA: [0, 0, 0], // 24, 36, 48 meses
        desembolsoTotal: [0, 0, 0], // 24, 36, 48 meses
        ahorroISR: [0, 0, 0], // 24, 36, 48 meses
        IVAAcreditable: [0, 0, 0], // 24, 36, 48 meses
        costoNeto: [0, 0, 0], // 24, 36, 48 meses
        costoNetoMasValorResidual: [0, 0, 0], // 24, 36, 48 meses
        costoNetoVSPrecioUnidad: [0, 0, 0], // 24, 36, 48 meses
      },
      calculoAplicadoConOpcionDeGasto: {
        ahorroISR: [0, 0, 0], // 24, 36, 48 meses
        IVAAcreditable: [0, 0, 0], // 24, 36, 48 meses
        costoNeto: [0, 0, 0], // 24, 36, 48 meses
        costoNetoMasValorResidual: [0, 0, 0], // 24, 36, 48 meses
        costoNetoVSPrecioUnidad: [0, 0, 0], // 24, 36, 48 meses
      },
    },
  });

  const steps = ["Vehículo", "Resumen de cotización"];
  const [activeStep, setActiveStep] = React.useState(0);
  //pasos completados
  const [completedSteps, setCompletedSteps] = useState([0]);
  //todos los pasos completados
  const [completed, setCompleted] = React.useState({});

  const getCarList = async () => {
    try {
      const response = await axios.get(
        baseUrl + "restauto",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        //console.log(response.data.data);
        setCars(response.data.data);
        setInitialized(true);
      } else {
        console.log("Error loading cars");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const calculateLeasing = (priceWithIVA) => {
    const priceWithoutIVA = priceWithIVA / 1.16;

    //Calculo de renta mensual
    const monthlyPayment24 = (priceWithoutIVA * 2.74) / 100;
    const monthlyPayment36 = (priceWithoutIVA * 2.5) / 100;
    const monthlyPayment48 = (priceWithoutIVA * 2.35) / 100;

    const monthlyPayment24IVA = monthlyPayment24 * 0.16;
    const monthlyPayment36IVA = monthlyPayment36 * 0.16;
    const monthlyPayment48IVA = monthlyPayment48 * 0.16;

    const monthlyPayment24WithIVA = monthlyPayment24 + monthlyPayment24IVA;
    const monthlyPayment36WithIVA = monthlyPayment36 + monthlyPayment36IVA;
    const monthlyPayment48WithIVA = monthlyPayment48 + monthlyPayment48IVA;

    //Calculo de pago inicial
    const initialContribution = (priceWithIVA * 20) / 100;

    const insuranceFirstYear = (priceWithIVA * 6) / 100;

    const gps24 = 3000 + 300 * 24;
    const gps36 = 3000 + 300 * 36;
    const gps48 = 3000 + 300 * 48;

    const commissionForOpening = (priceWithIVA * 3) / 100;

    const initialPayment24 =
      initialContribution + insuranceFirstYear + gps24 + commissionForOpening;
    const initialPayment36 =
      initialContribution + insuranceFirstYear + gps36 + commissionForOpening;
    const initialPayment48 =
      initialContribution + insuranceFirstYear + gps48 + commissionForOpening;

    //Calculo de valor residual con IVA
    const residualValueWithIVA24 = (priceWithIVA * 50) / 100;
    const residualValueWithIVA36 = (priceWithIVA * 40) / 100;
    const residualValueWithIVA48 = (priceWithIVA * 30) / 100;

    const disbursementTotal24 = (priceWithoutIVA * 107.5) / 100;
    const disbursementTotal36 = (priceWithoutIVA * 135.81) / 100;
    const disbursementTotal48 = (priceWithoutIVA * 162.3) / 100;

    const savingISR24 = (priceWithoutIVA * 18.32) / 100;
    const savingISR36 = (priceWithoutIVA * 25.36) / 100;
    const savingISR48 = (priceWithoutIVA * 32.28) / 100;

    const creditableIVA24 = (priceWithoutIVA * 9.77) / 100;
    const creditableIVA36 = (priceWithoutIVA * 13.53) / 100;
    const creditableIVA48 = (priceWithoutIVA * 17.22) / 100;

    const netCost24 = (priceWithoutIVA * 79.42) / 100;
    const netCost36 = (priceWithoutIVA * 96.92) / 100;
    const netCost48 = (priceWithoutIVA * 112.8) / 100;

    const netCostPlusResidualValue24 = (priceWithoutIVA * 137.42) / 100;
    const netCostPlusResidualValue36 = (priceWithoutIVA * 143.32) / 100;
    const netCostPlusResidualValue48 = (priceWithoutIVA * 147.6) / 100;

    const netCostVSPriceUnit24 = (priceWithoutIVA * 21.42) / 100;
    const netCostVSPriceUnit36 = (priceWithoutIVA * 27.32) / 100;
    const netCostVSPriceUnit48 = (priceWithoutIVA * 31.6) / 100;

    //Calculo aplicado con opcion de gasto

    const savingISR24WithExpense = (priceWithoutIVA * 27.8) / 100;
    const savingISR36WithExpense = (priceWithoutIVA * 35.13) / 100;
    const savingISR48WithExpense = (priceWithoutIVA * 41.98) / 100;

    const creditableIVA24WithExpense = (priceWithoutIVA * 14.83) / 100;
    const creditableIVA36WithExpense = (priceWithoutIVA * 18.74) / 100;
    const creditableIVA48WithExpense = (priceWithoutIVA * 22.39) / 100;

    const netCost24WithExpense = (priceWithoutIVA * 64.87) / 100;
    const netCost36WithExpense = (priceWithoutIVA * 90.29) / 100;
    const netCost48WithExpense = (priceWithoutIVA * 97.94) / 100;

    const netCostPlusResidualValue24WithExpense =
      (priceWithoutIVA * 122.87) / 100;
    const netCostPlusResidualValue36WithExpense =
      (priceWithoutIVA * 140.29) / 100;
    const netCostPlusResidualValue48WithExpense =
      (priceWithoutIVA * 132.74) / 100;

    const netCostVSPriceUnit24WithExpense = (priceWithoutIVA * 6.87) / 100;
    const netCostVSPriceUnit36WithExpense = (priceWithoutIVA * 12.36) / 100;
    const netCostVSPriceUnit48WithExpense = (priceWithoutIVA * 16.74) / 100;

    return {
      precioIVA: priceWithIVA,
      precioSinIVA: priceWithoutIVA,
      renta: {
        rentaMensual: [monthlyPayment24, monthlyPayment36, monthlyPayment48], // 24, 36, 48 meses
        IVA: [monthlyPayment24IVA, monthlyPayment36IVA, monthlyPayment48IVA], // 24, 36, 48 meses
        rentaTotal: [
          monthlyPayment24WithIVA,
          monthlyPayment36WithIVA,
          monthlyPayment48WithIVA,
        ], // 24, 36, 48 meses
      },
      pagoInicial: {
        aportacionInicial: [
          initialContribution,
          initialContribution,
          initialContribution,
        ], // 24, 36, 48 meses
        seguroPrimerAnio: [
          insuranceFirstYear,
          insuranceFirstYear,
          insuranceFirstYear,
        ], // 24, 36, 48 meses
        localizador: [gps24, gps36, gps48], // 24, 36, 48 meses
        comisionApertura: [
          commissionForOpening,
          commissionForOpening,
          commissionForOpening,
        ], // 24, 36, 48 meses
        totalPagoInicial: [
          initialPayment24,
          initialPayment36,
          initialPayment48,
        ], // 24, 36, 48 meses
      },
      valorResidualConIVA: {
        valorResidualConIVA: [
          residualValueWithIVA24,
          residualValueWithIVA36,
          residualValueWithIVA48,
        ], // 24, 36, 48 meses
        desembolsoTotal: [
          disbursementTotal24,
          disbursementTotal36,
          disbursementTotal48,
        ], // 24, 36, 48 meses
        ahorroISR: [savingISR24, savingISR36, savingISR48], // 24, 36, 48 meses
        IVAAcreditable: [creditableIVA24, creditableIVA36, creditableIVA48], // 24, 36, 48 meses
        costoNeto: [netCost24, netCost36, netCost48], // 24, 36, 48 meses
        costoNetoMasValorResidual: [
          netCostPlusResidualValue24,
          netCostPlusResidualValue36,
          netCostPlusResidualValue48,
        ], // 24, 36, 48 meses
        costoNetoVSPrecioUnidad: [
          netCostVSPriceUnit24,
          netCostVSPriceUnit36,
          netCostVSPriceUnit48,
        ], // 24, 36, 48 meses
      },
      calculoAplicadoConOpcionDeGasto: {
        ahorroISR: [
          savingISR24WithExpense,
          savingISR36WithExpense,
          savingISR48WithExpense,
        ], // 24, 36, 48 meses
        IVAAcreditable: [
          creditableIVA24WithExpense,
          creditableIVA36WithExpense,
          creditableIVA48WithExpense,
        ], // 24, 36, 48 meses
        costoNeto: [
          netCost24WithExpense,
          netCost36WithExpense,
          netCost48WithExpense,
        ], // 24, 36, 48 meses
        costoNetoMasValorResidual: [
          netCostPlusResidualValue24WithExpense,
          netCostPlusResidualValue36WithExpense,
          netCostPlusResidualValue48WithExpense,
        ], // 24, 36, 48 meses
        costoNetoVSPrecioUnidad: [
          netCostVSPriceUnit24WithExpense,
          netCostVSPriceUnit36WithExpense,
          netCostVSPriceUnit48WithExpense,
        ], // 24, 36, 48 meses
      },
    };
  };

  const handleSelectedCar = (car) => {
    setCompletedSteps([...completedSteps, activeStep]);
    setActiveStep(activeStep + 1);
    setData({
      ...data,
      auto: car,
      cotizacion: calculateLeasing(car.precioLista),
    });
    //console.log(car);
  };

  useEffect(() => {
    getCarList();
  }, []);

  if (!initialized) {
    return null;
  }
  return (
    <>
      <div className="page-content bg-white">
        <Pasos
          steps={steps}
          title={title}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          completed={completed}
          setCompleted={setCompleted}
          completedSteps={completedSteps}
          setCompletedSteps={setCompletedSteps}
        />
        {activeStep === 0 && (
          <Carlist cars={cars} handleSelectedCar={handleSelectedCar} routeImg={"https://gminmediauto.com.mx/imgAutos/"} initialBrand={initialBrand} initialLine={initialLine} initialModel={initialModel}/>
        )}
        {activeStep === 1 && <FiltersAndPage data={data} setData={setData} />}
      </div>
    </>
  );
};

const FiltersAndPage = (props) => {
  const { baseUrl } = useBaseUrl();
  const navigate = useNavigate();
  const { data, setData } = props;
  const CarList2 = [
    { img: IMAGE.productPic1 },
    { img: IMAGE.productPic2 },
    { img: IMAGE.productPic3 },
    { img: IMAGE.productPic4 },
    { img: IMAGE.productPic5 },
    { img: IMAGE.productGridPic1 },
  ];
  const [selectBtn, setSelectBtn] = useState("Newest");
  const [bgColor, setColorBtn] = useState(2);
  const [isVisible, setIsVisible] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const getCurrency2FormatMX = (number) => {
    //get type of variable number
    if (typeof number === "string") {
      number = parseFloat(number);
    }
    return number.toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN", // Puedes cambiar 'MXN' por el código de moneda correspondiente
    });
  };

  const getMonthlyPayment = (periods) => {
    let value = 0;
    if (periods === 24) value = data.cotizacion.renta.rentaTotal[0];
    if (periods === 36) value = data.cotizacion.renta.rentaTotal[1];
    if (periods === 48) value = data.cotizacion.renta.rentaTotal[2];
    return value;
  };

  const getTotalOpeningPayment = (periods) => {
    let value = 0;
    if (periods === 24) value = data.cotizacion.pagoInicial.totalPagoInicial[0];
    if (periods === 36) value = data.cotizacion.pagoInicial.totalPagoInicial[1];
    if (periods === 48) value = data.cotizacion.pagoInicial.totalPagoInicial[2];
    return value;
  };

  const getOpeningPayment = (periods) => {
    let value = 0;
    if (periods === 24)
      value = data.cotizacion.pagoInicial.aportacionInicial[0];
    if (periods === 36)
      value = data.cotizacion.pagoInicial.aportacionInicial[1];
    if (periods === 48)
      value = data.cotizacion.pagoInicial.aportacionInicial[2];
    return value;
  };

  const getSecureFirstYear = (periods) => {
    let value = 0;
    if (periods === 24) value = data.cotizacion.pagoInicial.seguroPrimerAnio[0];
    if (periods === 36) value = data.cotizacion.pagoInicial.seguroPrimerAnio[1];
    if (periods === 48) value = data.cotizacion.pagoInicial.seguroPrimerAnio[2];
    return value;
  };

  const handleSubmitSendData = (e) => {
    e.preventDefault();
    setShowLoading(true);

    const generatePDF = async () => {
      const blob = await pdf(<PDF data={data} />).toBlob();
      sendPDF(blob);
    };

    generatePDF();

    const sendPDF = async (blob) => {
      try {
        const formData = new FormData();
        formData.append("file", blob, "leasing.pdf");
        formData.append("name", name);
        formData.append("lastName", lastName);
        formData.append("email", email);
        formData.append("phone", phone);
        const response = await axios.post(
          baseUrl + "hola",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          //console.log(response.data.data);
          setIsVisible(false);
          setShowLoading(false);
          setName("");
          setLastName("");
          setEmail("");
          setPhone("");
          alert("Cotización enviada correctamente");
        } else {
          console.log("Error loading leasing");
          setShowLoading(false);
          alert("Cotización no ha podido ser enviada, intenta más tarde");
        }
      } catch (error) {
        console.error(error);
      }
    };
  };

  return (
    <div className="container">
      <div className="row">
        {/* ==============Car listing filters >>>>>>>> */}
        <FiltroCotizador
          setData={setData}
          data={data}
        />
        {/* ===============Car sort by >>>>>>>> */}
        <div className="col-xl-8 col-lg-8 m-b40">
          <DetalleAutoCotizador textTitleOn={false} data={data} />
          <div className="contact-area-preaprobacion-1">
            <div className="dlab-form style-1 dzForm">
              <input
                type="hidden"
                className="form-control"
                name="dzToDo"
                value="Contact"
              />
              <input
                type="hidden"
                className="form-control"
                name="reCaptchaEnable"
                value="0"
              />
              <div className="dzFormMsg"></div>
              <div className="row sp10">
                <div className="col-sm-6 resumen-head">
                  <div className="form-group">
                    <label className="font-weight-600 resumen-padding-labels resumen-label-color">
                      Mensualidad
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 resumen-head">
                  <div className="form-group">
                    <label className="font-weight-600 resumen-padding-labels resumen-label-color d-flex justify-content-end">
                      {getCurrency2FormatMX(
                        getMonthlyPayment(data.filtros.plazos)
                      )}{" "}
                      MXN
                    </label>
                  </div>
                </div>

                <label className=" resumen-title-label">
                  Resumen de cotización
                </label>

                <h4 className="resumen-padding-title-2">Mensualidad</h4>
                <div className="resumen-row-space-20"></div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="font-weight-400">
                      Mensualidad sin accesorios
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 d-flex  justify-content-end">
                  <div className="form-group">
                    <label className="font-weight-400">
                      {getCurrency2FormatMX(
                        getMonthlyPayment(data.filtros.plazos)
                      )}
                    </label>
                  </div>
                </div>
                <div className="resumen-row-space-30"></div>

                <div className="container-resumen-border"></div>
                <div className="resumen-row-space-30"></div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="font-weight-600 ">
                      Pago Total Por Apertura
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 d-flex justify-content-end">
                  <div className="form-group">
                    <label className="font-weight-600 ">
                      {getCurrency2FormatMX(
                        getTotalOpeningPayment(data.filtros.plazos)
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 resumen-row-space-20">
                  <div className="form-group">
                    <label className="font-weight-400 ">
                      Pago por apertura
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 d-flex resumen-row-space-20 justify-content-end">
                  <div className="form-group">
                    <label className="font-weight-400">
                      {getCurrency2FormatMX(
                        getOpeningPayment(data.filtros.plazos)
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 resumen-row-space-20">
                  <div className="form-group">
                    <label className="font-weight-400">Seguro de contado</label>
                  </div>
                </div>
                <div className="col-sm-6 d-flex resumen-row-space-20 justify-content-end">
                  <div className="form-group">
                    <label className="font-weight-400">
                      {getCurrency2FormatMX(
                        getSecureFirstYear(data.filtros.plazos)
                      )}
                    </label>
                  </div>
                </div>
                <div className="container-resumen-border"></div>
                <div className="resumen-row-space"></div>

                <div className="section text-center">
                  <label className="text-primary font-weight-400 resumen-padding-label-2">
                    Para ver más información de tu cotización da clic en enviar
                    cotización
                  </label>
                </div>
                <div className="col-sm-12">
                  <button
                    name="submit"
                    type="submit"
                    value="submit"
                    className="btn btn-primary w-100 d-block btn-rounded resumen-btn"
                    onClick={() =>
                      navigate("/preaprobacion", { state: { data } })
                    }
                  >
                    <div className="row">
                      <div className="col-11">Solicita tu pre-aprobación</div>
                      <div className="col-1" style={{ textAlign: "right" }}>
                        <ArrowForwardIosIcon />
                      </div>
                    </div>
                  </button>
                  <div
                    name="submit"
                    type="submit"
                    value="submit"
                    onClick={toggleVisibility}
                    className="btn btn-white w-100 d-block resumen-btn without-border"
                  >
                    <div className="row">
                      <div className="col-11">Enviar cotización completa</div>
                      <div className="col-1" style={{ textAlign: "right" }}>
                        {isVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isVisible && (
            <div className="col-xl-6.m-b40">
              <div className="contact-area-preaprobacion">
                <form
                  className="dlab-form style-1 dzForm"
                  onSubmit={(e) => {
                    handleSubmitSendData(e);
                  }}
                >
                  <input
                    type="hidden"
                    className="form-control"
                    name="dzToDo"
                    value="Contact"
                  />
                  <input
                    type="hidden"
                    className="form-control"
                    name="reCaptchaEnable"
                    value="0"
                  />
                  <div className="dzFormMsg"></div>
                  <div className="row sp10">
                    <div className="resumen-row-space"></div>
                    {!showLoading ? (
                      <>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="font-weight-400">Nombre(s)</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                                required
                                name="dzOther[Name]"
                                placeholder=""
                                value={name}
                                onChange={handleNameChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="font-weight-400">
                              Apellido(s)
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                                required
                                name="dzOther[Apellidos]"
                                placeholder=""
                                value={lastName}
                                onChange={handleLastNameChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="font-weight-400">
                              Correo electrónico
                            </label>
                            <div className="input-group">
                              <input
                                type="email"
                                className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                                required
                                name="dzOther[email]"
                                placeholder=""
                                value={email}
                                onChange={handleEmailChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="font-weight-400">Teléfono</label>
                            <div className="input-group">
                              <input
                                type="tel"
                                className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                                required
                                name="dzOther[Telefono]"
                                placeholder=""
                                value={phone}
                                onChange={handlePhoneChange}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress style={{ color: '#ff5269', width: '100px', height: '100px' }}/>
                      </div>
                    )}

                    <div className="resumen-row-space"></div>
                    <div className="row justify-content-center">
                      <div className="col-lg-4 col-md-6">
                        <button
                          name="submit"
                          type="submit"
                          value="submit"
                          className="btn btn-primary w-100 d-block btn-rounded resumen-btn"
                          style={{ marginLeft: "20px" }}
                          disabled={showLoading}
                        >
                          Enviar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
        {/* =============================================== */}
      </div>
    </div>
  );
};

const GeneratorPDF = (props) => {
  const { data } = props;
  return (
    <div>
      <PDFDownloadLink document={<PDF data={data} />} fileName="myfirstpdf.pdf">
        {({ loading, url, error, blob }) =>
          loading ? (
            <button>Loading Document ...</button>
          ) : (
            <button>Download now!</button>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

export const Data = () => {
  return (
    <>
      <CityCardSlider />
      {/* Contect Us page  */}
      <section className="content-inner">
        <div className="container">
          <ContectUs />
        </div>
      </section>
    </>
  );
};

export default Cotizador;
