import React from "react";
import { IMAGE } from "../constent/theme";
import { Link } from "react-router-dom";

import ImgInteresadoAuto from "../assets/images/gminmediauto/interesado-auto.png";

const ContectUs = (props) => {
  const { car } = props;
  return (
    <>
      <div className="row call-to-action-bx">
        <div className="col-xl-5 col-lg-6 me-auto">
          <div className="section-head">
            <h2 className="title text-white">¿Interesado en este auto?</h2>
          </div>
          <Link
            to={
              "/autofinanciamiento?marca=" +
              car.marca +
              "&vehiculo=" +
              car.linea +
              "&modelo=" +
              car.modelo
            }
            className="btn btn-white me-3 mb-2"
          >
            <span className="d-sm-inline-block d-none">Cotiza este auto</span>
          </Link>
          <Link to="/contacto" className="btn btn-outline-white effect-1  mb-2">
            <i className="fas fa-phone-volume me-sm-3 me-0 shake"></i>
            <span>Contáctanos</span>
          </Link>
        </div>
        <div className="col-lg-6">
          <div className="media-box">
            <img src={ImgInteresadoAuto} className="main-img" alt="" />
            <img src={IMAGE.patternImg3} className="pt-img move-1" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContectUs;
