import React, { createContext, useContext, useEffect, useState } from "react";

const SessionContext = createContext();

export const useSession = () => {
    return useContext(SessionContext);
};

export const SessionProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [sessionInitialized, setSessionInitialized] = useState(false); // Nuevo estado

    useEffect(() => {
        // Comprobar si la sesión ha caducado al cargar la página
        const storedSession = JSON.parse(localStorage.getItem("session"));

        if (storedSession && isSessionValid(storedSession)) {
            setUser(storedSession.usuario);
        } else {
            // La sesión ha caducado o no existe
            localStorage.removeItem("session");
        }

        setSessionInitialized(true); // Marca que la inicialización de la sesión se ha completado
    }, []);

    const isSessionValid = (session) => {
        if (!session) {
            return false;
        }
        const currentTime = new Date().getTime();
        const sessionStartTime = session.startTime;
        const sessionDuration = 1 * 60 * 60 * 1000; // 60 minutos en milisegundos

        return currentTime - sessionStartTime < sessionDuration;
    };

    const login = (userData) => {
        const startTime = new Date().getTime();
        localStorage.setItem(
            "session",
            JSON.stringify({ usuario: userData, startTime })
        );
        setUser(userData);
    };

    const logout = () => {
        localStorage.removeItem("session");
        setUser(null);
    };

    const updateUser = (userData) => {
        const storedSession = JSON.parse(localStorage.getItem("session"));
        //console.log("OLD  STORED user data", storedSession.usuario);
        localStorage.setItem(
            "session",
            JSON.stringify({
                usuario: userData,
                startTime: storedSession.startTime,
            })
        );
        //console.log("NEW STORED user data", userData);
        setUser(userData);
    };

    if (!sessionInitialized) {
        // Mientras se está inicializando la sesión, muestra un indicador de carga o componente de inicio de sesión
        return <div />; // Reemplaza con tu propio componente de carga o inicio de sesión
    }

    return (
        <SessionContext.Provider value={{ user, login, logout, updateUser }}>
            {children}
        </SessionContext.Provider>
    );
};
