import React, { useState, useEffect } from "react";

import axios from "axios";
import SearchCarBanner from "../components/SearchCarBanner";
import Home3bannerSlide from "../element/Home3bannerSlide";
import CityCardSlider from "../components/CityCardSlider";
import ContectUs from "../components/ContectUs";
import Pasos from "../components/Pasos";
import Carlist from "../element/ListaAutosCuatro";
import { act } from "react-dom/test-utils";

import { useBaseUrl } from "../contexts/BaseUrlContext";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import FiltroCotizadorAutoFinanciamiento from "../element/FiltroCotizadorAutoFinanciamiento";
import DetalleAutoCotizadorAutoFinanciamiento from "../components/DetalleAutoCotizadorAutoFinanciamiento";
import { IMAGE } from "../constent/theme";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { G, pdf, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PDF from "../components/cotizacionAutoFinanciamientoPDF";

const AutoFinanciamiento = () => {
  const [cars, setCars] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const { baseUrl } = useBaseUrl();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialBrand = queryParams.get("marca");
  const initialLine = queryParams.get("vehiculo");
  const initialModel = queryParams.get("modelo");

  const title = "Auto Financiamiento";
  const [data, setData] = useState({
    auto: {},
    filtros: {
      regimen: "",
      enganche: 0,
      engancheMinimo: 0,
      plazos: 0,
      aseguradora: "",
      gps: "",
      seguroDesempleo: "",
      seguroVida: "",
      pagoApertura: "",
    },
    cotizacion: {
      costoTotal: 0,
      mensualidad: 0,
    },
  });

  const steps = ["Vehículo", "Resumen de cotización"];
  const [activeStep, setActiveStep] = React.useState(0);
  //pasos completados
  const [completedSteps, setCompletedSteps] = useState([0]);
  //todos los pasos completados
  const [completed, setCompleted] = React.useState({});

  const getCarList = async () => {
    try {
      const response = await axios.get(
        baseUrl + "restautoaf",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        //console.log("veamos",response.data.data);
        setCars(response.data.data);
        setInitialized(true);
      } else {
        console.log("Error loading cars");
      }
    } catch (error) {
      console.error(error);
    }
  };

  



  const handleSelectedCar = (car) => {
    setCompletedSteps([...completedSteps, activeStep]);
    setActiveStep(activeStep + 1);
    setData({
      ...data,
      auto: car,
      filtros: { 
          regimen: "",
          enganche: car.pagoInicial,
          engancheMinimo: car.pagoInicial,
          plazos: car.meses,
          aseguradora: "",
          gps: "",
          seguroDesempleo: "",
          seguroVida: "",
          pagoApertura: "",
        },
      cotizacion: {
          costoTotal: car.costoTotal,
          mensualidad: car.mensualidad,
        },
    });
    //console.log(car);
  };

  useEffect(() => {
    getCarList();
  }, []);

  if (!initialized) {
    return null;
  }
  return (
    <>
      <div className="page-content bg-white">
        <Pasos
          steps={steps}
          title={title}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          completed={completed}
          setCompleted={setCompleted}
          completedSteps={completedSteps}
          setCompletedSteps={setCompletedSteps}
        />
        {activeStep === 0 && (
          <Carlist cars={cars} handleSelectedCar={handleSelectedCar} routeImg={"https://gminmediauto.com.mx/imgAutosAF/"} initialBrand={initialBrand} initialLine={initialLine} initialModel={initialModel}/>
        )}
        {activeStep === 1 && <FiltersAndPage data={data} setData={setData} />}
      </div>
    </>
  );
};

const FiltersAndPage = (props) => {
  const { baseUrl } = useBaseUrl();
  const navigate = useNavigate();
  const { data, setData } = props;
  const CarList2 = [
    { img: IMAGE.productPic1 },
    { img: IMAGE.productPic2 },
    { img: IMAGE.productPic3 },
    { img: IMAGE.productPic4 },
    { img: IMAGE.productPic5 },
    { img: IMAGE.productGridPic1 },
  ];
  const [selectBtn, setSelectBtn] = useState("Newest");
  const [bgColor, setColorBtn] = useState(2);
  const [isVisible, setIsVisible] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const getCurrency2FormatMX = (number) => {
    //get type of variable number
    if (typeof number === "string") {
      number = parseFloat(number);
    }
    return number.toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN", // Puedes cambiar 'MXN' por el código de moneda correspondiente
    });
  };

  const handleSubmitSendData = (e) => {
    e.preventDefault();
    setShowLoading(true);

    const generatePDF = async () => {
      const blob = await pdf(<PDF data={data} />).toBlob();
      sendPDF(blob);
    };

    generatePDF();

    const sendPDF = async (blob) => {
      try {
        const formData = new FormData();
        formData.append("file", blob, "AutoFinanciamiento.pdf");
        formData.append("name", name);
        formData.append("lastName", lastName);
        formData.append("email", email);
        formData.append("phone", phone);
        const response = await axios.post(
          baseUrl + "enviarcotizacionautofinanciamiento",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          //console.log(response.data.data);
          setIsVisible(false);
          setShowLoading(false);
          setName("");
          setLastName("");
          setEmail("");
          setPhone("");
          alert("Cotización enviada correctamente");
        } else {
          //console.log("Error loading leasing");
          setShowLoading(false);
          alert("Cotización no ha podido ser enviada, intenta más tarde");
        }
      } catch (error) {
        console.error(error);
      }
    };
  };

  return (
    <div className="container">
      <div className="row">
        {/* ==============Car listing filters >>>>>>>> */}
        <FiltroCotizadorAutoFinanciamiento
          setData={setData}
          data={data}
        />
        {/* ===============Car sort by >>>>>>>> */}
        <div className="col-xl-8 col-lg-8 m-b40">
          <DetalleAutoCotizadorAutoFinanciamiento textTitleOn={false} data={data} />
          <div className="contact-area-preaprobacion-1">
            <div className="dlab-form style-1 dzForm">
              <input
                type="hidden"
                className="form-control"
                name="dzToDo"
                value="Contact"
              />
              <input
                type="hidden"
                className="form-control"
                name="reCaptchaEnable"
                value="0"
              />
              <div className="dzFormMsg"></div>
              <div className="row sp10">
                <div className="col-sm-6 resumen-head">
                  <div className="form-group">
                    <label className="font-weight-600 resumen-padding-labels resumen-label-color">
                      Mensualidad
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 resumen-head">
                  <div className="form-group">
                    <label className="font-weight-600 resumen-padding-labels resumen-label-color d-flex justify-content-end">
                      {getCurrency2FormatMX(
                        Math.ceil(data.cotizacion.mensualidad)
                      )}{" "}
                      MXN
                    </label>
                  </div>
                </div>

                <label className=" resumen-title-label">
                  Resumen de cotización
                </label>

                <h4 className="resumen-padding-title-2">Mensualidad</h4>
                <div className="resumen-row-space-20"></div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="font-weight-400">
                      Mensualidad sin accesorios
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 d-flex  justify-content-end">
                  <div className="form-group">
                    <label className="font-weight-400">
                      {getCurrency2FormatMX(
                        Math.ceil(data.cotizacion.mensualidad)
                      )}
                    </label>
                  </div>
                </div>
                <div className="resumen-row-space-30"></div>

                <div className="container-resumen-border"></div>
                <div className="resumen-row-space-30"></div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="font-weight-600 ">
                      Pago Por Apertura
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 d-flex justify-content-end">
                  <div className="form-group">
                    <label className="font-weight-600 ">
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 resumen-row-space-20">
                  <div className="form-group">
                    <label className="font-weight-400 ">
                      Pago por apertura
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 d-flex resumen-row-space-20 justify-content-end">
                  <div className="form-group">
                    <label className="font-weight-400">
                      {getCurrency2FormatMX(
                        Math.ceil(data.filtros.enganche)
                      )}
                    </label>
                  </div>
                </div>
                
                <div className="container-resumen-border"></div>
                <div className="resumen-row-space"></div>

                <div className="section text-center">
                  <label className="text-primary font-weight-400 resumen-padding-label-2">
                    Para ver más información de tu cotización da clic en enviar
                    cotización
                  </label>
                </div>
                <div className="col-sm-12">
                  <button
                    name="submit"
                    type="submit"
                    value="submit"
                    className="btn btn-primary w-100 d-block btn-rounded resumen-btn"
                    onClick={() =>
                      navigate("/preaprobacion-autofinanciamiento", { state: { data } })
                    }
                  >
                    <div className="row">
                      <div className="col-11">Solicita tu pre-aprobación</div>
                      <div className="col-1" style={{ textAlign: "right" }}>
                        <ArrowForwardIosIcon />
                      </div>
                    </div>
                  </button>
                  <div
                    name="submit"
                    type="submit"
                    value="submit"
                    onClick={toggleVisibility}
                    className="btn btn-white w-100 d-block resumen-btn without-border"
                  >
                    <div className="row">
                      <div className="col-11">Enviar cotización completa</div>
                      <div className="col-1" style={{ textAlign: "right" }}>
                        {isVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isVisible && (
            <div className="col-xl-6.m-b40">
              <div className="contact-area-preaprobacion">
                <form
                  className="dlab-form style-1 dzForm"
                  onSubmit={(e) => {
                    handleSubmitSendData(e);
                  }}
                >
                  <input
                    type="hidden"
                    className="form-control"
                    name="dzToDo"
                    value="Contact"
                  />
                  <input
                    type="hidden"
                    className="form-control"
                    name="reCaptchaEnable"
                    value="0"
                  />
                  <div className="dzFormMsg"></div>
                  <div className="row sp10">
                    <div className="resumen-row-space"></div>
                    {!showLoading ? (
                      <>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="font-weight-400">Nombre(s)</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                                required
                                name="dzOther[Name]"
                                placeholder=""
                                value={name}
                                onChange={handleNameChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="font-weight-400">
                              Apellido(s)
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                                required
                                name="dzOther[Apellidos]"
                                placeholder=""
                                value={lastName}
                                onChange={handleLastNameChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="font-weight-400">
                              Correo electrónico
                            </label>
                            <div className="input-group">
                              <input
                                type="email"
                                className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                                required
                                name="dzOther[email]"
                                placeholder=""
                                value={email}
                                onChange={handleEmailChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="font-weight-400">Teléfono</label>
                            <div className="input-group">
                              <input
                                type="tel"
                                className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                                required
                                name="dzOther[Telefono]"
                                placeholder=""
                                value={phone}
                                onChange={handlePhoneChange}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress style={{ color: '#ff5269', width: '100px', height: '100px' }}/>
                      </div>
                    )}

                    <div className="resumen-row-space"></div>
                    <div className="row justify-content-center">
                      <div className="col-lg-4 col-md-6">
                        <button
                          name="submit"
                          type="submit"
                          value="submit"
                          className="btn btn-primary w-100 d-block btn-rounded resumen-btn"
                          style={{ marginLeft: "20px" }}
                          disabled={showLoading}
                        >
                          Enviar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
        {/* =============================================== */}
      </div>
    </div>
  );
};

const GeneratorPDF = (props) => {
  const { data } = props;
  return (
    <div>
      <PDFDownloadLink document={<PDF data={data} />} fileName="myfirstpdf.pdf">
        {({ loading, url, error, blob }) =>
          loading ? (
            <button>Loading Document ...</button>
          ) : (
            <button>Download now!</button>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

export const Data = () => {
  return (
    <>
      <CityCardSlider />
      {/* Contect Us page  */}
      <section className="content-inner">
        <div className="container">
          <ContectUs />
        </div>
      </section>
    </>
  );
};

export default AutoFinanciamiento;
