import React, { useState, useEffect } from 'react'
import { IMAGE } from '../constent/theme'
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const HorizontalNonLinearStepper = (props) => {
    const [screenSize, setScreenSize] = React.useState(false);
   
    useEffect(()=>{
      const handleResize = () => {
        const newScreenSize = window.innerWidth < 765 ; setScreenSize(newScreenSize);
        setScreenSize(newScreenSize);
      };
      handleResize()

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      }

    }, []);

    const { steps, activeStep, setActiveStep, completed, setCompleted, completedStep, setCompletedStep} = props;
    const totalSteps = () => {
      return steps.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };
  
    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
  
    const handleReset = () => {
      setActiveStep(0);
      setCompleted({});
    };

    const isStepDisabled = (step) => {
      //console.log(completedStep.indexOf(step) !== -1);
      return completedStep.indexOf(step) !== -1;
    };
    
    

    return (
      
      <Box sx={{ width: '100%' }}>
        <Stepper nonLinear activeStep={activeStep} orientation={screenSize? 'vertical': 'horizontal'} alternativeLabel={screenSize ? false : true}>
          {steps.map((label, index) => (
            <Step 
            sx={{
           
              '& .MuiStepLabel-root .Mui-active': {
                color: 'red', 
              },
              '& .MuiStepLabel-label.Mui-active.MuiStepLabel': {
                color: 'red', 
              }
            }}
            key={label} completed={completed[index]} disabled={!isStepDisabled(index)}>
                      
              <StepButton  onClick={handleStep(index)}>
                {label}
              </StepButton>              
            </Step>
          ))}          
        </Stepper>
        <div>
        </div>
      </Box>
    );
  }

const Pasos = (props) => {
    const { steps, title, activeStep, setActiveStep, completed, setCompleted, completedSteps, setCompletedSteps, screenSize, setScreenSize } = props;
    const [addactive,setAddactive] = useState(1);
    const links =(e)=>{
        setAddactive(e.currentTarget.value)
    }
  return (
  <>
          <div className="dlab-bnr-inr dlab-bnr-inr-lg style-1 overlay-black-middle" style={{backgroundImage: `url(${IMAGE.bnrLeasing})`}}>
              <div className="container">
                  <div className="dlab-bnr-inr-entry">
                      <h1 className="text-white">{title}</h1>
                      <div className="dlab-separator"></div>
                      
                      <div className="tab-content">
                          <div className={`tab-pane fade active show`} id="by-budget">
                              <div className="car-search-box row item2">
                                  <HorizontalNonLinearStepper steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} completed={completed} setCompleted={setCompleted} completedStep={completedSteps} setCompletedStep={setCompletedSteps} screenSize={screenSize}/>
                                  <img className="img2 move-2" src={IMAGE.patternImg5} alt=""/>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  </>
  )
}

export default Pasos