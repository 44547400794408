import React, { useState, useEffect } from "react";
import axios from "axios";
import { IMAGE } from "../constent/theme";
import Pasos from "../components/Pasos";
import DetalleAutoCotizadorAutoFinanciamiento from "../components/DetalleAutoCotizadorAutoFinanciamiento";
import { Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from "@mui/icons-material";

import { G, pdf, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PreaprobacionPDF from "../components/preaprobacionAutoFinanciamientoPDF";
import CotizacionPDF from "../components/cotizacionAutoFinanciamientoPDF";
//import PDF from "../components/preaprobacionAutoFinanciamientoPDF";

import { useBaseUrl } from "../contexts/BaseUrlContext";

const CarList2 = [
  { img: IMAGE.productPic1 },
  { img: IMAGE.productPic2 },
  { img: IMAGE.productPic3 },
  { img: IMAGE.productPic4 },
  { img: IMAGE.productPic5 },
  { img: IMAGE.productGridPic1 },
];

const PreaprobacionAutoFinanciamiento = () => {
  const location = useLocation();
  const selectedData = location.state.data;
  const steps = [
    "Persona",
    "Empleo",
    "Referencias",
    "Financiamiento",
    "Completado",
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  //pasos completados
  const [completedSteps, setCompletedSteps] = useState([0]);
  //todos los pasos completados
  const [completed, setCompleted] = React.useState({});
  const [textTitleOn, setTextTitleOn] = useState(false);

  const title = "Pre-aprobación";

  const [selectBtn, setSelectBtn] = useState("Newest");
  const [bgColor, setColorBtn] = useState(2);

  /**TODO: Datos a actualizar con el diseño */
  const [data, setData] = useState({
    personal: {
      general: {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        curp: "",
        fechaNacimiento: "",
        estadoCivil: "",
        telefono: "",
        correoElectronico: "",
        conyuge: "",
        numeroDependientesEconomicos: "",
      },
      direccion: {
        calle: "",
        numeroExterior: "",
        numeroInterior: "",
        colonia: "",
        municipio: "",
        ciudad: "",
        estado: "",
        cp: "",
        tipoDeDomicilio: "",
        vivesCon: "",
      },
    },
    empleo: {
      empleo: {
        empresa: "",
        puesto: "",
        tiempoEnElEmpleo: "",
        fechaDeInicioDeTrabajo: "",
        ingresoMensual: "",
        ingresoExtra: "",
        creditosDisponibles: "",
        tarjetasBancarias: "",
      },

      direccionDeLaEmpresa: {
        calle: "",
        numeroExterior: "",
        numeroInterior: "",
        colonia: "",
        municipio: "",
        ciudad: "",
        estado: "",
        cp: "",
      },
    },
    referencias: {
      referenciaPersonal: {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        telefono: "",
        relacionConUsted: "",
        tiempoDeConocerALaPersona: "",
      },
      referenciaPersonalSegundo: {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        telefono: "",
        relacionConUsted: "",
        tiempoDeConocerALaPersona: "",
      },
    },
    financiamiento: {
      marca: selectedData.auto.marca,
      linea: selectedData.auto.linea,
      modelo: selectedData.auto.modelo,
      version: selectedData.auto.version,
      tipoDeUso: "",
      otroUso: "",
      monto: selectedData.cotizacion.costoTotal,
      plazo: selectedData.filtros.plazos,
      mensualidades: selectedData.cotizacion.mensualidad,
      apertura: selectedData.filtros.enganche,
    },
  });

  return (
    <>
      <div className="page-content bg-white">
        <Pasos
          steps={steps}
          title={title}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          completed={completed}
          setCompleted={setCompleted}
          completedSteps={completedSteps}
          setCompletedSteps={setCompletedSteps}
        />
        <section className="content-preaprobacion">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <DetalleAutoCotizadorAutoFinanciamiento
                textTitleOn={textTitleOn}
                data={selectedData}
              />
            </div>
          </div>

          {activeStep === 0 && (
            <Paso1
              data={data}
              setData={setData}
              setActiveStep={setActiveStep}
            />
          )}
          {activeStep === 1 && (
            <Paso2
              data={data}
              setData={setData}
              setActiveStep={setActiveStep}
            />
          )}
          {activeStep === 2 && (
            <Paso3
              data={data}
              setData={setData}
              setActiveStep={setActiveStep}
            />
          )}
          {activeStep === 3 && (
            <Paso4
              data={data}
              setData={setData}
              setActiveStep={setActiveStep}
              setTextTitleOn={setTextTitleOn}
              selectedData={selectedData}
            />
          )}
          {activeStep === 4 && (
            <Paso5
              data={data}
              setData={setData}
              setActiveStep={setActiveStep}
            />
          )}
        </section>
      </div>
    </>
  );
};

const Paso1 = (props) => {
  const { data, setData, setActiveStep } = props;

  const handleNameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          nombre: e.target.value,
        },
      },
    }));
  };

  const handleSurnameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          apellidoPaterno: e.target.value,
        },
      },
    }));
  };

  const handleSecondSurnameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          apellidoMaterno: e.target.value,
        },
      },
    }));
  };

  const handleBirthdateChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          fechaNacimiento: e.target.value,
        },
      },
    }));
  };

  const handleCurpChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          curp: e.target.value,
        },
      },
    }));
  };

  const handleCiviStatusChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          estadoCivil: e.target.value,
        },
      },
    }));
  };

  const handleSpouseChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          conyuge: e.target.value,
        },
      },
    }));
  };

  const handleNumberEconomicDependentsChange = (e) => {
    /*Validar el campo de nss que solo reciba números */
    // Expresión regular para validar que solo se ingresen números
    const inputValue = e.target.value;
    // Expresión regular para validar que solo se ingresen exactamente 11 dígitos
    const numericRegex = /^[0-9]*$/;

    if (numericRegex.test(inputValue) || inputValue === "") {
      setData((prevState) => ({
        ...prevState,
        personal: {
          ...prevState.personal,
          general: {
            ...prevState.personal.general,
            numeroDependientesEconomicos: e.target.value,
          },
        },
      }));
    }
  };

  const handleEmailChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          correoElectronico: e.target.value,
        },
      },
    }));
  };

  const handleCellPhoneChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          telefono: e.target.value,
        },
      },
    }));
  };

  const handleStreetChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          calle: e.target.value,
        },
      },
    }));
  };

  const handleOutdoorNumberChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          numeroExterior: e.target.value,
        },
      },
    }));
  };

  const handleInteriorNumberChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          numeroInterior: e.target.value,
        },
      },
    }));
  };

  const handleCologneChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          colonia: e.target.value,
        },
      },
    }));
  };

  const handleMunicipalityChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          municipio: e.target.value,
        },
      },
    }));
  };

  const handleCityChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          ciudad: e.target.value,
        },
      },
    }));
  };

  const handleStateChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          estado: e.target.value,
        },
      },
    }));
  };

  const handlePostalCodeChange = (e) => {
    /*Validar el campo de nss que solo reciba números */
    // Expresión regular para validar que solo se ingresen números
    const inputValue = e.target.value;
    // Expresión regular para validar que solo se ingresen exactamente 11 dígitos
    const numericRegex = /^[0-9]*$/;

    if (numericRegex.test(inputValue) || inputValue === "") {
      setData((prevState) => ({
        ...prevState,
        personal: {
          ...prevState.personal,
          direccion: {
            ...prevState.personal.direccion,
            cp: e.target.value,
          },
        },
      }));
    }
  };

  const handleTypeOfAddressChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          tipoDeDomicilio: e.target.value,
        },
      },
    }));
  };

  const handleLiveWithChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          vivesCon: e.target.value,
        },
      },
    }));
  };

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  /*////////Validación del formulario//////// */
  const handleNext = (e) => {
    e.preventDefault();
    // Verificar si los campos obligatorios están llenos
    if (
      data.personal.general.nombre &&
      data.personal.general.apellidoPaterno &&
      data.personal.general.apellidoMaterno &&
      data.personal.general.fechaNacimiento &&
      data.personal.general.curp &&
      data.personal.general.estadoCivil &&
      data.personal.general.numeroDependientesEconomicos &&
      data.personal.general.correoElectronico &&
      data.personal.general.telefono &&
      data.personal.direccion.calle &&
      data.personal.direccion.numeroExterior &&
      data.personal.direccion.colonia &&
      data.personal.direccion.municipio &&
      data.personal.direccion.ciudad &&
      data.personal.direccion.estado &&
      data.personal.direccion.cp &&
      data.personal.direccion.tipoDeDomicilio
    ) {
      // Avanzar al siguiente paso
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      // Mostrar mensaje de error o realizar alguna otra acción
      alert("Por favor, llena todos los campos obligatorios.");
    }

    /* if (isChecked) {
      console.log("Avanzando al siguiente paso...");
      // Aquí puedes agregar cualquier otra lógica que desees ejecutar cuando el checkbox esté marcado
    } else {
      console.log("Acepta la política de privacidad.");
      // Puedes mostrar un mensaje al usuario indicando que debe marcar el checkbox antes de continuar
    } */
  };
  /* /////////**************/ /////////// */

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 m-b40">
            <div className="paragraph-alert-preaprobacion">
              ¡Obtén tu preaprobación de Autofinanciamiento ahora mismo! Solo
              completa tus datos o contáctanos vía WhatsApp al{" "}
              <a
                href="https://wa.me/522214333335?text=%C2%A1Hola%21+Me+estoy+contactando+desde+la+p%C3%A1gina+web+de+GMInmediauto+para+solicitar+mi+preaprobaci%C3%B3n+de+autofinanciamiento."
                target="_blank"
                rel="noopener noreferrer"
              >
                221 433 3335
              </a>{" "}
              y un asesor estará encantado de ayudarte.
            </div>
            <div className="contact-area-preaprobacion">
              <form className="dlab-form style-1 dzForm">
                <input
                  type="hidden"
                  className="form-control"
                  name="dzToDo"
                  value="Contact"
                />
                <input
                  type="hidden"
                  className="form-control"
                  name="reCaptchaEnable"
                  value="0"
                />
                <div className="dzFormMsg"></div>
                <div className="row sp10">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="font-weight-800">General:</label>
                    </div>
                  </div>
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Nombre(s)*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleNameChange}
                          value={data.personal.general.nombre}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzName"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Paterno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleSurnameChange}
                          value={data.personal.general.apellidoPaterno}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Surname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Materno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleSecondSurnameChange}
                          value={data.personal.general.apellidoMaterno}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[SecondSurname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Fecha de nacimiento*
                      </label>
                      <div className="input-group">
                        <input
                          type="date"
                          onChange={handleBirthdateChange}
                          value={data.personal.general.fechaNacimiento}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Birthdate]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">CURP*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleCurpChange}
                          value={data.personal.general.curp}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Curp]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Estado civil*</label>
                      <div className="col-lg-12 border-bottom">
                        <div className="selected-box">
                          <Form.Select
                            className="form-control sm-preaprobacion"
                            onChange={handleCiviStatusChange}
                            value={data.personal.general.estadoCivil}
                          >
                            <option></option>
                            <option>Soltero(a)</option>
                            <option>Casado(a)</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Teléfono*</label>
                      <div className="input-group">
                        <input
                          type="tel"
                          onChange={handleCellPhoneChange}
                          value={data.personal.general.telefono}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[CellPhone]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Correo electrónico*
                      </label>
                      <div className="input-group">
                        <input
                          type="email"
                          onChange={handleEmailChange}
                          value={data.personal.general.correoElectronico}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Email]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Nombre de cónyuge
                      </label>
                      <div className="input-group">
                        <input
                          type="Text"
                          onChange={handleSpouseChange}
                          value={data.personal.general.conyuge}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          name="dzOther[Spouse]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Número de dependientes económicos*
                      </label>
                      <div className="input-group">
                        <input
                          type="Text"
                          onChange={handleNumberEconomicDependentsChange}
                          value={
                            data.personal.general.numeroDependientesEconomicos
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[NumberEconomicDependents]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-800">Dirección:</label>
                    </div>
                  </div>
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Calle*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleStreetChange}
                          value={data.personal.direccion.calle}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Street]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group ">
                      <div className="row justify-content-center">
                        <div className="col-lg3 col-md-6 col-sm-12 ">
                          <label className="font-weight-400">
                            Número exterior*
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleOutdoorNumberChange}
                              value={data.personal.direccion.numeroExterior}
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[OutdoorNumber]"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-lg3 col-md-6 col-sm-12">
                          <label className="font-weight-400">
                            Número interior
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleInteriorNumberChange}
                              value={data.personal.direccion.numeroInterior}
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              name="dzOther[InteriorNumber]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Colonia*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleCologneChange}
                          value={data.personal.direccion.colonia}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Cologne]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Delegación/municipio*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleMunicipalityChange}
                          value={data.personal.direccion.municipio}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Municipality]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Ciudad*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleCityChange}
                          value={data.personal.direccion.ciudad}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[City]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Estado*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleStateChange}
                          value={data.personal.direccion.estado}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[State]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Código postal*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePostalCodeChange}
                          value={data.personal.direccion.cp}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[PostalCode]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Vives con*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleLiveWithChange}
                          value={data.personal.direccion.vivesCon}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[LivesWith]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Casa propia o rentada*
                      </label>
                      <div className="col-lg-12 border-bottom">
                        <div className="selected-box">
                          <Form.Select
                            className="form-control sm-preaprobacion"
                            onChange={handleTypeOfAddressChange}
                            value={data.personal.direccion.tipoDeDomicilio}
                          >
                            <option></option>
                            <option>Propia</option>
                            <option>Renta</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="resumen-row-space"></div>

                  <div className="section text-center">
                    <label htmlFor="miCheckbox">
                      {isChecked ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}{" "}
                      Acepto la{" "}
                      <Link
                        to="https://gminmediauto.com/politica-de-privacidad/?v=0b98720dcb2c"
                        className="text-primary"
                        target="_blank"
                      >
                        política de privacidad
                      </Link>{" "}
                      de GMInmediAuto
                      <input
                        type="checkbox"
                        id="miCheckbox"
                        name="miCheckbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        style={{ display: "none" }}
                      />
                    </label>
                  </div>
                  <div className="resumen-row-space"></div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <button
                        name="submit"
                        type="submit"
                        value="submit"
                        onClick={handleNext}
                        className={`btn btn-primary w-100 d-block btn-rounded resumen-btn ${
                          !isChecked ? "disabled" : ""
                        }`}
                        disabled={!isChecked}
                      >
                        Siguiente paso
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Paso2 = (props) => {
  const { data, setData, setActiveStep } = props;

  const handleCompanyChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        empleo: {
          ...prevState.empleo.empleo,
          empresa: e.target.value,
        },
      },
    }));
  };
  const handlePositionChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        empleo: {
          ...prevState.empleo.empleo,
          puesto: e.target.value,
        },
      },
    }));
  };
  const handleTimeInEmploymentChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        empleo: {
          ...prevState.empleo.empleo,
          tiempoEnElEmpleo: e.target.value,
        },
      },
    }));
  };
  const handleJobStartDateChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        empleo: {
          ...prevState.empleo.empleo,
          fechaDeInicioDeTrabajo: e.target.value,
        },
      },
    }));
  };
  const handleMonthlyIncomeChange = (e) => {
    /*Validar el campo de nss que solo reciba números */
    // Expresión regular para validar que solo se ingresen números
    const inputValue = e.target.value;
    // Expresión regular para validar que solo se ingresen exactamente 11 dígitos
    const numericRegex = /^[0-9]*$/;

    if (numericRegex.test(inputValue) || inputValue === "") {
      setData((prevState) => ({
        ...prevState,
        empleo: {
          ...prevState.empleo,
          empleo: {
            ...prevState.empleo.empleo,
            ingresoMensual: e.target.value,
          },
        },
      }));
    }
  };
  const handleExtraIncomeChange = (e) => {
    /*Validar el campo de nss que solo reciba números */
    // Expresión regular para validar que solo se ingresen números
    const inputValue = e.target.value;
    // Expresión regular para validar que solo se ingresen exactamente 11 dígitos
    const numericRegex = /^[0-9]*$/;

    if (numericRegex.test(inputValue) || inputValue === "") {
      setData((prevState) => ({
        ...prevState,
        empleo: {
          ...prevState.empleo,
          empleo: {
            ...prevState.empleo.empleo,
            ingresoExtra: e.target.value,
          },
        },
      }));
    }
  };
  const handleAvailableCreditsChange = (e) => {
    /*Validar el campo de nss que solo reciba números */
    // Expresión regular para validar que solo se ingresen números
    const inputValue = e.target.value;
    // Expresión regular para validar que solo se ingresen exactamente 11 dígitos
    const numericRegex = /^[0-9]*$/;

    if (numericRegex.test(inputValue) || inputValue === "") {
      setData((prevState) => ({
        ...prevState,
        empleo: {
          ...prevState.empleo,
          empleo: {
            ...prevState.empleo.empleo,
            creditosDisponibles: e.target.value,
          },
        },
      }));
    }
  };
  const handleBankCardsChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        empleo: {
          ...prevState.empleo.empleo,
          tarjetasBancarias: e.target.value,
        },
      },
    }));
  };
  const handleStreetChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          calle: e.target.value,
        },
      },
    }));
  };
  const handleOutdoorNumberChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          numeroExterior: e.target.value,
        },
      },
    }));
  };
  const handleInteriorNumberChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          numeroInterior: e.target.value,
        },
      },
    }));
  };
  const handleCologneChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          colonia: e.target.value,
        },
      },
    }));
  };
  const handleMunicipalityChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          municipio: e.target.value,
        },
      },
    }));
  };
  const handleCityChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          ciudad: e.target.value,
        },
      },
    }));
  };
  const handleStateChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          estado: e.target.value,
        },
      },
    }));
  };
  const handlePostalCodeChange = (e) => {
    /*Validar el campo de nss que solo reciba números */
    // Expresión regular para validar que solo se ingresen números
    const inputValue = e.target.value;
    // Expresión regular para validar que solo se ingresen exactamente 11 dígitos
    const numericRegex = /^[0-9]*$/;

    if (numericRegex.test(inputValue) || inputValue === "") {
      setData((prevState) => ({
        ...prevState,
        empleo: {
          ...prevState.empleo,
          direccionDeLaEmpresa: {
            ...prevState.empleo.direccionDeLaEmpresa,
            cp: e.target.value,
          },
        },
      }));
    }
  };

  /* validación del formulario */
  const handleNext = (e) => {
    e.preventDefault();
    // Verificar si los campos obligatorios están llenos
    if (
      data.empleo.empleo.empresa &&
      data.empleo.empleo.puesto &&
      data.empleo.empleo.tiempoEnElEmpleo &&
      data.empleo.empleo.fechaDeInicioDeTrabajo &&
      data.empleo.empleo.ingresoMensual &&
      data.empleo.empleo.tarjetasBancarias &&
      data.empleo.direccionDeLaEmpresa.calle &&
      data.empleo.direccionDeLaEmpresa.numeroExterior &&
      data.empleo.direccionDeLaEmpresa.colonia &&
      data.empleo.direccionDeLaEmpresa.municipio &&
      data.empleo.direccionDeLaEmpresa.ciudad &&
      data.empleo.direccionDeLaEmpresa.estado &&
      data.empleo.direccionDeLaEmpresa.cp
    ) {
      // Avanzar al siguiente paso
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      // Mostrar mensaje de error o realizar alguna otra acción
      alert("Por favor, llena todos los campos obligatorios.");
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 m-b40">
            <div className="contact-area-preaprobacion">
              <form className="dlab-form style-1 dzForm">
                <input
                  type="hidden"
                  className="form-control"
                  name="dzToDo"
                  value="Contact"
                />
                <input
                  type="hidden"
                  className="form-control"
                  name="reCaptchaEnable"
                  value="0"
                />
                <div className="dzFormMsg"></div>
                <div className="row sp10">
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <label className="font-weight-800">Empleo:</label>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Nombre de empresa o razón social*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleCompanyChange}
                          value={data.empleo.empleo.empresa}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Company]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Puesto*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePositionChange}
                          value={data.empleo.empleo.puesto}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzPosition"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Tiempo en ese empleo*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleTimeInEmploymentChange}
                          value={data.empleo.empleo.tiempoEnElEmpleo}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[TimeInEmployment]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Fecha de Inicio del trabajo*
                      </label>
                      <div className="input-group">
                        <input
                          type="date"
                          onChange={handleJobStartDateChange}
                          value={data.empleo.empleo.fechaDeInicioDeTrabajo}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Birthdate]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Ingreso mensual*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleMonthlyIncomeChange}
                          value={data.empleo.empleo.ingresoMensual}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[MonthlyIncome]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Ingreso extra</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleExtraIncomeChange}
                          value={data.empleo.empleo.ingresoExtra}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          name="dzOther[ExtraIncome]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Créditos disponibles
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleAvailableCreditsChange}
                          value={data.empleo.empleo.creditosDisponibles}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          name="dzOther[AvailableCredits]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Tarjetas bancarias
                      </label>
                      <div className="col-lg-12 border-bottom">
                        <div className="selected-box">
                          <Form.Select
                            className="form-control sm-preaprobacion"
                            onChange={handleBankCardsChange}
                            value={data.empleo.empleo.tarjetasBancarias}
                          >
                            <option></option>
                            <option>Débito</option>
                            <option>Crédito</option>
                            <option>Ambas</option>
                            <option>Ninguna</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Aqui */}
                  <div className="form-group"></div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <label className="font-weight-800">
                      Dirección de la empresa donde trabajas:
                    </label>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Calle*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleStreetChange}
                          value={data.empleo.direccionDeLaEmpresa.calle}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Street]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group ">
                      <div className="row justify-content-center">
                        <div className="col-lg3 col-md-6 col-sm-12 ">
                          <label className="font-weight-400">
                            Número exterior*
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleOutdoorNumberChange}
                              value={
                                data.empleo.direccionDeLaEmpresa.numeroExterior
                              }
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[OutdoorNumber]"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-lg3 col-md-6 col-sm-12">
                          <label className="font-weight-400">
                            Número interior
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleInteriorNumberChange}
                              value={
                                data.empleo.direccionDeLaEmpresa.numeroInterior
                              }
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              name="dzOther[InteriorNumber]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Colonia*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleCologneChange}
                          value={data.empleo.direccionDeLaEmpresa.colonia}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Cologne]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Delegación/municipio*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleMunicipalityChange}
                          value={data.empleo.direccionDeLaEmpresa.municipio}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Municipality]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Ciudad*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleCityChange}
                          value={data.empleo.direccionDeLaEmpresa.ciudad}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[City]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Estado*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleStateChange}
                          value={data.empleo.direccionDeLaEmpresa.estado}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[State]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Código postal*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePostalCodeChange}
                          value={data.empleo.direccionDeLaEmpresa.cp}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[PostalCode]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="resumen-row-space"></div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <button
                        name="submit"
                        type="submit"
                        value="submit"
                        onClick={handleNext}
                        className="btn btn-primary w-100 d-block btn-rounded resumen-btn"
                      >
                        Siguiente paso
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Paso3 = (props) => {
  const { data, setData, setActiveStep } = props;

  const handlePersonalNameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonal: {
          ...prevState.referencias.referenciaPersonal,
          nombre: e.target.value,
        },
      },
    }));
  };
  const handlePersonalSurnameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonal: {
          ...prevState.referencias.referenciaPersonal,
          apellidoPaterno: e.target.value,
        },
      },
    }));
  };
  const handlePersonalSecondSurnameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonal: {
          ...prevState.referencias.referenciaPersonal,
          apellidoMaterno: e.target.value,
        },
      },
    }));
  };
  const handlePersonalPhoneChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonal: {
          ...prevState.referencias.referenciaPersonal,
          telefono: e.target.value,
        },
      },
    }));
  };
  const handleRelationshipWithYouChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonal: {
          ...prevState.referencias.referenciaPersonal,
          relacionConUsted: e.target.value,
        },
      },
    }));
  };
  const handleTimeOfKnowingChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonal: {
          ...prevState.referencias.referenciaPersonal,
          tiempoDeConocerALaPersona: e.target.value,
        },
      },
    }));
  };

  const handlePersonalNameSecondChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonalSegundo: {
          ...prevState.referencias.referenciaPersonalSegundo,
          nombre: e.target.value,
        },
      },
    }));
  };
  const handlePersonalSurnameSecondChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonalSegundo: {
          ...prevState.referencias.referenciaPersonalSegundo,
          apellidoPaterno: e.target.value,
        },
      },
    }));
  };
  const handlePersonalSecondSurnameSecondChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonalSegundo: {
          ...prevState.referencias.referenciaPersonalSegundo,
          apellidoMaterno: e.target.value,
        },
      },
    }));
  };
  const handlePersonalPhoneSecondChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonalSegundo: {
          ...prevState.referencias.referenciaPersonalSegundo,
          telefono: e.target.value,
        },
      },
    }));
  };
  const handleRelationshipWithYouSecondChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonalSegundo: {
          ...prevState.referencias.referenciaPersonalSegundo,
          relacionConUsted: e.target.value,
        },
      },
    }));
  };
  const handleTimeOfKnowingSecondChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonalSegundo: {
          ...prevState.referencias.referenciaPersonalSegundo,
          tiempoDeConocerALaPersona: e.target.value,
        },
      },
    }));
  };

  /* validación del formulario */
  const handleNext = (e) => {
    e.preventDefault();
    // Verificar si los campos obligatorios están llenos
    if (
      data.referencias.referenciaPersonal.nombre &&
      data.referencias.referenciaPersonal.apellidoPaterno &&
      data.referencias.referenciaPersonal.apellidoMaterno &&
      data.referencias.referenciaPersonal.telefono &&
      data.referencias.referenciaPersonal.relacionConUsted &&
      data.referencias.referenciaPersonal.tiempoDeConocerALaPersona &&
      data.referencias.referenciaPersonalSegundo.nombre &&
      data.referencias.referenciaPersonalSegundo.apellidoPaterno &&
      data.referencias.referenciaPersonalSegundo.apellidoMaterno &&
      data.referencias.referenciaPersonalSegundo.telefono &&
      data.referencias.referenciaPersonalSegundo.relacionConUsted &&
      data.referencias.referenciaPersonalSegundo.tiempoDeConocerALaPersona
    ) {
      // Avanzar al siguiente paso
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      // Mostrar mensaje de error o realizar alguna otra acción
      alert("Por favor, llena todos los campos obligatorios.");
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 m-b40">
            <div className="contact-area-preaprobacion">
              <form className="dlab-form style-1 dzForm">
                <input
                  type="hidden"
                  className="form-control"
                  name="dzToDo"
                  value="Contact"
                />
                <input
                  type="hidden"
                  className="form-control"
                  name="reCaptchaEnable"
                  value="0"
                />
                <div className="dzFormMsg"></div>
                <div className="row sp10">
                  <div className="form-group"></div>
                  <div>
                    <label className="font-weight-800">
                      Referencia personal que no viva con usted ni sea un
                      familiar (1)
                    </label>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Nombre(s)*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePersonalNameChange}
                          value={data.referencias.referenciaPersonal.nombre}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzName"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Paterno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePersonalSurnameChange}
                          value={
                            data.referencias.referenciaPersonal.apellidoPaterno
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[PersonalSurname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Materno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePersonalSecondSurnameChange}
                          value={
                            data.referencias.referenciaPersonal.apellidoMaterno
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[PersonalSecondSurname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Teléfono*</label>
                      <div className="input-group">
                        <input
                          type="tel"
                          onChange={handlePersonalPhoneChange}
                          value={data.referencias.referenciaPersonal.telefono}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[PersonalPhone]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Relación con usted*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleRelationshipWithYouChange}
                          value={
                            data.referencias.referenciaPersonal.relacionConUsted
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Occupation]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Tiempo de conocer a la persona*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleTimeOfKnowingChange}
                          value={
                            data.referencias.referenciaPersonal
                              .tiempoDeConocerALaPersona
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzTimeOfKnowing"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group"></div>
                  <div className="form-group"></div>
                  <div>
                    <label className="font-weight-800">
                      Referencia personal que no viva con usted ni sea un
                      familiar (2)
                    </label>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Nombre(s)*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePersonalNameSecondChange}
                          value={
                            data.referencias.referenciaPersonalSegundo.nombre
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzName"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Paterno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePersonalSurnameSecondChange}
                          value={
                            data.referencias.referenciaPersonalSegundo
                              .apellidoPaterno
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[PersonalSurname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Materno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePersonalSecondSurnameSecondChange}
                          value={
                            data.referencias.referenciaPersonalSegundo
                              .apellidoMaterno
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[PersonalSecondSurname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Teléfono*</label>
                      <div className="input-group">
                        <input
                          type="tel"
                          onChange={handlePersonalPhoneSecondChange}
                          value={
                            data.referencias.referenciaPersonalSegundo.telefono
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[PersonalPhone]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Relación con usted*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleRelationshipWithYouSecondChange}
                          value={
                            data.referencias.referenciaPersonalSegundo
                              .relacionConUsted
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Occupation]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Tiempo de conocer a la persona*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleTimeOfKnowingSecondChange}
                          value={
                            data.referencias.referenciaPersonalSegundo
                              .tiempoDeConocerALaPersona
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzTimeOfKnowingSecond"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="resumen-row-space"></div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <button
                        name="submit"
                        type="submit"
                        value="submit"
                        onClick={handleNext}
                        className="btn btn-primary w-100 d-block btn-rounded resumen-btn"
                      >
                        Siguiente paso
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Paso4 = (props) => {
  const { data, setData, setActiveStep, setTextTitleOn, selectedData } = props;
  const [selectedOtherUse, setSelectedOtherUse] = useState(false);
  const { baseUrl } = useBaseUrl();

  const handleTypeOfUseChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      financiamiento: {
        ...prevState.financiamiento,
        tipoDeUso: e.target.value,
      },
    }));

    if (e.target.value === "Otro") {
      setSelectedOtherUse(true);
    } else {
      setSelectedOtherUse(false);
    }
  };
  const handleOtherUseChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      financiamiento: {
        ...prevState.financiamiento,
        otroUso: e.target.value,
      },
    }));
  };

  const handleOtherUseSelected = (e) => {
    setSelectedOtherUse(!selectedOtherUse);
  };

  /* validación del formulario */
  const handleNext = (e) => {
    e.preventDefault();
    // Verificar si los campos obligatorios están llenos
    if (data.financiamiento.tipoDeUso) {
      const generateCotizadorPDF = async () => {
        const blob = await pdf(<CotizacionPDF data={selectedData} />).toBlob();
        const blob2 = await pdf(<PreaprobacionPDF data={data} />).toBlob();
        sendCotizadorPDF(blob, blob2);
      };

      generateCotizadorPDF();

      const sendCotizadorPDF = async (blob, blob2) => {
        try {
          const formData = new FormData();
          formData.append("file", blob, "autofinanciamiento.pdf");
          formData.append("file2", blob2, "preaprobacion.pdf");
          formData.append("nombre", data.personal.general.nombre);
          formData.append(
            "apellidoPaterno",
            data.personal.general.apellidoPaterno
          );
          formData.append(
            "apellidoMaterno",
            data.personal.general.apellidoMaterno
          );
          formData.append(
            "correoElectronico",
            data.personal.general.correoElectronico
          );

          const response = await axios.post(
            baseUrl + "preaprobacionautofinanciamientoprospecto",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.status === 200) {
            //console.log(response.data.data);
          } else {
            console.log("Error loading leasing");
          }
        } catch (error) {
          console.error(error);
        }
      };

      const generatePreaprobacionPDF = async () => {
        const blob = await pdf(<PreaprobacionPDF data={data} />).toBlob();
        const blob2 = await pdf(<CotizacionPDF data={selectedData} />).toBlob();
        sendPreaprobacionPDF(blob, blob2);
      };

      generatePreaprobacionPDF();

      const sendPreaprobacionPDF = async (blob, blob2) => {
        try {
          const formData = new FormData();
          formData.append("file", blob, "preaprobacion.pdf");
          formData.append("file2", blob2, "autofinanciamiento.pdf");
          formData.append("nombre", data.personal.general.nombre);
          formData.append(
            "apellidoPaterno",
            data.personal.general.apellidoPaterno
          );
          formData.append(
            "apellidoMaterno",
            data.personal.general.apellidoMaterno
          );
          formData.append("curp", data.personal.general.curp);
          formData.append(
            "fechaNacimiento",
            data.personal.general.fechaNacimiento
          );
          formData.append("estadoCivil", data.personal.general.estadoCivil);
          formData.append("telefono", data.personal.general.telefono);
          formData.append(
            "correoElectronico",
            data.personal.general.correoElectronico
          );
          formData.append("conyuge", data.personal.general.conyuge);
          formData.append(
            "numeroDependientesEconomicos",
            data.personal.general.numeroDependientesEconomicos
          );

          const response = await axios.post(
            baseUrl + "preaprobacionautofinanciamientoadmin",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.status === 200) {
            //console.log(response.data.data);
          } else {
            console.log("Error loading leasing");
          }
        } catch (error) {
          console.error(error);
        }
      };

      //Cambiar la activacion del titulo a true
      setTextTitleOn(true);
      // Avanzar al siguiente paso
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      // Mostrar mensaje de error o realizar alguna otra acción
      alert("Por favor, llena todos los campos obligatorios.");
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 m-b40">
            <div className="contact-area-preaprobacion">
              <form className="dlab-form style-1 dzForm">
                <input
                  type="hidden"
                  className="form-control"
                  name="dzToDo"
                  value="Contact"
                />
                <input
                  type="hidden"
                  className="form-control"
                  name="reCaptchaEnable"
                  value="0"
                />
                <div className="dzFormMsg"></div>
                <div className="row sp10">
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <label className="font-weight-800">
                      Tipo de uso del vehículo:
                    </label>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6"></div>
                  <div className="form-group"></div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Tipo de uso*</label>
                      <div className="col-lg-12 border-bottom">
                        <div className="selected-box">
                          <Form.Select
                            className="form-control sm-preaprobacion"
                            onChange={handleTypeOfUseChange}
                            value={data.financiamiento.tipoDeUso}
                          >
                            <option></option>
                            <option>Familiar/propio</option>
                            <option>Plataforma</option>
                            <option>Negocio propio</option>
                            <option>Otro</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {selectedOtherUse && (
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="font-weight-400">
                          Escribe el uso que le darás*
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            onChange={handleOtherUseChange}
                            value={data.financiamiento.otroUso}
                            className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                            required
                            name="dzOther[OtherUse]"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="resumen-row-space"></div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <button
                        name="submit"
                        type="submit"
                        value="submit"
                        onClick={handleNext}
                        className="btn btn-primary w-100 d-block btn-rounded resumen-btn"
                      >
                        Envíar solicitud
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Paso5 = (props) => {
  const { data, setData, setActiveStep } = props;

  return (
    <>
      <div className="section-head text-center">
        <div className="dlab-post-title">
          <h2>¡Tu solicitud de pre-aprobación ha sido enviada!</h2>
          <p>
            Gracias por preferirnos. En un lapso no mayor a 24 hrs recibirás por
            correo electrónico la respuesta de tu solicitud y en dado caso de
            ser aprobada un asesor se comunicará contigo...
          </p>
        </div>
      </div>
    </>
  );
};

/* const GeneratorPDF = (props) => {
  const { data } = props;
  return (
    <div>
      <PDFDownloadLink document={<PDF data={data} />} fileName="myfirstpdf.pdf">
        {({ loading, url, error, blob }) =>
          loading ? (
            <button>Loading Document ...</button>
          ) : (
            <button>Download now!</button>
          )
        }
      </PDFDownloadLink>
    </div>
  );
}; */

export default PreaprobacionAutoFinanciamiento;
