import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useBaseUrl } from "../contexts/BaseUrlContext";

import Linkify from "react-linkify";

const ChatBot = () => {
  const [showChat, setShowChat] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [animation, setAnimation] = useState("");
  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    // Muestra el tutorial al inicio
    setInitiated(true);

    // Oculta el tutorial después de 30 segundos
    const timer = setTimeout(() => {
      setInitiated(false);
    }, 10000); // 30000 milisegundos = 30 segundos

    // Limpia el temporizador si el componente se desmonta antes de que se agote el tiempo
    return () => clearTimeout(timer);
  }, []); // El array vacío significa que este efecto se ejecuta solo una vez al montar el componente

  const handleClick = () => {
    setInitiated(false);
    setIsClicked(true);
    toggleChat();
    setTimeout(() => {
      setIsClicked(false);
    }, 500);
  };

  const toggleChat = () => {
    setShowChat(!showChat);
    setAnimation(showChat ? "slideOut" : "slideIn");
  };

  return (
    <>
      {initiated && (
        <div className="tutorial">
          ¡Hola! Soy tu asistente de chat. Puedes preguntarme cualquier cosa que
          necesites.
        </div>
      )}
      <button
        onClick={handleClick}
        style={{ display: "block" }}
        className={"chatBot rocketchat" + (initiated ? " chatbot-animate" : "")}
      >
        {showChat ? (
          <i className={`fas fa-times ${isClicked ? "spin" : ""}`}></i>
        ) : (
          <i className={`fas fa-comments ${isClicked ? "swing" : ""}`}></i>
        )}
      </button>
      {showChat && <ChatWindow toggleChat={toggleChat} animation={animation} />}
    </>
  );
};

const ChatWindow = ({ toggleChat, animation }) => {
  const [messages, setMessages] = useState([
    {
      text: "¡Hola! 👋 Soy tu asistente GM. Aquí puedes: \n 🚗 Consultar la disponibilidad de vehículos \n💰 Obtener cotizaciones al proporcionarme la marca, línea, versión y modelo del vehículo \n🛠️ Brindarte atención \n📞 Comunicarte con nosotros \n¡Estoy aquí para ayudarte a encontrar el auto perfecto! 🚘",
      sender: "bot",
    },
  ]);
  const [input, setInput] = useState("");
  const [pastOutputBot, setPastOutputBot] = useState("");

  const { baseUrl } = useBaseUrl();

  const [isLoading, setIsLoading] = useState(false);
  const lastMessageRef = useRef(null);

  const scrollToLastMessage = () => {
    lastMessageRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const responseAPIBot = async (message) => {
    try {
      const response = await axios.post(baseUrl + "chatbot/respond", {
        message: message,
        pastOutputBot: pastOutputBot,
        conversation: messages,
      });
      //console.log(response.data);
      setIsLoading(false); // Oculta la burbuja de carga
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: response.data.data.message, sender: "bot" },
      ]);
      setPastOutputBot(response.data.data.message);
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: "Lo siento, no puedo responder en este momento. Intenta más tarde.",
          sender: "bot",
        },
      ]);
      setPastOutputBot(
        "Lo siento, no puedo responder en este momento. Intenta más tarde."
      );
    }
  };

  useEffect(() => {
    scrollToLastMessage();
  }, [messages]);

  const handleSend = () => {
    if (input.trim()) {
      setIsLoading(true); // Muestra la burbuja de carga
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: input, sender: "user" },
      ]);
      setInput("");
      responseAPIBot(input);
      // Aquí puedes añadir la lógica para enviar el mensaje al backend y obtener la respuesta del bot
    }
  };

  return (
    <div className={`chat-window ${animation}`}>
      <div className="chat-header">
        <b>GMAsistente</b>
        <button className="close-btn" onClick={toggleChat}>
          <b>×</b>
        </button>
      </div>
      <div className="chat-body">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`message-bubble ${msg.sender}`}
            ref={index === messages.length - 1 ? lastMessageRef : null}
          >
            {msg.text.split("\n").map((item, key) => {
              return (
                <span key={key}>
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a
                        target="_blank"
                        href={decoratedHref}
                        key={key}
                        rel="noopener noreferrer"
                      >
                        {decoratedText}
                      </a>
                    )}
                  >
                    {item}
                  </Linkify>
                  <br />
                </span>
              );
            })}
          </div>
        ))}
        {isLoading && <div className="message-bubble bot">Cargando...</div>}
      </div>
      <div className="chat-footer">
        <input
          type="text"
          placeholder="Escribe un mensaje..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSend()}
        />
        <button onClick={handleSend}>Enviar</button>
      </div>
    </div>
  );
};

export default ChatBot;
