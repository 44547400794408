import React, { createContext, useContext, useState } from 'react';

const BaseUrlContext = createContext();

export function useBaseUrl() {
  return useContext(BaseUrlContext);
}

export function BaseUrlProvider({ children }) {
  const [baseUrl, setBaseUrl] = useState("https://gmapi.gminmediauto.com.mx/public/");

  return (
    <BaseUrlContext.Provider value={{ baseUrl, setBaseUrl }}>
      {children}
    </BaseUrlContext.Provider>
  );
}