import React from 'react'
import { IMAGE } from '../constent/theme'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper'

import { Link } from 'react-router-dom';


const slider = [
    { imag1: IMAGE.advertising1, model: "LTZ", title: "Chevrolet Beat 2020", monthly : "$3,987.00" },
    { imag1: IMAGE.advertising2, model: "Advance", title: "Nissan Versa 2020", monthly : "$5,482.00" },
    { imag1: IMAGE.advertising3, model: "Grand Touring", title: "Mazda 3 2018", monthly : "$5,781.00" },
    { imag1: IMAGE.advertising4, model: "Crew Cab", title: "Nissan Frontier 2016", monthly : "$3,705.00"},
    { imag1: IMAGE.advertising5, model: "Excellence", title: "Seat Ibiza 2019", monthly : "$5,382.00" },
    { imag1: IMAGE.advertising6, model: "Style", title: "Volkswagen Jetta 2014", monthly : "$3,788.00" },
]

const Homeslide = (props) => {
    const swiperRef = React.useRef(null)
    return (
        <div className="col-lg-6">
            <div className="banner-slider">
                <Swiper className="swiper-container main-silder-swiper"
                    spaceBetween={30}
                    effect={"fade"}
                    speed={1500}
                    modules={[EffectFade, Navigation, Pagination, Autoplay]}
                    autoplay={{ delay: 1200 }}
                    loop={true}
                    ref={swiperRef}
                >
                    {
                        slider.map((slide, index) => (
                            <SwiperSlide className="swiper-slide" key={index}>
                                <div className="dlab-slide-item">
                                    <div className="silder-content">
                                        <div className="inner-content">
                                            <div className="left">
                                                <p className="car-type">{slide.model}</p>
                                                <p className="car-name slide-vertical" data-splitting >{slide.title}</p>
                                            </div>
                                            <div className="right">
                                                <p className="price-label">Mensualidad desde </p>
                                                <p className="car-price" data-splitting >{slide.monthly}</p>
                                            </div>
                                        </div>
                                        <Link to="/vehiculos" data-splitting className="discover-link">Descubre más</Link>
                                    </div>
                                    <div className="slider-img" >
                                        <img src={slide.imag1} alt="" />
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    }

                    <div className="slider-one-pagination">
                        <div className="btn-prev swiper-button-prev1 cursorPointer"
                            onClick={() => swiperRef.current.swiper.slidePrev()}
                        ><i className="fas fa-chevron-left"></i></div>
                        <div className="btn-next swiper-button-next1 cursorPointer"
                            onClick={() => swiperRef.current.swiper.slideNext()}
                        ><i className="fas fa-chevron-right"></i></div>
                    </div>
                </Swiper>
            </div>
        </div >
    )
}

export default Homeslide