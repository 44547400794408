import {
  Document,
  Text,
  Page,
  StyleSheet,
  Image,
  View,
} from "@react-pdf/renderer";

import Logo from "../assets/images/gminmediauto/logo1.jpg";

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 12,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  column: {
    width: "50%",
    backgroundColor: "rgba(238, 49, 49, 1)",
    fontSize: 10,
    color: "#fff",
    border: "1px solid rgba(238, 49, 49, 1)",
    borderRadius: 5,
    margin: 1,
    padding: 5,
  },
  column2: {
    fontSize: 10,
    width: "50%",
    border: "1px solid #f0f0f0",
    borderRadius: 5,
    margin: 1,
    padding: 5,
  },
  column3: {
    fontSize: 10,
    width: "50%",
    border: "1px solid #8c8c8c",
    backgroundColor: "rgba(250, 193, 193, 1)",
    borderRadius: 5,
    margin: 1,
    padding: 5,
  },
});

const PDF = (props) => {
  const { data } = props;

  const getCurrentDate = () => {
    const date = new Date();
    return date.toLocaleDateString("es-MX", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text></Text>
            <Image src={{ uri: '../img/logo1.jpg', method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} style={{ width: 150, textAlign: "right" }} />
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <Text></Text>
            <Text style={{ fontSize: 8, marginRight: 1 }}>
              Torre Albertina en Tehuacán Sur #60,
            </Text>
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <Text></Text>
            <Text style={{ fontSize: 8, marginRight: 1 }}>Puebla, Puebla</Text>
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <Text></Text>
            <Text style={{ fontSize: 8, marginRight: 1 }}>222 632 3835</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={{ fontSize: 10, marginLeft: 1, fontWeight: 700 }}>
            Preaprobacion con fecha: {getCurrentDate()}
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Información Personal</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.column2}>Nombre</Text>
            <Text style={styles.column2}>{data.personal.general.nombre}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Apellido Paterno</Text>
            <Text style={styles.column3}>
              {data.personal.general.apellidoPaterno}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Apellido Materno</Text>
            <Text style={styles.column2}>
              {data.personal.general.apellidoMaterno}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Fecha de Nacimiento</Text>
            <Text style={styles.column3}>
              {data.personal.general.fechaNacimiento}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Género</Text>
            <Text style={styles.column2}>{data.personal.general.genero}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>RFC</Text>
            <Text style={styles.column3}>{data.personal.general.rfc}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>CURP</Text>
            <Text style={styles.column2}>{data.personal.general.curp}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>País de Nacimiento</Text>
            <Text style={styles.column3}>
              {data.personal.general.paisNacimiento}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Nacionalidad</Text>
            <Text style={styles.column2}>
              {data.personal.general.nacionalidad}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Tipo de Identificación</Text>
            <Text style={styles.column3}>
              {data.personal.general.tipoIdentificacion}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Estado Civil</Text>
            <Text style={styles.column2}>
              {data.personal.general.estadoCivil}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Régimen</Text>
            <Text style={styles.column3}>{data.personal.general.regimen}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>
              Número de Dependientes Económicos
            </Text>
            <Text style={styles.column2}>
              {data.personal.general.numeroDependientesEconomicos}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Correo Electrónico</Text>
            <Text style={styles.column3}>
              {data.personal.general.correoElectronico}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Nivel Educativo</Text>
            <Text style={styles.column2}>
              {data.personal.general.nivelEducativo}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Teléfono celular</Text>
            <Text style={styles.column3}>
              {data.personal.general.telefonoCelular}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>calle</Text>
            <Text style={styles.column2}>{data.personal.direccion.calle}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Número Exterior</Text>
            <Text style={styles.column3}>
              {data.personal.direccion.numeroExterior}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Número Interior</Text>
            <Text style={styles.column2}>
              {data.personal.direccion.numeroInterior}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Colonia</Text>
            <Text style={styles.column3}>
              {data.personal.direccion.colonia}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Municipio/Delegación</Text>
            <Text style={styles.column2}>
              {data.personal.direccion.municipio}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Ciudad</Text>
            <Text style={styles.column3}>{data.personal.direccion.ciudad}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Estado</Text>
            <Text style={styles.column2}>{data.personal.direccion.estado}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Código Postal</Text>
            <Text style={styles.column3}>{data.personal.direccion.cp}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Tiempo viviendo en el domicilio</Text>
            <Text style={styles.column2}>
              {data.personal.direccion.tiempoViviendo}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Tipo de domicilio</Text>
            <Text style={styles.column3}>
              {data.personal.direccion.tipoDeDomicilio}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Teléfono celular</Text>
            <Text style={styles.column2}>
              {data.personal.direccion.telefonoCelular}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Información Laboral</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.column2}>Actividad económica</Text>
            <Text style={styles.column2}>
              {data.empleo.empleo.actividadEconomica}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Empresa</Text>
            <Text style={styles.column3}>{data.empleo.empleo.empresa}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Giro de la empresa</Text>
            <Text style={styles.column2}>
              {data.empleo.empleo.giroDeLaEmpresa}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Tipo de empleo</Text>
            <Text style={styles.column3}>{data.empleo.empleo.tipoEmpleo}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Jefe inmediato</Text>
            <Text style={styles.column2}>
              {data.empleo.empleo.jefeInmediato}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Tiempo en ese empleo</Text>
            <Text style={styles.column3}>
              {data.empleo.empleo.tiempoEnElEmpleo}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Número de seguro social</Text>
            <Text style={styles.column2}>
              {data.empleo.empleo.NumeroSeguroSocial}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Dirección de la empresa</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.column2}>Calle</Text>
            <Text style={styles.column2}>
              {data.empleo.direccionDeLaEmpresa.calle}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Número Exterior</Text>
            <Text style={styles.column3}>
              {data.empleo.direccionDeLaEmpresa.numeroExterior}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Número Interior</Text>
            <Text style={styles.column2}>
              {data.empleo.direccionDeLaEmpresa.numeroInterior}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Colonia</Text>
            <Text style={styles.column3}>
              {data.empleo.direccionDeLaEmpresa.colonia}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Municipio/Delegación</Text>
            <Text style={styles.column2}>
              {data.empleo.direccionDeLaEmpresa.municipio}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Ciudad</Text>
            <Text style={styles.column3}>
              {data.empleo.direccionDeLaEmpresa.ciudad}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Estado</Text>
            <Text style={styles.column2}>
              {data.empleo.direccionDeLaEmpresa.estado}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Código Postal</Text>
            <Text style={styles.column3}>
              {data.empleo.direccionDeLaEmpresa.cp}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Tiempo trabajando ahí</Text>
            <Text style={styles.column2}>
              {data.empleo.direccionDeLaEmpresa.tiempoEnElEmpleo}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Teléfono de trabajo con lada</Text>
            <Text style={styles.column3}>
              {data.empleo.direccionDeLaEmpresa.telefonoDelTrabajo}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Extensión</Text>
            <Text style={styles.column2}>
              {data.empleo.direccionDeLaEmpresa.extension}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Ingresos Mensuales</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.column2}>Recibo de nómina (Neto)</Text>
            <Text style={styles.column2}>
              {data.empleo.ingresosMensuales.reciboNominaNeto}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>
              Servicios profesionales o empresariales
            </Text>
            <Text style={styles.column3}>
              {
                data.empleo.ingresosMensuales
                  .serviciosProfesionalesOEmpresariales
              }
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Otros</Text>
            <Text style={styles.column2}>
              {data.empleo.ingresosMensuales.otros}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Referencias</Text>
        </View>

        {data.referencias.datosConyuge.tienesConyuge === "Sí" && (
          <>
            <View style={styles.section}>
              <Text style={styles.title}>Información del cónyuge</Text>
            </View>
            <View style={styles.section}></View>
              <View style={styles.row}>
                <Text style={styles.column2}>Nombre(s)</Text>
                <Text style={styles.column2}>
                  {data.referencias.datosConyuge.nombres}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.column3}>Apellido Paterno</Text>
                <Text style={styles.column3}>
                  {data.referencias.datosConyuge.apellidoPaterno}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.column2}>Apellido Materno</Text>
                <Text style={styles.column2}>
                  {data.referencias.datosConyuge.apellidoMaterno}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.column3}>Fecha de Nacimiento</Text>
                <Text style={styles.column3}>
                  {data.referencias.datosConyuge.fechaNacimiento}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.column2}>Teléfono de celular con lada</Text>
                <Text style={styles.column2}>
                  {data.referencias.datosConyuge.telefonoCelular}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.column3}>Ocupación</Text>
                <Text style={styles.column3}>
                  {data.referencias.datosConyuge.ocupacion}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.column2}>Teléfono de trabajo con lada</Text>
                <Text style={styles.column2}>
                  {data.referencias.datosConyuge.telefonoDelTrabajo}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.column3}>Extensión</Text>
                <Text style={styles.column3}>
                  {data.referencias.datosConyuge.extension}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.column2}>¿Vive en la misma dirección que él solicitante?</Text>
                <Text style={styles.column2}>{data.referencias.datosConyuge.viveEnTuMismaDireccion}</Text>
              </View>
              {data.referencias.datosConyuge.viveEnTuMismaDireccion === "No" && ( 
                <>
                  <View style={styles.row}>
                    <Text style={styles.column3}>Calle</Text>
                    <Text style={styles.column3}>
                      {data.referencias.datosConyuge.calle}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.column2}>Número Exterior</Text>
                    <Text style={styles.column2}>
                      {data.referencias.datosConyuge.numeroExterior}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.column3}>Número Interior</Text>
                    <Text style={styles.column3}>
                      {data.referencias.datosConyuge.numeroInterior}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.column2}>Colonia</Text>
                    <Text style={styles.column2}>
                      {data.referencias.datosConyuge.colonia}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.column3}>Municipio/Delegación</Text>
                    <Text style={styles.column3}>
                      {data.referencias.datosConyuge.municipio}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.column2}>Ciudad</Text>
                    <Text style={styles.column2}>
                      {data.referencias.datosConyuge.ciudad}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.column3}>Estado</Text>
                    <Text style={styles.column3}>
                      {data.referencias.datosConyuge.estado}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.column2}>Código Postal</Text>
                    <Text style={styles.column2}>
                      {data.referencias.datosConyuge.cp}
                    </Text>
                  </View>
                </>
              )}
          </>
        )}

        <View style={styles.section}>
          <Text style={styles.title}>Referencia de Familiar 1</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.column2}>Nombre(s)</Text>
            <Text style={styles.column2}>
              {data.referencias.referenciaFamiliar.nombre}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Apellido Paterno</Text>
            <Text style={styles.column3}>
              {data.referencias.referenciaFamiliar.apellidoPaterno}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Apellido Materno</Text>
            <Text style={styles.column2}>
              {data.referencias.referenciaFamiliar.apellidoMaterno}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Fecha de Nacimiento</Text>
            <Text style={styles.column3}>
              {data.referencias.referenciaFamiliar.fechaNacimiento}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Parentesco</Text>
            <Text style={styles.column2}>
              {data.referencias.referenciaFamiliar.parentesco}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Teléfono con lada</Text>
            <Text style={styles.column3}>
              {data.referencias.referenciaFamiliar.telefono}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Calle</Text>
            <Text style={styles.column2}>
              {data.referencias.referenciaFamiliar.calle}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Número Exterior</Text>
            <Text style={styles.column3}>
              {data.referencias.referenciaFamiliar.numeroExterior}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Número Interior</Text>
            <Text style={styles.column2}>
              {data.referencias.referenciaFamiliar.numeroInterior}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Colonia</Text>
            <Text style={styles.column3}>
              {data.referencias.referenciaFamiliar.colonia}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Municipio/Delegación</Text>
            <Text style={styles.column2}>
              {data.referencias.referenciaFamiliar.municipio}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Ciudad</Text>
            <Text style={styles.column3}>
              {data.referencias.referenciaFamiliar.ciudad}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Estado</Text>
            <Text style={styles.column2}>
              {data.referencias.referenciaFamiliar.estado}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Código Postal</Text>
            <Text style={styles.column3}>
              {data.referencias.referenciaFamiliar.cp}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Referencia de Familiar 2</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.column2}>Nombre(s)</Text>
            <Text style={styles.column2}>
              {data.referencias.referenciaFamiliarSegundo.nombre}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Apellido Paterno</Text>
            <Text style={styles.column3}>
              {data.referencias.referenciaFamiliarSegundo.apellidoPaterno}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Apellido Materno</Text>
            <Text style={styles.column2}>
              {data.referencias.referenciaFamiliarSegundo.apellidoMaterno}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Parentesco</Text>
            <Text style={styles.column3}>
              {data.referencias.referenciaFamiliarSegundo.parentesco}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Referencia personal 1</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.column2}>Nombre(s)</Text>
            <Text style={styles.column2}>
              {data.referencias.referenciaPersonal.nombre}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Apellido Paterno</Text>
            <Text style={styles.column3}>
              {data.referencias.referenciaPersonal.apellidoPaterno}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Apellido Materno</Text>
            <Text style={styles.column2}>
              {data.referencias.referenciaPersonal.apellidoMaterno}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Teléfono con lada</Text>
            <Text style={styles.column3}>
              {data.referencias.referenciaPersonal.telefono}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Relacion con el solicitante</Text>
            <Text style={styles.column2}>
              {data.referencias.referenciaPersonal.relacionConUsted}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Referencia personal 2</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.column2}>Nombre(s)</Text>
            <Text style={styles.column2}>
              {data.referencias.referenciaPersonalSegundo.nombre}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Apellido Paterno</Text>
            <Text style={styles.column3}>
              {data.referencias.referenciaPersonalSegundo.apellidoPaterno}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Apellido Materno</Text>
            <Text style={styles.column2}>
              {data.referencias.referenciaPersonalSegundo.apellidoMaterno}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Teléfono con lada</Text>
            <Text style={styles.column3}>
              {data.referencias.referenciaPersonalSegundo.telefono}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Relacion con el solicitante</Text>
            <Text style={styles.column2}>
              {data.referencias.referenciaPersonalSegundo.relacionConUsted}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Domiciliación</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.column2}>Banco o Institución</Text>
            <Text style={styles.column2}>
              {data.domiciliacion.bancoInstitucion}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>CLABE interbancaria</Text>
            <Text style={styles.column3}>{data.domiciliacion.ClabeInterbancaria}</Text>
          </View>
        </View>
          
        <View style={styles.section}>
          <Text style={styles.title}>Datos del Aval</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.column2}>Nombre</Text>
            <Text style={styles.column2}>{data.aval.datosAval.nombre}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Apellido Paterno</Text>
            <Text style={styles.column3}>
              {data.aval.datosAval.apellidoPaterno}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Apellido Materno</Text>
            <Text style={styles.column2}>
              {data.aval.datosAval.apellidoMaterno}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Fecha de Nacimiento</Text>
            <Text style={styles.column3}>
              {data.aval.datosAval.fechaNacimiento}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Género</Text>
            <Text style={styles.column2}>{data.aval.datosAval.genero}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>RFC</Text>
            <Text style={styles.column3}>{data.aval.datosAval.rfc}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>CURP</Text>
            <Text style={styles.column2}>{data.aval.datosAval.curp}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>País de Nacimiento</Text>
            <Text style={styles.column3}>
              {data.aval.datosAval.paisNacimiento}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Nacionalidad</Text>
            <Text style={styles.column2}>
              {data.aval.datosAval.nacionalidad}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Tipo de Identificación</Text>
            <Text style={styles.column3}>
              {data.aval.datosAval.tipoIdentificacion}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Estado Civil</Text>
            <Text style={styles.column2}>
              {data.aval.datosAval.estadoCivil}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Régimen</Text>
            <Text style={styles.column3}>{data.aval.datosAval.regimen}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>
              Número de Dependientes Económicos
            </Text>
            <Text style={styles.column2}>
              {data.aval.datosAval.numeroDependientesEconomicos}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Correo Electrónico</Text>
            <Text style={styles.column3}>
              {data.aval.datosAval.correoElectronico}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Nivel Educativo</Text>
            <Text style={styles.column2}>
              {data.aval.datosAval.nivelEducativo}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Teléfono celular</Text>
            <Text style={styles.column3}>
              {data.aval.datosAval.telefonoCelular}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>calle</Text>
            <Text style={styles.column2}>{data.aval.direccionDelObligadoOAval.calle}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Número Exterior</Text>
            <Text style={styles.column3}>
              {data.aval.direccionDelObligadoOAval.numeroExterior}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Número Interior</Text>
            <Text style={styles.column2}>
              {data.aval.direccionDelObligadoOAval.numeroInterior}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Colonia</Text>
            <Text style={styles.column3}>
              {data.aval.direccionDelObligadoOAval.colonia}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Municipio/Delegación</Text>
            <Text style={styles.column2}>
              {data.aval.direccionDelObligadoOAval.municipio}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Ciudad</Text>
            <Text style={styles.column3}>{data.aval.direccionDelObligadoOAval.ciudad}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Estado</Text>
            <Text style={styles.column2}>{data.aval.direccionDelObligadoOAval.estado}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Código Postal</Text>
            <Text style={styles.column3}>{data.aval.direccionDelObligadoOAval.cp}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Tiempo viviendo en el domicilio</Text>
            <Text style={styles.column2}>
              {data.aval.direccionDelObligadoOAval.tiempoViviendo}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column3}>Tipo de domicilio</Text>
            <Text style={styles.column3}>
              {data.aval.direccionDelObligadoOAval.tipoDeDomicilio}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column2}>Teléfono de casa con lada</Text>
            <Text style={styles.column2}>
              {data.aval.direccionDelObligadoOAval.telefonoCasa}
            </Text>
          </View>
        </View>

      </Page>
    </Document>
  );
};

export default PDF;
