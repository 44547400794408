import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { IMAGE } from "../constent/theme";
import { Swiper, SwiperSlide } from "swiper/react";
import TestimonialReting from "../element/TestimonialReting";
import TopDeal from "../components/TopDeal";
import ContectUs from "../components/ContectUs";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Link } from "react-router-dom";
import { SocialMediaLinks } from "./UseCarDeatail";

import { useBaseUrl } from "../contexts/BaseUrlContext";

const Thumbs2 = [
  { img: IMAGE.item1, img2: IMAGE.productThumb1 },
  { img: IMAGE.item2, img2: IMAGE.productThumb2 },
  { img: IMAGE.item3, img2: IMAGE.productThumb3 },
  { img: IMAGE.item3, img2: IMAGE.productThumb3 },
];
const DetalleVehiculo = () => {
  const { baseUrl } = useBaseUrl();
  const { id } = useParams();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const [car, setCar] = useState({});
  const [images, setImages] = useState([]);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const getCar = async () => {
      const response = await axios.get(
        baseUrl + `restautoaf/${id}`
      );
      //console.log(response.data.data);
      setCar(response.data.data);
      fetchImages(response.data.data.fotografiaPrincipal);
    };

    const fetchImages = async (photos) => {
      const response = await axios.get(
        baseUrl + "getimages/" + photos
      );
      setImages(response.data.data);
      //console.log(response.data.data);
      setInitialized(true);
    };

    getCar();
  }, []);

  const getCurrency2FormatMX = (number) => {
    //get type of variable number
    if (typeof number === "string") {
      number = parseFloat(number);
    }
    return number.toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN", // Puedes cambiar 'MXN' por el código de moneda correspondiente
    });
  };

  if (!initialized) {
    return null;
  }
  return (
    <>
      <div className="page-content bg-white theme-rounded">
        <nav aria-label="breadcrumb" className="breadcrumb-row">
          <div className="container">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Inicio</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/vehiculos">Vehículos</Link>
              </li>
              <li className="breadcrumb-item">
                {car.marca} {car.linea} {car.modelo} {car.version}
              </li>
            </ul>
          </div>
        </nav>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-6 m-b40">
                <div
                  className="product-gallery on-show-slider lightgallery"
                  id="lightgallery"
                >
                  <Swiper
                    className="swiper-container sync1"
                    spaceBetween={10}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    speed={1200}
                  >
                    {Object.entries(images).map(([index, imagePath]) => (
                      <SwiperSlide className="swiper-slide" key={index}>
                        <div className="dlab-thum-bx">
                          <img
                            src={`https://gminmediauto.com.mx/imgAutosAF/${car.fotografiaPrincipal}/${imagePath.file}`}
                            alt={`Image ${index}`}
                            style={{
                              width: "100%",
                              position: "relative", // Añade una posición
                              zIndex: "2",
                            }}
                          />
                          {/* Crear un div que se ponga para obtener el color principal */}
                          <div>
                            <div
                              className="overlay-bx-2"
                              style={{
                                width: "100%",
                                height: "50%",
                                background:
                                  "rgba(" +
                                  imagePath.color[0] +
                                  "," +
                                  imagePath.color[1] +
                                  "," +
                                  imagePath.color[2] +
                                  ",1)",
                              }}
                            ></div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <Swiper
                    className="swiper-container thumb-slider sync2"
                    onClick={setThumbsSwiper}
                    spaceBetween={30}
                    slidesPerView={3}
                    freeMode={true}
                    loop={false}
                    modules={[FreeMode, Navigation, Thumbs]}
                  >
                    {Object.entries(images).map(([index, imagePath]) => (
                      <SwiperSlide
                        className="swiper-slide"
                        key={index}
                        style={{ width: "179px !importent" }}
                      >
                        <div className="dlab-media">
                          <img
                            src={`https://gminmediauto.com.mx/imgAutosAF/${car.fotografiaPrincipal}/${imagePath.file}`}
                            alt={`Image ${index}`}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 m-b40 m-sm-b0">
                <div className="sticky-top">
                  <div className="dlab-post-title">
                    <h3 className="post-title">
                      <Link to={"#"}>
                        {car.marca} {car.linea} {car.modelo}
                      </Link>
                    </h3>
                    <h6 className="sub-title">{car.version}</h6>
                    <p className="m-b10">{car.descripcionAdicional}</p>
                    <div className="dlab-divider bg-gray tb15">
                      <i className="icon-dot c-square"></i>
                    </div>
                  </div>
                  <div className="feature-list m-b40 m-lg-b30">
                    <div>
                      <label>Transmisión</label>
                      <p className="value">{car.transmision}</p>
                    </div>
                    <div>
                      <label>Combustible</label>
                      <p className="value">{car.combustible}</p>
                    </div> 
                    <div>
                      <label>Kilometraje</label>
                      <p className="value">{car.kilometraje}</p>
                    </div>
                  </div>
                  <p className="m-b10">mensualidades desde:</p>
                  <div className="m-b40 m-lg-b30">
                    <Link
                      to={"/autofinanciamiento?marca=" + car.marca + "&vehiculo=" + car.linea + "&modelo=" + car.modelo}
                    >
                      <span className="btn btn-primary price-btn m-b10 m-r10">
                        {getCurrency2FormatMX(car.mensualidad)}
                      </span>
                    </Link>
                    <Link
                      to="/contacto"
                      className="btn btn-primary light shadow-none m-b10 focusBtn"
                    >
                      <i className="fas fa-phone-volume me-3"></i>Contáctanos
                    </Link>
                  </div>
                  <div className="social-list">
                    <span>Compartir en</span>
                    <SocialMediaLinks />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <TestimonialReting /> */}
        {/* Top deals page */}
        {/* <section className="content-inner-2">
                    <div className="container-fluid">
                        <div className="section-head space-lg text-center">
                            <h2 className="title">Top deals of the week</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                        </div>
                        <TopDeal />
                    </div>
                </section> */}
        {/* Contect Us page  */}
        <section className="content-inner">
          <div className="container">
            <ContectUs car={car} />
          </div>
        </section>
      </div>
    </>
  );
};

export default DetalleVehiculo;
