import React from "react";
import CarlistingBanner from "../element/CarlistingBanner";
import { IMAGE } from "../constent/theme";
import { Autoplay, EffectFade, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Bestcar from "../components/Bestcar";
import TopDeal from "../components/TopDeal";
import ContectUs from "../components/ContectUs";

import PercentIcon from "@mui/icons-material/Percent";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import WidthNormalIcon from "@mui/icons-material/WidthNormal";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";

import ImgBannerPlanes from "../assets/images/gminmediauto/banner-planes.png";
import ImgFinanciaTuCarro from "../assets/images/gminmediauto/financia-tu-carro.png";

const cardBlog = [
  {
    title: "EXCLUSIVE",
    img: (
      <svg
        width="47"
        height="42"
        viewBox="0 0 47 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.9787 1.46102L15.2421 12.3206L2.40714 14.0677C0.105462 14.3793 -0.816966 17.0286 0.85219 18.546L10.138 26.9942L7.94171 38.9283C7.54638 41.0854 9.97984 42.7012 12.018 41.6924L23.5 36.0575L34.982 41.6924C37.0202 42.693 39.4536 41.0854 39.0583 38.9283L36.862 26.9942L46.1478 18.546C47.817 17.0286 46.8945 14.3793 44.5929 14.0677L31.7579 12.3206L26.0213 1.46102C24.9935 -0.474683 22.0153 -0.499289 20.9787 1.46102Z"
          fill="#0D3DE6"
        />
      </svg>
    ),
  },
  {
    title: "UPDATE",
    img: (
      <svg
        width="44"
        height="42"
        viewBox="0 0 44 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44 26.2499V39.375C44 40.8245 42.7685 42 41.25 42H27.5C25.9815 42 24.75 40.8245 24.75 39.375V26.2499C24.75 24.8004 25.9815 23.6249 27.5 23.6249H41.25C42.7685 23.6249 44 24.8004 44 26.2499ZM11 20.9999C4.92508 20.9999 0 25.7011 0 31.4999C0 37.2988 4.92508 42 11 42C17.0749 42 22 37.2988 22 31.4999C22 25.7011 17.0749 20.9999 11 20.9999ZM41.1666 18.3749C43.3443 18.3749 44.7055 16.1871 43.6167 14.4374L35.4501 1.3123C34.3613 -0.437434 31.6388 -0.437434 30.5499 1.3123L22.3833 14.4374C21.2945 16.1871 22.6557 18.3749 24.8334 18.3749H41.1666Z"
          fill="#0D3DE5"
        />
      </svg>
    ),
  },
  {
    title: "SECURE",
    img: (
      <svg
        width="36"
        height="38"
        viewBox="0 0 36 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33.7875 6.21086L19.3875 0.272785C18.9489 0.0926974 18.4786 0 18.0037 0C17.5289 0 17.0586 0.0926974 16.62 0.272785L2.22 6.21086C0.8775 6.76014 0 8.05909 0 9.49908C0 24.2329 8.5875 34.4167 16.6125 37.7272C17.4975 38.0909 18.495 38.0909 19.38 37.7272C25.8075 35.0774 36 25.9253 36 9.49908C36 8.05909 35.1225 6.76014 33.7875 6.21086ZM18.0075 33.1252L18 4.84511L31.1925 10.2859C30.945 21.5237 25.035 29.6663 18.0075 33.1252Z"
          fill="#0D3DE6"
        />
      </svg>
    ),
  },
  {
    title: "EXCLUSIVE",
    img: (
      <svg
        width="47"
        height="42"
        viewBox="0 0 47 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.9787 1.46102L15.2421 12.3206L2.40714 14.0677C0.105462 14.3793 -0.816966 17.0286 0.85219 18.546L10.138 26.9942L7.94171 38.9283C7.54638 41.0854 9.97984 42.7012 12.018 41.6924L23.5 36.0575L34.982 41.6924C37.0202 42.693 39.4536 41.0854 39.0583 38.9283L36.862 26.9942L46.1478 18.546C47.817 17.0286 46.8945 14.3793 44.5929 14.0677L31.7579 12.3206L26.0213 1.46102C24.9935 -0.474683 22.0153 -0.499289 20.9787 1.46102Z"
          fill="#0D3DE6"
        />
      </svg>
    ),
  },
  {
    title: "UPDATE",
    img: (
      <svg
        width="44"
        height="42"
        viewBox="0 0 44 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44 26.2499V39.375C44 40.8245 42.7685 42 41.25 42H27.5C25.9815 42 24.75 40.8245 24.75 39.375V26.2499C24.75 24.8004 25.9815 23.6249 27.5 23.6249H41.25C42.7685 23.6249 44 24.8004 44 26.2499ZM11 20.9999C4.92508 20.9999 0 25.7011 0 31.4999C0 37.2988 4.92508 42 11 42C17.0749 42 22 37.2988 22 31.4999C22 25.7011 17.0749 20.9999 11 20.9999ZM41.1666 18.3749C43.3443 18.3749 44.7055 16.1871 43.6167 14.4374L35.4501 1.3123C34.3613 -0.437434 31.6388 -0.437434 30.5499 1.3123L22.3833 14.4374C21.2945 16.1871 22.6557 18.3749 24.8334 18.3749H41.1666Z"
          fill="#0D3DE5"
        />
      </svg>
    ),
  },
  {
    title: "SECURE",
    img: (
      <svg
        width="36"
        height="38"
        viewBox="0 0 36 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33.7875 6.21086L19.3875 0.272785C18.9489 0.0926974 18.4786 0 18.0037 0C17.5289 0 17.0586 0.0926974 16.62 0.272785L2.22 6.21086C0.8775 6.76014 0 8.05909 0 9.49908C0 24.2329 8.5875 34.4167 16.6125 37.7272C17.4975 38.0909 18.495 38.0909 19.38 37.7272C25.8075 35.0774 36 25.9253 36 9.49908C36 8.05909 35.1225 6.76014 33.7875 6.21086ZM18.0075 33.1252L18 4.84511L31.1925 10.2859C30.945 21.5237 25.035 29.6663 18.0075 33.1252Z"
          fill="#0D3DE6"
        />
      </svg>
    ),
  },
  {
    title: "EXCLUSIVE",
    img: (
      <svg
        width="47"
        height="42"
        viewBox="0 0 47 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.9787 1.46102L15.2421 12.3206L2.40714 14.0677C0.105462 14.3793 -0.816966 17.0286 0.85219 18.546L10.138 26.9942L7.94171 38.9283C7.54638 41.0854 9.97984 42.7012 12.018 41.6924L23.5 36.0575L34.982 41.6924C37.0202 42.693 39.4536 41.0854 39.0583 38.9283L36.862 26.9942L46.1478 18.546C47.817 17.0286 46.8945 14.3793 44.5929 14.0677L31.7579 12.3206L26.0213 1.46102C24.9935 -0.474683 22.0153 -0.499289 20.9787 1.46102Z"
          fill="#0D3DE6"
        />
      </svg>
    ),
  },
  {
    title: "UPDATE",
    img: (
      <svg
        width="44"
        height="42"
        viewBox="0 0 44 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44 26.2499V39.375C44 40.8245 42.7685 42 41.25 42H27.5C25.9815 42 24.75 40.8245 24.75 39.375V26.2499C24.75 24.8004 25.9815 23.6249 27.5 23.6249H41.25C42.7685 23.6249 44 24.8004 44 26.2499ZM11 20.9999C4.92508 20.9999 0 25.7011 0 31.4999C0 37.2988 4.92508 42 11 42C17.0749 42 22 37.2988 22 31.4999C22 25.7011 17.0749 20.9999 11 20.9999ZM41.1666 18.3749C43.3443 18.3749 44.7055 16.1871 43.6167 14.4374L35.4501 1.3123C34.3613 -0.437434 31.6388 -0.437434 30.5499 1.3123L22.3833 14.4374C21.2945 16.1871 22.6557 18.3749 24.8334 18.3749H41.1666Z"
          fill="#0D3DE5"
        />
      </svg>
    ),
  },
];
const Service = () => {
  return (
    <>
      <div className="page-content bg-white">
        <CarlistingBanner img={ImgBannerPlanes} title={"Planes"} />
        <section className="content-inner-2">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-6 m-b30 d-flex align-items-stretch">
                <div
                  className="icon-bx-wraper style-1 hover-overlay-effect background-black"
                  style={{ background: "#000" }}
                >
                  <div className="icon-md m-b40">
                    <PercentIcon style={{ width: 60, height: 55 }} />
                  </div>
                  <div className="icon-content">
                    <h4 className="title m-b10" style={{ color: "#fff" }}>
                      Plan 100
                    </h4>
                    <ol>
                      <li style={{ listStyleType: "disc" }}>
                        Financiamos el 100% del valor del auto.
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Se requieren 2 referencias NO FAMILIARES.
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        {" "}
                        Año de seguro (cobertura amplia).
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Tasa de interés: 8%.
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Incluye GPS para tu seguridad.
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Visita domiciliaria para validación de datos.
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Validación de datos vía telefónica (5-10 días hábiles).
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        La validación debe ser al 100% para permanecer en este plan.
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Adjudicación en 10 a 15 días naturales.
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        25 días naturales, Garantía de contrato.
                      </li>
                    </ol>
                  </div>
                  <div className="effect bg-primary"></div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 m-b30 d-flex align-items-stretch">
                <div className="icon-bx-wraper style-1 hover-overlay-effect">
                  <div className="icon-md m-b40">
                    <RequestQuoteIcon style={{ width: 60, height: 55 }} />
                  </div>
                  <div className="icon-content">
                    <h4 className="title m-b10">Plan autofinanciamiento</h4>
                    <ol>
                      <li style={{ listStyleType: "disc" }}>GPS incluido.</li>
                      <li style={{ listStyleType: "disc" }}>
                        Historial de pago: Construye un historial sólido.
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Pago puntual: Del 1 al 3 de cada mes.
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Voluntad, puntualidad y solvencia: Valoramos tu
                        compromiso.
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Adjudicación apartir del sexto mes.
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Financiamiento máximo: del 60%.
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Requisitos: Se necesita Firma de contrato, comprobante
                        de ingresos INE, comprobante de domicilio, encuesta
                        Socio-económica, aval y enganche.
                      </li>
                    </ol>
                  </div>
                  <div className="effect bg-primary"></div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 m-b30 d-flex align-items-stretch">
                <div className="icon-bx-wraper style-1 hover-overlay-effect">
                  <div className="icon-md m-b40">
                    <WidthNormalIcon style={{ width: 60, height: 55 }} />
                  </div>
                  <div className="icon-content">
                    <h4 className="title m-b10">Plan tradicional</h4>
                    <ol>
                      <li style={{ listStyleType: "disc" }}>
                        GPS (instalación, mantenimiento y mensualidad).
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Enganche desde el 40%
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Tasa de interes del 15.5% anual.
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Pago de seguro (cobertura amplia).
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Requisitos: Revisión de búro, comprobante de ingresos,
                        historial crediticio, pago de apertura.
                      </li>
                    </ol>
                  </div>
                  <div className="effect bg-primary"></div>
                </div>
              </div>
              {/* <div className="col-xl-12 col-lg-6 col-md-6 m-b30">
                <div
                  className="icon-bx-wraper style-1 hover-overlay-effect background-black"
                  style={{ background: "#000" }}
                >
                  <div className="icon-md m-b40">
                    <AssignmentTurnedInOutlinedIcon
                      style={{ width: 60, height: 55 }}
                    />
                  </div>
                  <div className="icon-content">
                    <h4 className="title m-b10" style={{ color: "#fff" }}>
                      Despues de la adjudicación
                    </h4>
                    <ol>
                      <li style={{ listStyleType: "disc" }}>
                        Requisitos mínimos: Sin aval, sin revisar Buró de
                        Crédito y sin comprobante de ingresos.
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Tasa de interés: 8% anual, sin anualidad.
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Pago de apertura incluye:
                        <ol>
                          <li style={{ listStyleType: "disc" }}>
                            Primera mensualidad.
                          </li>
                          <li style={{ listStyleType: "disc" }}>
                            Seguro de automóvil COBERTURA AMPLIA.
                          </li>
                          <li style={{ listStyleType: "disc" }}>
                            Seguro de vida.
                          </li>
                          <li style={{ listStyleType: "disc" }}>
                            Seguro de desempleo (3 mensualidades).
                          </li>
                          <li style={{ listStyleType: "disc" }}>
                            Gastos administrativos e impuestos (3% + I.V.A.).
                          </li>
                        </ol>
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Garantía de automóvil:
                        <ol>
                          <li style={{ listStyleType: "disc" }}>
                            Seminuevos: 3 meses.
                          </li>
                          <li style={{ listStyleType: "disc" }}>
                            Nuevos: De 2 a 10 años.
                          </li>
                        </ol>
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        GPS antirrobo incluido: Instalación, mantenimiento y
                        mensualidades.
                      </li>
                    </ol>
                  </div>
                  <div className="effect bg-primary"></div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        {/* Best Car page >>> */}
        <section className="content-inner-2">
          <div className="container">
            <div className="row features-box">
              <div className="row features-box">
                <div className="col-lg-6 m-b30 m-sm-b0">
                  <div className="image-slider-box">
                    <Swiper
                      className="swiper-container feature-swiper"
                      effect="fade"
                      speed={1500}
                      autoplay={{
                        delay: 1500,
                      }}
                      modules={[EffectFade, Pagination, Autoplay]}
                      pagination={{
                        el: ".swiper-pagination",
                        clickable: true,
                      }}
                    >
                      <SwiperSlide className="swiper-slide">
                        <div className="dlab-media">
                          <img src={ImgFinanciaTuCarro} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                <div className="col-lg-6 align-self-center m-b30">
                  <div className="content-box right">
                    <div className="section-head">
                      <h6 className="sub-title style-1">Contáctanos</h6>
                      <h2 className="title">¿Necesitas más información?</h2>
                    </div>
                    <p className="m-b40">
                      Si tienes alguna pregunta o necesitas más detalles sobre
                      nuestros planes de financiamiento, no dudes en
                      contactarnos. Estamos aquí para ayudarte en todo lo que
                      necesites.
                      <ol>
                        <li style={{ listStyleType: "disc" }}>
                          Teléfono: 221 433 3335 o 814 074 3686
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Visítanos en nuestra sucursal más cercana: Col. la
                          Paz, Puebla o Edificio Ocampo, Monterrey, NL.
                        </li>
                      </ol>
                      En un horario de:
                      <ol>
                        <li style={{ listStyleType: "disc" }}>
                          Lunes a viernes: 10:00am a 6:00pm.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Sábado: 10:00am a 3:00pm.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Domingo: 11:00am a 3:00pm.
                        </li>
                      </ol>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Service;
