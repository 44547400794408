export const IMAGE = {
    logo1: require('../assets/images/gminmediauto/logo1.jpg'),
    logo2: require('../assets/images/logo-2.png'),
    slide_pic1: require('../assets/images/main-slider/pic1.jpg'),
    slide_pic2: require('../assets/images/main-slider/pic2.jpg'),
    slide_pic3: require('../assets/images/main-slider/pic3.jpg'),
    patternImg: require('../assets/images/pattern/pattern4.png'),
    about1: require('../assets/images/about/about1.jpg'),
    patternImg2: require('../assets/images/pattern/pattern6.png'),
    aboutPic1: require('../assets/images/about/pic1.jpg'),
    aboutPic2: require('../assets/images/about/pic2.jpg'),
    aboutPic3: require('../assets/images/about/pic3.jpg'),
    aboutPic4: require('../assets/images/about/pic4.jpg'),
    dealPic1: require('../assets/images/deal/pic1.jpg'),
    dealPic2: require('../assets/images/deal/pic2.jpg'),
    dealPic3: require('../assets/images/deal/pic3.jpg'),
    dealPic4: require('../assets/images/deal/pic4.jpg'),
    aboutPic5: require('../assets/images/about/pic5.jpg'),
    patternImg3: require('../assets/images/pattern/pattern7.png'),
    patternImg5: require('../assets/images/pattern/pattern5.png'),
    home2Slideimg1: require('../assets/images/main-slider/slide1.jpg'),
    home2BackgrounImg: require('../assets/images/background/about.jpg'),
    home3BackgroundImg: require('../assets/images/main-slider/slide4.jpg'),
    home3gellerypic1: require('../assets/images/gallery/pic7.jpg'),
    home3gellerypic2: require('../assets/images/gallery/pic12.jpg'),
    home3gellerypic3: require('../assets/images/gallery/pic18.jpg'),
    home3backgroundbg: require('../assets/images/background/bg1.jpg'),
    productPic1: require('../assets/images/product/grid/pic1.jpg'),
    productPic2: require('../assets/images/product/grid/pic2.jpg'),
    productPic3: require('../assets/images/product/grid/pic3.jpg'),
    productPic4: require('../assets/images/product/grid/pic4.jpg'),
    productPic5: require('../assets/images/product/grid/pic5.jpg'),
    home4mainSlider1: require('../assets/images/main-slider/slide5.jpg'),
    home4mainSlider2: require('../assets/images/main-slider/slide6.jpg'),
    home4mainSlider3: require('../assets/images/main-slider/slide7.jpg'),
    home4mainSlider4: require('../assets/images/main-slider/slide8.jpg'),
    adsImg1: require('../assets/images/ads/img2.jpg'),
    adsImg: require('../assets/images/ads/img2.jpg'),
    home4Ourwork1: require('../assets/images/our-work/pic1.jpg'),
    home4Ourwork2: require('../assets/images/our-work/pic2.jpg'),
    home4Ourwork3: require('../assets/images/our-work/pic3.jpg'),
    galleryImg1: require('../assets/images/our-work/pic5.jpg'),
    galleryImg2: require('../assets/images/our-work/pic4.jpg'),
    galleryImg3: require('../assets/images/our-work/pic3.jpg'),
    galleryImg4: require('../assets/images/our-work/pic2.jpg'),
    galleryImg5: require('../assets/images/our-work/pic6.jpg'),
    testimonialPic1: require('../assets/images/testimonials/pic1.jpg'),
    testimonialPic2: require('../assets/images/testimonials/pic2.jpg'),
    testimonialPic3: require('../assets/images/testimonials/pic3.jpg'),
    bannerImg1: require('../assets/images/banner/bnr4.jpg'),
    productGridPic1: require('../assets/images/product/grid/pic6.jpg'),
    bnr3: require('../assets/images/banner/bnr3.jpg'),
    bnr2: require('../assets/images/banner/bnr2.jpg'),
    bnrLeasing: require('../assets/images/banner/bnrleasing.jpg'),
    bnrPreApproval: require('../assets/images/banner/bnrpreapproval.jpg'),
    blogPic1: require('../assets/images/blog/recent-blog/pic1.jpg'),
    blogPic2: require('../assets/images/blog/recent-blog/pic2.jpg'),
    blogPic3: require('../assets/images/blog/recent-blog/pic3.jpg'),
    blogPic4: require('../assets/images/blog/recent-blog/pic4.jpg'),
    blogPic5: require('../assets/images/blog/recent-blog/pic5.jpg'),
    adsImg: require('../assets/images/ads/img1.jpg'),
    clientLogo1: require('../assets/images/client-logo/logo1.jpg'),
    clientLogo2: require('../assets/images/client-logo/logo2.jpg'),
    clientLogo3: require('../assets/images/client-logo/logo3.jpg'),
    clientLogo4: require('../assets/images/client-logo/logo4.jpg'),
    clientLogo5: require('../assets/images/client-logo/logo5.jpg'),
    clientLogo6: require('../assets/images/client-logo/logo6.jpg'),
    clientLogo7: require('../assets/images/client-logo/logo7.jpg'),
    clientLogo8: require('../assets/images/client-logo/logo8.jpg'),
    clientLogo9: require('../assets/images/client-logo/logo9.jpg'),
    clientLogo10: require('../assets/images/client-logo/logo10.jpg'),
    clientLogo11: require('../assets/images/client-logo/logo11.jpg'),
    clientLogo12: require('../assets/images/client-logo/logo12.jpg'),
    clientLogo13: require('../assets/images/client-logo/logo13.jpg'),
    clientLogo14: require('../assets/images/client-logo/logo14.jpg'),
    clientLogo15: require('../assets/images/client-logo/logo15.jpg'),
    clientLogo16: require('../assets/images/client-logo/logo16.jpg'),
    clientLogo17: require('../assets/images/client-logo/logo17.jpg'),
    clientLogo18: require('../assets/images/client-logo/logo18.jpg'),
    home2Slideimg2: require('../assets/images/main-slider/slide2.jpg'),
    slide3slideImg: require('../assets/images/main-slider/slide3.jpg'),
    patternImg2: require('../assets/images/pattern/pattern5.png'),
    testimonialImg: require('../assets/images/testimonials/user.jpg'),
    testimonialImg2: require('../assets/images/testimonials/building.jpg'),
    bnr1: require('../assets/images/banner/bnr1.jpg'),
    slideItem1: require('../assets/images/product/item2/item1.jpg'),
    slideItem2: require('../assets/images/product/item2/item2.jpg'),
    slideItem3: require('../assets/images/product/item2/item3.jpg'),
    slideItem4: require('../assets/images/product/item2/item4.jpg'),
    thumb1: require('../assets/images/product/thumb2/thumb1.jpg'),
    thumb2: require('../assets/images/product/thumb2/thumb2.jpg'),
    thumb3: require('../assets/images/product/thumb2/thumb3.jpg'),
    thumb4: require('../assets/images/product/thumb2/thumb4.jpg'),

    item1: require('../assets/images/product/item/item1.jpg'),
    item2: require('../assets/images/product/item/item2.jpg'),
    item3: require('../assets/images/product/item/item3.jpg'),
    galleryPic1: require('../assets/images/gallery/pic1.jpg'),
    galleryPic2: require('../assets/images/gallery/pic2.jpg'),
    galleryPic3: require('../assets/images/gallery/pic3.jpg'),
    galleryPic4: require('../assets/images/gallery/pic4.jpg'),
    galleryPic5: require('../assets/images/gallery/pic5.jpg'),
    galleryPic6: require('../assets/images/gallery/pic6.jpg'),
    galleryPic7: require('../assets/images/gallery/pic7.jpg'),
    galleryPic8: require('../assets/images/gallery/pic8.jpg'),
    galleryPic9: require('../assets/images/gallery/pic9.jpg'),
    galleryPic10: require('../assets/images/gallery/pic10.jpg'),
    galleryPic11: require('../assets/images/gallery/pic11.jpg'),
    galleryPic12: require('../assets/images/gallery/pic12.jpg'),
    galleryPic13: require('../assets/images/gallery/pic13.jpg'),
    galleryPic14: require('../assets/images/gallery/pic14.jpg'),
    galleryPic15: require('../assets/images/gallery/pic15.jpg'),
    galleryPic16: require('../assets/images/gallery/pic16.jpg'),
    galleryPic17: require('../assets/images/gallery/pic17.jpg'),
    mapPic1: require('../assets/images/map/map1.jpg'),
    teamPic1: require('../assets/images/team/pic1.jpg'),
    teamPic2: require('../assets/images/team/pic2.jpg'),
    teamPic3: require('../assets/images/team/pic3.jpg'),
    teamPic4: require('../assets/images/team/pic4.jpg'),
    teamPic5: require('../assets/images/team/pic5.jpg'),
    teamPic6: require('../assets/images/team/pic6.jpg'),
    teamPic7: require('../assets/images/team/pic7.jpg'),
    teamPic8: require('../assets/images/team/pic8.jpg'),
    blogGridpic1: require('../assets/images/blog/blog-grid/pic1.png'),
    blogGridpic2: require('../assets/images/blog/blog-grid/pic2.png'),
    blogGridpic3: require('../assets/images/blog/blog-grid/pic3.png'),
    blogGridpic4: require('../assets/images/blog/blog-grid/pic4.png'),
    blogGridpic5: require('../assets/images/blog/blog-grid/pic5.png'),
    blogGridpic6: require('../assets/images/blog/blog-grid/pic6.png'),
    blogGridpic7: require('../assets/images/blog/blog-grid/pic7.png'),
    blogGridpic8: require('../assets/images/blog/blog-grid/pic8.png'),
    blogGridpic9: require('../assets/images/blog/blog-grid/pic9.png'),
    blogGridpic10: require('../assets/images/blog/blog-grid/pic10.png'),
    blogGridpic11: require('../assets/images/blog/blog-grid/pic11.png'),
    blogLargPic3: require('../assets/images/blog/large/pic3.jpg'),
    blogLargPic4: require('../assets/images/blog/large/pic4.jpg'),
    blogLargPic5: require('../assets/images/blog/large/pic5.jpg'),
    smallGaleryPic1: require('../assets/images/gallery/small/pic1.jpg'),
    smallGaleryPic2: require('../assets/images/gallery/small/pic2.jpg'),
    smallGaleryPic3: require('../assets/images/gallery/small/pic3.jpg'),
    smallGaleryPic4: require('../assets/images/gallery/small/pic4.jpg'),
    smallGaleryPic5: require('../assets/images/gallery/small/pic5.jpg'),
    smallGaleryPic6: require('../assets/images/gallery/small/pic6.jpg'),
    largeBlogPic1: require('../assets/images/blog/large/pic1.jpg'),
    productThumb1: require('../assets/images/product/thumb/thumb1.jpg'),
    productThumb2: require('../assets/images/product/thumb/thumb2.jpg'),
    productThumb3: require('../assets/images/product/thumb/thumb3.jpg'),
    advertising1: require('../assets/images/main-slider/advertising1.png'),
    advertising2: require('../assets/images/main-slider/advertising2.png'),   
    advertising3: require('../assets/images/main-slider/advertising3.png'),
    advertising4: require('../assets/images/main-slider/advertising4.png'),
    advertising5: require('../assets/images/main-slider/advertising5.png'),
    advertising6: require('../assets/images/main-slider/advertising6.png'),
    
}
function allImages() {
    <IMAGE />
}
export default allImages;