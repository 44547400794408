import React, { useReducer, useRef, useState, useEffect } from "react";
import axios from "axios";
import Homeslide from "../element/Homeslide";
import { IMAGE } from "../constent/theme";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";

const selectOption1 = [
  { title: "Make" },
  { title: "BMW" },
  { title: "Honda" },
  { title: "Hyundai" },
  { title: "Nissan" },
  { title: "Mercedes Benz " },
];

const selectOption2 = [
  { title: "CLASS" },
  { title: "2010" },
  { title: "2011" },
  { title: "2012" },
];

const selectOption3 = [
  { title: "MODEl" },
  { title: "3-Series" },
  { title: "Carrera" },
  { title: "GT-R" },
  { title: "Cayenne" },
  { title: "Mazda6 " },
  { title: "Macan " },
];

const Homebanner = (props) => {
  const { cars } = props;
  const [optinActive, setOptinActive] = useState(0);
  const [optinActive2, setOptinActive2] = useState(0);
  const [optinActive3, setOptinActive3] = useState(0);
  const text = useRef("");
  const text2 = useRef("");
  const text3 = useRef("");

  const [brands, setBrands] = useState([]);
  const [lines, setLines] = useState([]);
  const [models, setModels] = useState([]);

  const [selectedBrand, setSelectedBrand] = useState("Marca");
  const [selectedLine, setSelectedLine] = useState("Vehículo");
  const [selectedModel, setSelectedModel] = useState("Modelo");

  useEffect(() => {
    if (cars && cars.length > 0) {
      setBrands(getUniqueBrands());
      setLines(getUniqueLinesByBrand(selectedBrand));
      setModels(getUniqueModelsByLineAndBrand(selectedLine, selectedBrand));
    }
  }, [cars]);

  const getUniqueBrands = () => {
    const uniqueBrands = [...new Set(cars.map((car) => car.marca))];
    return uniqueBrands;
  };

  const getUniqueLinesByBrand = (brand) => {
    let uniqueLines = [];
    if (brand === "Marca") {
      uniqueLines = [...new Set(cars.map((car) => car.linea))];
    } else {
      uniqueLines = [
        ...new Set(
          cars.filter((car) => car.marca === brand).map((car) => car.linea)
        ),
      ];
    }

    return uniqueLines;
  };

  const getUniqueModelsByLineAndBrand = (line, brand) => {
    let uniqueModels = [];
    if (line === "Vehículo" && brand === "Marca") {
      uniqueModels = [...new Set(cars.map((car) => car.modelo))];
    } else if (line === "Vehículo") {
      uniqueModels = [
        ...new Set(
          cars.filter((car) => car.marca === brand).map((car) => car.modelo)
        ),
      ];
    } else if (brand === "Marca") {
      uniqueModels = [
        ...new Set(
          cars.filter((car) => car.linea === line).map((car) => car.modelo)
        ),
      ];
    } else {
      uniqueModels = [
        ...new Set(
          cars
            .filter((car) => car.marca === brand && car.linea === line)
            .map((car) => car.modelo)
        ),
      ];
    }

    return uniqueModels;
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
    setSelectedLine("Vehículo");
    setSelectedModel("Modelo");
    setLines(getUniqueLinesByBrand(event.target.value));
    setModels(getUniqueModelsByLineAndBrand("Vehículo", event.target.value));
  };

  const handleLineChange = (event) => {
    setSelectedLine(event.target.value);
    setSelectedModel("Modelo");
    setModels(getUniqueModelsByLineAndBrand(event.target.value, selectedBrand));
  };

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "showOtion1":
        return {
          ...state,
          Value1: !state.Value1,
          Value2: false,
          Value3: false,
        };
      case "showOtion2":
        return {
          ...state,
          Value2: !state.Value2,
          Value1: false,
          Value3: false,
        };
      case "showOtion3":
        return {
          ...state,
          Value3: !state.Value3,
          Value1: false,
          Value2: false,
        };
      case "selectedButton":
        setOptinActive(action.ind);
        text.current.textContent = selectOption1[action.ind].title;
        return { ...state, Value1: false };
      case "selectedButton2":
        setOptinActive2(action.ind);
        text2.current.textContent = selectOption2[action.ind].title;
        return { ...state, Value2: false };
      case "selectedButton3":
        setOptinActive3(action.ind);
        text3.current.textContent = selectOption3[action.ind].title;
        return { ...state, Value3: false };
      default:
        return state;
    }
  };

  const initialState = false;
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <div className="col-lg-6 align-self-center">
        <div className="banner-content">
          <div className="trending-box">
            <span className="text-btn">GMInmediauto</span>
            Más que Autos, Creando Confianza
          </div>
          <h1 className="title">
            Tu auto nuevo está más cerca de lo que crees
          </h1>
          <p>
            En GMInmediauto, te ofrecemos un proceso de compra seguro y
            transparente. Cumplimos con todas las regulaciones legales para
            brindarte la tranquilidad que necesitas al adquirir tu auto.
          </p>
          <div className="car-search-box row item3 p-10">
            <div className="col-md-3 col-sm-6">
              <div className="selected-box">
                <div className="inner show">
                  <Form.Select
                    value={selectedBrand}
                    onChange={handleBrandChange}
                  >
                    <option value="Marca">Marca</option>
                    {brands.map((item, ind) => (
                      <option key={ind} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="selected-box">
                <div className="inner show">
                  <Form.Select value={selectedLine} onChange={handleLineChange}>
                    <option value="Vehículo">Vehículo</option>
                    {lines.map((item, ind) => (
                      <option key={ind} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="selected-box">
                <div className="inner show">
                  <Form.Select
                    value={selectedModel}
                    onChange={handleModelChange}
                  >
                    <option value="Modelo">Modelo</option>
                    {models.map((item, ind) => (
                      <option key={ind} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <Link
                to={
                  "vehiculos?marca=" +
                  selectedBrand +
                  "&vehiculo=" +
                  selectedLine +
                  "&modelo=" +
                  selectedModel
                }
                className="btn btn-lg effect-1 shadow-none btn-primary d-flex justify-content-between"
              >
                <span className="d-flex justify-content-between w-100">
                  BUSCAR<i className="las la-long-arrow-alt-right"></i>
                </span>
              </Link>
            </div>
            <img className="img2 move-2" src={IMAGE.patternImg5} alt="" />
          </div>
          <Link to={"/vehiculos"} className="btn-link-lg">
            Prueba la búsqueda avanzada{" "}
            <i className="las la-long-arrow-alt-right"></i>
          </Link>
          <div className="tags-area">
            <p>Marcas populares</p>
            <ul className="tag-list">
              <li>
                <Link to={"/vehiculos?marca=Mazda"}>MAZDA</Link>
              </li>
              <li>
                <Link to={"/vehiculos?marca=Volkswagen"}>VOLKSWAGEN</Link>
              </li>
              <li>
                <Link to={"/vehiculos?marca=Nissan"}>NISSAN</Link>
              </li>
              <li>
                <Link to={"/vehiculos?marca=Seat"}>SEAT</Link>
              </li>
            </ul>
          </div>
          <img className="img1 move-1" src={IMAGE.patternImg} alt="" />
        </div>
      </div>
      <Homeslide />
    </>
  );
};

export default Homebanner;
