import React, { useState} from "react";
import axios from "axios";

const ImportarAutosFormulario = (props) => {
  const { route } = props;
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(route, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        alert("File uploaded successfully");
      }
      else {
        alert("Error uploading file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <>
      <div className="col-xl-6 m-b40">
        <div className="contact-area1">
          <form className="dlab-form style-1 dzForm" onSubmit={handleSubmit}>
            
            <div className="dzFormMsg"></div>

            <div className="row sp10">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Selecciona el excel en el formato apropiado:</label>
                  <div className="input-group">
                    <input
                      type="file"
                      accept=".xlsx"
                      required
                      className="form-control"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <button
                  name="submit"
                  type="submit"
                  value="submit"
                  className="btn btn-primary w-100 d-block btn-rounded"
                >
                  Importar autos
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ImportarAutosFormulario;
