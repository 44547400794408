import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SettingsIcon from '@mui/icons-material/Settings';
import HandshakeIcon from '@mui/icons-material/Handshake';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const AboutSlider = () => {
  return (
    <Swiper
      className="swiper-container about-swiper"
      slidesPerView={3}
      speed={1200}
      breakpoints={{
        1280: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
        320: {
          slidesPerView: 1,
        },
      }}
    >
      <SwiperSlide className="swiper-slide">
        <div className="icon-bx-wraper style-1 hover-overlay-effect">
          <div className="icon-md m-b40">
          <SettingsIcon style={{ color: '#ff5269', width: 60, height: 55 }}/>
          </div>
          <div className="icon-content">
            <h4 className="title text-white">
              <Link to="/planes" id="about-title" className="text-effect-1">
                Planes Personalizados
              </Link>
            </h4>
            <ul>
              <li>1. Financiamiento adaptable a tu perfil y necesidades.</li>
              <li>2. Plazos de 36 a 60 meses con pagos mensuales.</li>
            </ul>
          </div>
          <br class="break-for-desktop" />
          <div className="effect bg-primary"></div>
        </div>
      </SwiperSlide>

      <SwiperSlide className="swiper-slide">
        <div className="icon-bx-wraper style-1 hover-overlay-effect">
          <div className="icon-md m-b40">
          <HandshakeIcon style={{ color: '#ff5269', width: 60, height: 55 }}/>
          </div>
          <div className="icon-content">
            <h4 className="title text-white">
              <Link to="/planes" id="about-title" className="text-effect-1">
                Adjudicación Rápida
              </Link>
            </h4>
            <ul>
              <li>1. Obtén tu auto en 25 días.</li>
              <li>
                2. Adjudicación por referencias o modalidades como sorteo,
                subasta o puntualidad de pagos.
              </li>
            </ul>
          </div>
          <div className="effect bg-primary"></div>
        </div>
      </SwiperSlide>

      <SwiperSlide className="swiper-slide">
        <div className="icon-bx-wraper style-1 hover-overlay-effect">
          <div className="icon-md m-b40">
          <CreditCardIcon style={{ color: '#ff5269', width: 60, height: 55 }}/>
          </div>
          <div className="icon-content">
            <h4 className="title text-white">
              <Link to="/pagos" id="about-title" className="text-effect-1">
                Flexibilidad Financiera
              </Link>
            </h4>
            <ul>
              <li>
                1. Opción de aportar a capital para reducir mensualidades.
              </li>
              <li>
                2. Políticas de crédito flexibles para adaptarnos a tu situación.
              </li>
            </ul>
            <br class="break-for-desktop" />
          </div>
          <div className="effect bg-primary"></div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default AboutSlider;
