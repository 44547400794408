import React from "react";
import { Link } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Pagination = (props) => {
  const { totalPage, currentPage, setCurrentPage } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const pageRange = isMobile ? 5 : 10;

  const pages = () => {
    let pages = [];
    let startPage = currentPage - Math.floor(pageRange / 2) > 0 ? currentPage - Math.floor(pageRange / 2) : 1;
    let endPage = startPage + pageRange - 1 <= totalPage ? startPage + pageRange - 1 : totalPage;
  
    if (endPage - startPage < pageRange - 1 && startPage !== 1) {
      startPage = endPage - (pageRange - 1);
    }
  
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <Link
            className="page-link"
            to={"#"}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </Link>
        </li>
      );
    }
    return pages;
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <nav aria-label="Blog Pagination">
      <ul className="pagination text-center m-b30">
        <li className="page-item">
          <Link
            className="page-link prev"
            to={"#"}
            onClick={() => prevPage()}
          >
            <i className="la la-angle-left"></i>
          </Link>
        </li>
        {pages()}
        <li className="page-item">
          <Link className="page-link next" to={"#"} onClick={() => nextPage()}>
            <i className="la la-angle-right"></i>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
