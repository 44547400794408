import React, { useState, useEffect } from "react";
import axios from "axios";
import Homebanner from "../components/Homebanner";
import About from "../components/About";
import CarFeatures from "../components/CarFeatures";
import TopDeal from "../components/TopDeal";
import Bestcar from "../components/Bestcar";
import ContectUs from "../components/ContectUs";
import AllCarBrands from "../components/AllCarBrands";
import Testimonial from "../components/Testimonial";
import { Link } from "react-router-dom";
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import ModalVideo from "react-modal-video";

import { useBaseUrl } from "../contexts/BaseUrlContext";
/* icons */
import LockOpenIcon from "@mui/icons-material/LockOpen";
import SecurityIcon from "@mui/icons-material/Security";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import KeyIcon from "@mui/icons-material/Key";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";

import logoProfeco from "../assets/images/gminmediauto/logo-profeco.png";

const Home = () => {
  const { baseUrl } = useBaseUrl();
  
  const [open, setOpen] = useState(false);
  const [cars, setCars] = useState([]);
  const [initialized, setInitialized] = useState(false);

  const getCarList = async () => {
    try {
      const response = await axios.get(
        baseUrl + "restautoaf",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        //console.log(response.data.data);
        setCars(response.data.data);
        setInitialized(true);
      } else {
        console.log("Error loading cars");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCarList();
  }, []);

  return (
    <>
      <div className="page-content bg-white">
        <div className="banner-one">
          <div className="row">
            <Homebanner cars={cars}/>
          </div>
        </div>
        {/* import About page >>> */}
        <section className="content-inner-2 overflow-hidden">
          <div className="container">
            <About />
          </div>
        </section>
        {/* Show car brands page >>> */}
        <AllCarBrands />
        {/* ===========Our services >>>>>>> */}
        <div className="section-full bg-white content-inner" id="our-service">
          <div className="container">
            <div className="section-head text-center head-style-2 wow fadeIn">
              <h6 className="text-primary sub-title">
                ¿Listo para conducir tu propio auto?
              </h6>
              <h2 className="title">
                En GMInmediauto es posible estrenar fácil, rápido y seguro
              </h2>
              <p></p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp mb-3 d-flex align-items-stretch">
                <div className="icon-bx-wraper style-6 center m-b40">
                  <div className="icon-bx-sm bg-primary m-b20">
                    <span className="icon-cell">
                      <LockOpenIcon
                        style={{ color: "#fff", width: 60, height: 55 }}
                      />
                    </span>
                  </div>
                  <div className="icon-content">
                    <h5 className="dlab-tilte ">Requisitos mínimos</h5>
                    <p>
                      Lo más importante para nosotros, es cumplir tus sueños,
                      por eso contamos con opciones para que todos puedan
                      estrenar.
                      <ol>
                        <li style={{ listStyleType: "disc" }}>
                          Posibilidad de autorización para clientes en buró de
                          crédito, sin comprobante de ingresos.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Opciones de autorización de crédito sin aval.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Mensualidades congeladas al momento de la entrega de
                          tu auto.
                        </li>
                      </ol>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp mb-3 d-flex align-items-stretch">
                <div className="icon-bx-wraper style-6 center m-b40">
                  <div className="icon-bx-sm bg-primary m-b20">
                    <span className="icon-cell">
                      <SecurityIcon
                        style={{ color: "#fff", width: 60, height: 55 }}
                      />
                    </span>
                  </div>
                  <div className="icon-content">
                    <h5 className="dlab-tilte ">Seguridad y Claridad</h5>
                    <p>
                      Nos enfocamos en ofrecerte sólo lo mejor.
                      <ol>
                        <li style={{ listStyleType: "disc" }}>
                          Respaldo de la Red de Distribuidores Autorizados.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Vehículos nacionales, importados, baja y alta gama.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Localizador satelital por todo el plazo de la
                          operación
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Protección de seguro de vida y desempleo incluidos en
                          su mensualidad
                        </li>
                      </ol>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp mb-3 d-flex align-items-stretch">
                <div className="icon-bx-wraper style-6 center m-b40">
                  <div className="icon-bx-sm bg-primary m-b20">
                    <span className="icon-cell">
                      <CreditScoreIcon
                        style={{ color: "#fff", width: 60, height: 55 }}
                      />
                    </span>
                  </div>
                  <div className="icon-content">
                    <h5 className="dlab-tilte ">Facilidad al pagar</h5>
                    <p>
                      En GMInmediauto, te ofrecemos facilidades para que pagar
                      sea sencillo y conveniente.
                      <ol>
                        <li style={{ listStyleType: "disc" }}>
                          Elige el plazo que mejor se ajuste a tus necesidades,
                          desde 12 hasta 60 meses.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Pagos en Web Site para tu mayor comodidad.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Opciones de pago en diferentes instituciones
                          bancarias, autoservicio y tiendas de conveniencia.
                        </li>
                      </ol>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp mb-3 d-flex align-items-stretch">
                <div className="icon-bx-wraper style-6 center m-b40">
                  <div className="icon-bx-sm bg-primary m-b20">
                    <span className="icon-cell">
                      <HandshakeIcon
                        style={{ color: "#fff", width: 60, height: 55 }}
                      />
                    </span>
                  </div>
                  <div className="icon-content">
                    <h5 className="dlab-tilte ">Confiamos en tu palabra</h5>
                    <p>
                      En GMInmediauto, confiamos en ti y en nuestra palabra.
                      <ol>
                        <li style={{ listStyleType: "disc" }}>
                          Nos comprometemos a ofrecerte un servicio honesto y
                          transparente en cada paso del proceso.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Nuestro equipo está siempre disponible para resolver
                          tus dudas y brindarte el mejor servicio.
                        </li>
                      </ol>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp mb-3 d-flex align-items-stretch">
                <div className="icon-bx-wraper style-6 center m-b40">
                  <div className="icon-bx-sm bg-primary m-b20">
                    <span className="icon-cell">
                      <AutoFixHighIcon
                        style={{ color: "#fff", width: 60, height: 55 }}
                      />
                    </span>
                  </div>
                  <div className="icon-content">
                    <h5 className="dlab-tilte ">
                      Más fácil de lo que te imaginas
                    </h5>
                    <p>
                      Es un crédito tradicional que te ofrece la posibilidad de
                      adquirir el Auto que más te guste con planes a tu medida y
                      formas de pago accesibles. Solo necesitas INE y
                      Comprobante de Domicilio, obten la respuesta el mismo día
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp mb-3 d-flex align-items-stretch">
                <div className="icon-bx-wraper style-6 center m-b40">
                  <div className="icon-bx-sm bg-primary m-b20">
                    <span className="icon-cell">
                      <KeyIcon
                        style={{ color: "#fff", width: 60, height: 55 }}
                      />
                    </span>
                  </div>
                  <div className="icon-content">
                    <h5 className="dlab-tilte ">Tenemos una opción para ti</h5>
                    <p>
                      No importa si eres estudiante, emprendedor, jubilado o no
                      cuentas con historial crediticio, tenemos un crédito
                      especial para ti. En GMInmediauto te ofrecemos todas las
                      facilidades para que estrenes con un plan a tu medida y
                      pongas tu Auto a trabajar.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ============Steps >>>>>>> */}
        <section className="section-full bg-white">
          <div className="container">
            <div className="section-head text-center space-lg">
              <h2 className="title">¡Tu Nuevo Auto en 4 Sencillos Pasos!</h2>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-6 m-b30">
                <div className="icon-bx-wraper left">
                  <div className="icon-lg text-primary radius me-3">
                    <Link to="#" className="icon-cell text-primary">
                      <LooksOneIcon
                        style={{ color: "#ff5269", width: 60, height: 55 }}
                      />
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h6 className="dlab-tilte m-a0">
                      Solicita tu preaprobación
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 m-b30">
                <div className="icon-bx-wraper left">
                  <div className="icon-lg text-primary radius me-3">
                    <Link to="#" className="icon-cell text-primary">
                      <LooksTwoIcon
                        style={{ color: "#ff5269", width: 60, height: 55 }}
                      />
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h6 className="dlab-tilte m-a0">
                      Agenda tu cita para conocer los Planes
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 m-b30">
                <div className="icon-bx-wraper left">
                  <div className="icon-lg text-primary radius me-3">
                    <Link to="#" className="icon-cell text-primary">
                      <Looks3Icon
                        style={{ color: "#ff5269", width: 60, height: 55 }}
                      />
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h6 className="dlab-tilte m-a0">
                      Firma tu contrato y entrega los requisitos
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 m-b30">
                <div className="icon-bx-wraper left">
                  <div className="icon-lg text-primary radius me-3">
                    <Link to="#" className="icon-cell text-primary">
                      <Looks4Icon
                        style={{ color: "#ff5269", width: 60, height: 55 }}
                      />
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h6 className="dlab-tilte m-a0">Estrena tu auto</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ===============Testimonial >>>>> */}
        <div className="section-full bg-gray content-inner-1" id="client">
          <div className="container">
            <div
              className="section-head text-center wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.2s"
            >
              <h2 className="title">Testimonios de Nuestros Clientes</h2>
            </div>
            <Testimonial />
          </div>
        </div>
        {/* Car Future page */}
        <section className="section-full bg-white content-inner">
          <div className="container">
            <div className="row">
              <div className="col-md-6 m-b30">
                    <img src={logoProfeco} alt="logo-profeco" className="size-logo-profeco"/>
                  
              </div>
              <div className="col-md-6 m-b30">
                <div className="icon-bx-wraper left">
                  <div className="icon-content">
                  <h6 className="text-primary sub-title">Para tu tranquilidad y seguridad</h6> 
                  <h3 className="title">Estamos acreditados por PROFECO</h3>
                    <p>GMInmediauto está regulado por la Procuraduría Federal del Consumidor (PROFECO), garantizando transparencia y legalidad en nuestros servicios.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Car Future page */}
        {/* <section className="content-inner-2">
          <div className="container">
            <CarFeatures />
          </div>
        </section> */}
        {/* Top deals page >>> */}
        {/* <section className="content-inner-2">
          <div className="container-fluid">
            <div className="section-head space-lg text-center">
              <h2 className="title">Top deals of the week</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              </p>
            </div>
            <TopDeal />
          </div>
        </section> */}
        {/* Best Car page >>> */}
        {/* <section className="content-inner-2">
          <div className="container">
            <div className="row features-box">
              <Bestcar setOpen={setOpen} />
            </div>
          </div>
        </section> */}
        {/* Contect Us page >>> */}
        {/* <section className="content-inner">
          <div className="container">
            <ContectUs />
          </div>
        </section> */}
      </div>
      {/* <ModalVideo
        channel="youtube"
        autoplay
        isOpen={open}
        videoId="PkkV1vLHUvQ"
        onClose={() => setOpen(false)}
      /> */}
    </>
  );
};

export default Home;
