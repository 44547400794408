import React from 'react'
import { IMAGE } from '../constent/theme'
import AboutSlider from '../element/AboutSlider'

import ImgConocenos from '../assets/images/gminmediauto/conocenos.png';


const About = () => {
    return (

        <div className="row about-bx1">
            <div className="col-lg-5">
                <div className="dlab-media">
                    <img src={ImgConocenos} alt="" />
                </div>
            </div>
            <div className="col-lg-7 align-self-center" >
                <div className="section-head">
                    <h6 className="text-primary sub-title">Conócenos</h6>
                    <h2 className="title">Con GMInmediauto tu eliges</h2>
                </div>
                <p>En GMInmediauto nos dedicamos a brindarte la mejor experiencia en la compra de tu próximo auto, ofreciéndote seguridad, confianza y flexibilidad. Con nosotros, encontrarás planes de financiamiento diseñados específicamente para adaptarse a tu perfil y necesidades individuales.</p>
               <AboutSlider/>
            </div>
        </div>
    )
}

export default About