import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { alpha, styled } from "@mui/material/styles";

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: "#ee3131",
  "& .MuiSlider-thumb": {
    "&:hover, &.Mui-focusVisible": {
      boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.error.main, 0.16)}`,
    },
    "&.Mui-active": {
      boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.error.main, 0.16)}`,
    },
  },
}));

const RangeSliderMUI = (props) => {
  const { data, setData, min, max, steps } = props;
  const MAX = max;
  const MIN = min;
  const marks = [
    {
      value: MIN,
      label: "",
    },
    {
      value: MAX,
      label: "",
    },
  ];

  const handleChange = (_, newValue) => {
    setData((prevData) => ({
      ...prevData,
      filtros: {
        ...prevData.filtros,
        plazos: newValue,
      },
    }));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <CustomSlider
        marks={marks}
        step={steps}
        value={data.filtros.plazos}
        valueLabelDisplay="auto"
        min={MIN}
        max={MAX}
        onChange={handleChange}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="body2"
          onClick={() =>
            setData((prevData) => ({
              ...prevData,
              filtros: {
                ...prevData.filtros,
                plazos: MIN,
              },
            }))
          }
          sx={{ cursor: "pointer" }}
        >
          {MIN} meses
        </Typography>
        <Typography
          variant="body2"
          onClick={() =>
            setData((prevData) => ({
              ...prevData,
              filtros: {
                ...prevData.filtros,
                plazos: MAX,
              },
            }))
          }
          sx={{ cursor: "pointer" }}
        >
          {MAX} meses
        </Typography>
      </Box>
    </Box>
  );
};

export default RangeSliderMUI;
