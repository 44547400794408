import React from "react";
import CarlistingBanner from "../element/CarlistingBanner";
import { IMAGE } from "../constent/theme";
import { Autoplay, EffectFade, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Bestcar from "../components/Bestcar";
import TopDeal from "../components/TopDeal";
import ContectUs from "../components/ContectUs";

import ImgBannerPoliticas from "../assets/images/gminmediauto/banner-politicas.png";

const PoliticasPrivacidad = () => {
  return (
    <>
      <div className="page-content bg-white">
        <CarlistingBanner img={ImgBannerPoliticas} title={"Políticas de privacidad"} />
        <section className="content-inner-2">
          <div className="container">
            <div className="row">
              <h3 className="title">Quiénes somos</h3>
              <p>
                GM Orientación Financiera Automotriz S.A.S. de C.V. somos una
                empresa comprometida en ayudar a alcanzar las metas de todos
                nuestros clientes, responsables y preocupados por el desarrollo
                de la movilidad de los mismos. La dirección de nuestra web es:
                https://gminmediauto.com.mx
              </p>
              <h3 className="title">Comentarios</h3>
              <p>
                Cuando los visitantes dejan comentarios en la web, recopilamos
                los datos que se muestran en el formulario de comentarios, así
                como la dirección IP del visitante y la cadena de agentes de
                usuario del navegador para ayudar a la detección de spam.{" "}
              </p>
              <p>
                Una cadena anónima creada a partir de tu dirección de correo
                electrónico (también llamada hash) puede ser proporcionada al
                servicio de Gravatar para ver si la estás usando. La política de
                privacidad del servicio Gravatar está disponible aquí:
                https://automattic.com/privacy/. Después de la aprobación de tu
                comentario, la imagen de tu perfil es visible para el público en
                el contexto de tu comentario.
              </p>
              <h3 className="title">Cookies</h3>
              <p>
                Si dejas un comentario en nuestro sitio puedes elegir guardar tu
                nombre, dirección de correo electrónico y web en cookies. Esto
                es para tu comodidad, para que no tengas que volver a rellenar
                tus datos cuando dejes otro comentario. Estas cookies tendrán
                una duración de un año.
              </p>
              <p>
                Si tienes una cuenta y te conectas a este sitio, instalaremos
                una cookie temporal para determinar si tu navegador acepta
                cookies. Esta cookie no contiene datos personales y se elimina
                al cerrar el navegador.
              </p>
              <p>
                Cuando accedas, también instalaremos varias cookies para guardar
                tu información de acceso y tus opciones de visualización de
                pantalla. Las cookies de acceso duran dos días, y las cookies de
                opciones de pantalla duran un año. Si seleccionas «Recuérdarme»,
                tu acceso perdurará durante dos semanas. Si sales de tu cuenta,
                las cookies de acceso se eliminarán.
              </p>
              <p>
                Si editas o publicas un artículo se guardará una cookie
                adicional en tu navegador. Esta cookie no incluye datos
                personales y simplemente indica el ID del artículo que acabas de
                editar. Caduca después de 1 día.
              </p>
              <h3 className="title">
                Contenido incrustado de otros sitios web
              </h3>
              <p>
                Los artículos de este sitio pueden incluir contenido incrustado
                (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido
                incrustado de otras webs se comporta exactamente de la misma
                manera que si el visitante hubiera visitado la otra web.
              </p>
              <p>
                Estas web pueden recopilar datos sobre ti, utilizar cookies,
                incrustar un seguimiento adicional de terceros, y supervisar tu
                interacción con ese contenido incrustado, incluido el
                seguimiento de tu interacción con el contenido incrustado si
                tienes una cuenta y estás conectado a esa web.
              </p>
              <h3 className="title">Con quién compartimos tus datos</h3>
              <p>
                GM Orientación Financiera Automotriz S.A.S de C.V. hace
                conocimiento expreso que mantendrá en sus registros físicos y
                electrónicos y/o digitales y llevará acabo el manejo de tú
                información personal con la finalidad de que:
              </p>
              <p>
                <b>Primero.</b> Sea de cumplimiento a la legislación aplicable a
                la materia.
              </p>
              <p>
                <b>Segundo.</b> Lleve a cabo el análisis de mis datos personales
                con la finalidad de decidir sobre el otorgamiento del plan de
                autofinanciamiento contratado.
              </p>
              <p>
                <b>Tercero.</b>. Del cumplimiento de las obligaciones que
                deriven del contrato que en su caso se documente para formalizar
                el otorgamiento de su adjudicación.
              </p>
              <p>
                <b>Cuarto.</b> Pueda transferir por cualquier medio mis datos
                personales a personas físicas y morales, ya sean estas empresas
                o filiales, subsidiarias o relacionadas, proveedores y/o
                terceros que presten sus servicios a GM Orientación Financiera
                Automotriz S.A.S de C.V. para la realización de sus operaciones
                y consecución de sus fines.
              </p>
              <p>
                <b>Quinto.</b> En general a GM Orientación Financiera Automotriz
                S.A.S de C.V. , sus partes relacionadas, proveedores y/o
                terceros, puedan traer mis datos personales con fines
                financieros, administrativos, de calidad, publicitarios y/o
                estadísticos.
              </p>
              <h3 className="title">Qué derechos tienes sobre tus datos</h3>
              <p>
                Si tienes una cuenta o has dejado comentarios en esta web,
                puedes solicitar recibir un archivo de exportación de los datos
                personales que tenemos sobre ti, incluyendo cualquier dato que
                nos hayas proporcionado. También puedes solicitar que eliminemos
                cualquier dato personal que tengamos sobre ti. Esto no incluye
                ningún dato que estemos obligados a conservar con fines
                administrativos, legales o de seguridad.
              </p>
              <h3 className="title">Dónde se envían tus datos</h3>
              <p>
                Los comentarios de los visitantes puede que los revise un
                servicio de detección automática de spam.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PoliticasPrivacidad;
