import React, { useState, useEffect } from "react";
import { IMAGE } from "../constent/theme";
import AboutSlider from "../element/AboutSlider";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { SocialMediaLinks } from "../pages/UseCarDeatail";

const DetalleAutoCotizadorAutoFinanciamiento = (props) => {
  const { textTitleOn, data } = props;
  const defaultRoutesImgCar = "https://gminmediauto.com.mx/imgAutosAF/";
  const Thumbs2 = [
    { img: defaultRoutesImgCar + data.auto.fotografiaPrincipal + "/1.png" },
  ];

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const getCurrency2FormatMX = (number) => {
    //get type of variable number
    if (typeof number === "string") {
      number = parseFloat(number);
    }
    return number.toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN", // Puedes cambiar 'MXN' por el código de moneda correspondiente
    });
  };

  //año
  const getCurrentYear = () => {
    return new Date().getFullYear(); // Obtiene el año actual
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12 col-lg-12">
            <div className="col-12 m-b30">
              {textTitleOn && (
                <div className="row text-center">
                  <div className="dlab-post-title">
                    <h2>
                      Estás muy cerca de estrenar este {getCurrentYear()}{" "}
                    </h2>
                  </div>
                </div>
              )}

              <div className="car-list-box-preaprobacion list-view-preaprobacion">
                <div className="list-info">
                  <div className="sticky-top">
                    <div className="dlab-post-title">
                      <h3 className="post-title">
                        <Link to={"#"}>
                          {data.auto.marca +
                            " " +
                            data.auto.linea +
                            " " +
                            data.auto.version}
                        </Link>
                      </h3>
                      <h6 className="sub-title">{data.auto.modelo}</h6>
                      <div className="dlab-divider bg-gray tb15">
                        <i className="icon-dot c-square"></i>
                      </div>
                    </div>
                    <div className="feature-list m-b40 m-lg-b30">
                      <div>
                        <label>{"Precio"}</label>
                        <p className="value">{getCurrency2FormatMX(Math.ceil(data.cotizacion.costoTotal))}</p>
                      </div>
                      <div>
                        <label>{"A " + Math.ceil(data.filtros.plazos) + " meses de"}</label>
                        <p className="value">{getCurrency2FormatMX(Math.ceil(data.cotizacion.mensualidad))}</p>
                      </div>
                      <div>
                        <label>{"Apertura"}</label>
                        <p className="value">{getCurrency2FormatMX(Math.ceil(data.filtros.enganche))}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="media-box">
                  <div className="sticky-top">
                    <div
                      className="product-gallery on-show-slider lightgallery"
                      id="lightgallery"
                    >
                      <Swiper
                        className="swiper-container sync1"
                        spaceBetween={10}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        speed={1200}
                      >
                        {Thumbs2.map((item, index) => (
                          <SwiperSlide className="swiper-slide" key={index}>
                            <div className="dlab-thum-bx">
                              <img src={item.img} alt="" />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetalleAutoCotizadorAutoFinanciamiento;
