import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";
import { IMAGE } from "../constent/theme";
import { Dropdown } from "react-bootstrap";
import { Form } from "react-bootstrap";

const CarList = [
  { img: IMAGE.productPic1 },
  { img: IMAGE.productPic2 },
  { img: IMAGE.productPic3 },
  { img: IMAGE.productPic4 },
  { img: IMAGE.productPic5 },
  { img: IMAGE.productGridPic1 },
];

export const CarListSlideContent = (props) => {
  const {
    cars,
    handleSelectedCar,
    pageState,
    setPageState,
    pageLimit,
    setPageLimit,
    routeImg,
  } = props;
  const defaultRoutesImgCar = routeImg;

  const [screenSize, setScreenSize] = React.useState(false);

  useEffect(() => {
    const handleResize = () => {
      const newScreenSize = window.innerWidth < 765;
      setScreenSize(newScreenSize);
      setScreenSize(newScreenSize);
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getCurrency2FormatMX = (number) => {
    //get type of variable number
    if (typeof number === "string") {
      number = parseFloat(number);
    }
    return number.toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN", // Puedes cambiar 'MXN' por el código de moneda correspondiente
    });
  };

  useEffect(() => {
    setPageLimit(Math.ceil(cars.length / 12));
    //console.log("limit", Math.ceil(cars.length / 12));
  }, [cars]);

  return (
    <>
      <div className="row lightgallery col-lg-12">
        {cars.map((list, listKey) => {
          if (listKey < pageState * 12 && listKey >= (pageState - 1) * 12) {
            return (
              <div
                className="col-xl-3 col-lg-6 col-md-12 m-b30"
                key={listKey}
                onClick={() => handleSelectedCar(list)}
              >
                <div className="car-list-box">
                  <div className="media-box">
                    <img
                      src={
                        defaultRoutesImgCar +
                        list.fotografiaPrincipal +
                        "/1.png"
                      }
                      alt=""
                      style={{ width: "436px", height: "318px", zIndex: 2, position: "relative" }}
                    />
                    <div>
                      <div
                        className="overlay-bx-3"
                        style={{
                          width: "100%",
                          height: "50%",
                          background:
                            "rgba(" +
                            list.colorPrincipalR +
                            "," +
                            list.colorPrincipalG +
                            "," +
                            list.colorPrincipalB +
                            ",1)",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="list-info">
                    <h4 className="title mb-0">
                      {/* <Link to={"/car-details"} data-splitting> */}
                      <Link data-splitting>
                        {list.marca + " " + list.linea + " " + list.modelo}
                      </Link>
                    </h4>
                    <div className="car-type">{list.version}</div>
                    <span className="badge m-b30" style={{zIndex: 3}}>
                      Desde {getCurrency2FormatMX(list.precioLista)}
                    </span>
                    <div className="feature-list">
                      {!screenSize && (
                        <div>
                          <label>Transmisión</label>
                          <p className="value">{list.transmision}</p>
                        </div>
                      )}
                      <div>
                        <label>Combustible</label>
                        <p className="value">{list.combustible}</p>
                      </div>
                      {list.kilometraje && (
                        <div>
                          <label>Kilometraje</label>
                          {routeImg === "https://gminmediauto.com.mx/imgAutosAF/" ? (
                            <p className="value">{list.kilometraje}</p>
                          ) : (
                            <p className="value">{list.kilometraje} km</p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
      <Pagination
        totalPage={pageLimit}
        setCurrentPage={setPageState}
        currentPage={pageState}
      />
    </>
  );
};

const Carlist = (props) => {
  const {
    cars,
    handleSelectedCar,
    routeImg,
    initialBrand,
    initialLine,
    initialModel,
    routeNavigate,
  } = props;
  const [pageState, setPageState] = useState(1);
  const [pageLimit, setPageLimit] = useState(1);
  const navigate = useNavigate();

  const [filteredCars, setFilteredCars] = useState(cars);

  //first filter
  const [brands, setBrands] = useState([]);
  const [lines, setLines] = useState([]);
  const [models, setModels] = useState([]);

  const [selectedBrand, setSelectedBrand] = useState("Marca");
  const [selectedLine, setSelectedLine] = useState("Vehículo");
  const [selectedModel, setSelectedModel] = useState("Modelo");

  //second filter
  const [search, setSearch] = useState("");

  const [selectBtn, setSelectBtn] = useState("Newest");
  const [bgColor, setColor] = useState(2);
  const [initialized, setInitialized] = useState(false);

  //methods for first filter
  const getUniqueBrands = () => {
    const uniqueBrands = [...new Set(cars.map((car) => car.marca))];
    return uniqueBrands;
  };

  const getUniqueLinesByBrand = (brand) => {
    let uniqueLines = [];
    if (brand === "Marca") {
      uniqueLines = [...new Set(cars.map((car) => car.linea))];
    } else {
      uniqueLines = [
        ...new Set(
          cars.filter((car) => car.marca === brand).map((car) => car.linea)
        ),
      ];
    }

    return uniqueLines;
  };

  const getUniqueModelsByLineAndBrand = (line, brand) => {
    let uniqueModels = [];
    if (line === "Vehículo" && brand === "Marca") {
      uniqueModels = [...new Set(cars.map((car) => car.modelo))];
    } else if (line === "Vehículo") {
      uniqueModels = [
        ...new Set(
          cars.filter((car) => car.marca === brand).map((car) => car.modelo)
        ),
      ];
    } else if (brand === "Marca") {
      uniqueModels = [
        ...new Set(
          cars.filter((car) => car.linea === line).map((car) => car.modelo)
        ),
      ];
    } else {
      uniqueModels = [
        ...new Set(
          cars
            .filter((car) => car.marca === brand && car.linea === line)
            .map((car) => car.modelo)
        ),
      ];
    }

    return uniqueModels;
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
    setSelectedLine("Vehículo");
    setSelectedModel("Modelo");
    setLines(getUniqueLinesByBrand(event.target.value));
    setModels(getUniqueModelsByLineAndBrand("Vehículo", event.target.value));

    //enviar filtro
    setFilteredCars(
      getFirstFilteredCars(event.target.value, "Vehículo", "Modelo")
    );

    setPageState(1);
  };

  const handleLineChange = (event) => {
    setSelectedLine(event.target.value);
    setSelectedModel("Modelo");
    setModels(getUniqueModelsByLineAndBrand(event.target.value, selectedBrand));

    //enviar filtro
    setFilteredCars(
      getFirstFilteredCars(selectedBrand, event.target.value, "Modelo")
    );

    setPageState(1);
  };

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);

    //enviar filtro
    setFilteredCars(
      getFirstFilteredCars(selectedBrand, selectedLine, event.target.value)
    );

    setPageState(1);
  };

  const getFirstFilteredCars = (brand, line, model) => {
    if (brand === "Marca" && line === "Vehículo" && model === "Modelo") {
      return cars;
    } else if (brand === "Marca" && line === "Vehículo") {
      return cars.filter((car) => car.modelo === model);
    } else if (brand === "Marca" && model === "Modelo") {
      return cars.filter((car) => car.linea === line);
    } else if (line === "Vehículo" && model === "Modelo") {
      return cars.filter((car) => car.marca === brand);
    } else if (brand === "Marca") {
      return cars.filter((car) => car.linea === line && car.modelo === model);
    } else if (line === "Vehículo") {
      return cars.filter((car) => car.marca === brand && car.modelo === model);
    } else if (model === "Modelo") {
      return cars.filter((car) => car.marca === brand && car.linea === line);
    } else {
      return cars.filter(
        (car) =>
          car.marca === brand && car.linea === line && car.modelo === model
      );
    }
  };

  //methods for second filter
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSearchSubmit = () => {
    setSelectedBrand("Marca");
    setSelectedLine("Vehículo");
    setSelectedModel("Modelo");

    setLines(getUniqueLinesByBrand("Marca"));
    setModels(getUniqueModelsByLineAndBrand("Vehículo", "Marca"));

    //enviar filtro
    setFilteredCars(getSecondFilteredCars(search));

    setPageState(1);
  };

  const getSecondFilteredCars = (search) => {
    if (search === "") {
      return cars;
    } else {
      return cars.filter(
        (car) =>
          car.marca.includes(search) ||
          car.linea.includes(search) ||
          car.modelo.includes(search) ||
          car.version.includes(search) ||
          (car.marca + " " + car.linea).includes(search) ||
          (car.linea + " " + car.version).includes(search) ||
          (car.linea + " " + car.version + " " + car.modelo).includes(search) ||
          (
            car.marca +
            " " +
            car.linea +
            " " +
            car.version +
            " " +
            car.modelo
          ).includes(search) ||
          (car.marca + " " + car.version).includes(search) ||
          (car.marca + " " + car.version + " " + car.modelo).includes(search)
      );
    }
  };

  const setInitialValues = () => {
    const valueInitialBrand = initialBrand !== null ? initialBrand : "Marca";
    const valueInitialLine = initialLine !== null ? initialLine : "Vehículo";
    const valueInitialModel = initialModel !== null ? initialModel : "Modelo";

    setSelectedBrand(valueInitialBrand);
    setSelectedLine(valueInitialLine);
    setSelectedModel(valueInitialModel);

    /* console.log(
      "initial values",
      valueInitialBrand,
      valueInitialLine,
      valueInitialModel
    ); */

    setFilteredCars(
      getFirstFilteredCars(
        valueInitialBrand,
        valueInitialLine,
        valueInitialModel
      )
    );
  };

  useEffect(() => {
    setInitialValues();
    setBrands(getUniqueBrands());
    setLines(getUniqueLinesByBrand(selectedBrand));
    setModels(getUniqueModelsByLineAndBrand(selectedLine, selectedBrand));
    setInitialized(true);
  }, []);

  const setColorBtn = (action) => {
    setColor(action);
  };

  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="col-xl-12 col-lg-12" style={{ padding: "32px" }}>
        <div className="catagory-result-row">
          {/*<h5 className="serch-result">
              Showing <strong>8 product from 40</strong>
              </h5>*/}
          <div className="Form-group row col-md-12">
            <div className="col-md-3">
              <div className="selected-box">
                <Form.Select
                  className="form-control sm"
                  value={selectedBrand}
                  onChange={handleBrandChange}
                >
                  <option>Marca</option>
                  {brands.map((brand, brandKey) => (
                    <option key={brandKey}>{brand}</option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="selected-box">
                <Form.Select
                  className="form-control sm"
                  value={selectedLine}
                  onChange={handleLineChange}
                >
                  <option>Vehículo</option>
                  {lines.map((line, lineKey) => (
                    <option key={lineKey}>{line}</option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="selected-box">
                <Form.Select
                  className="form-control sm"
                  value={selectedModel}
                  onChange={handleModelChange}
                >
                  <option>Modelo</option>
                  {models.map((model, modelKey) => (
                    <option key={modelKey}>{model}</option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <div className="col-md-3 search-bx">
              <div role="search">
                <div className="input-group">
                  <input
                    name="text"
                    className="form-control"
                    placeholder="Busca aquí..."
                    type="text"
                    value={search}
                    onChange={handleSearchChange}
                  />
                  <span className="input-group-btn">
                    <button
                      type="submit"
                      className="btn btn-primary sharp radius-no"
                      onClick={handleSearchSubmit}
                    >
                      <i className="la la-search scale3"></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CarListSlideContent
          pageState={pageState}
          setPageState={setPageState}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          cars={filteredCars}
          handleSelectedCar={handleSelectedCar}
          routeImg={routeImg}
        />
      </div>
    </>
  );
};

export default Carlist;
