import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";
// import css files
import "../src/assets/vendor/swiper/swiper-bundle.min.css";
import "../src/assets/vendor/switcher/switcher.css";
import "swiper/css/effect-fade";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "./assets/css/selectoptin.css";
import "../src/assets/css/style.css";
// import components >>
import Header from "./components/Header";
import Header2 from "./components/Header2";
import Footer from "./components/Footer";
// import pages >>>
import Home from "./pages/Home";
import Home2 from "./pages/Home2";
import Home3 from "./pages/Home3";
import Home4 from "./pages/Home4";
import CarListing1 from "./pages/CarListing1";
import CarListing2 from "./pages/CarListing2";
import CarListing3 from "./pages/CarListing3";
import NewCarSearch from "./pages/NewCarSearch";
import SearchCarResult from "./pages/SearchCarResult";
import SearchCarColumn from "./pages/SearchCarColumn";
import LetestCar from "./pages/LetestCar";
import PopulerCar from "./pages/PopulerCar";
import UpcomingCar from "./pages/UpcomingCar";
import CarDealers from "./pages/CarDealers";
import ServiceCenter from "./pages/ServiceCenter";
import OnRoadPrice from "./pages/OnRoadPrice";
import MyCity from "./pages/MyCity";
import UsedSearchCar from "./pages/UsedSearchCar";
import UseCarDeatail from "./pages/UseCarDeatail";
import UsedCarSell from "./pages/UsedCarSell";
import UsedCarValue from "./pages/UsedCarValue";
import ComapeCar from "./pages/ComapeCar";
import CompareResult from "./pages/CompareResult";
import CarReview from "./pages/CarReview";
import CarReviewDetail from "./pages/CarReviewDetail";
import CarDetail_1 from "./pages/CarDetail_1";
import CarDetail_2 from "./pages/CarDetail_2";
import CarSpacification from "./pages/CarSpacification";
import CarPrice from "./pages/CarPrice";
import CarCompare from "./pages/CarCompare";
import CarPicture from "./pages/CarPicture";
import About from "./pages/About";
import Service from "./pages/Service";
import Location from "./pages/Location";
import Contect from "./pages/Contect";
import BlogGrid from "./pages/BlogGrid";
import BlogListSidebar from "./pages/BlogListSidebar";
import BlogDetail from "./pages/BlogDetail";
import Error404 from "./pages/Error404";
import ScrollTop from "./constent/ScrollTop";
import ChatBot from "./constent/ChatBot";

import Cotizador from "./pages/Cotizador";
import Preaprobacion from "./pages/Preaprobacion";
import AutoFinanciamiento from "./pages/AutoFinanciamiento";
import PreaprobacionFinanciamiento from "./pages/PreaprobacionAutoFinanciamiento";
import Planes from "./pages/Planes";
import Pagos from "./pages/Pagos";
import PoliticasPrivacidad from "./pages/PoliticasPrivacidad";
import Vehiculos from "./pages/Vehiculos";
import AdministrarVehiculos from "./pages/AdministrarVehiculos";
import DetallesVehiculos from "./pages/DetallesVehiculo";

import LoginAdminPage from "./pages/LoginAdminPage";

import ImportarAutos from "./pages/ImportarAutos";
import ImportarAutosAutoFinanciamiento from "./pages/ImportarAutosAutoFinanciamiento";

import { BaseUrlProvider } from "./contexts/BaseUrlContext";
import { SessionProvider, useSession } from "./contexts/SessionContext";

function PrivateRoute({ children }) {
  const navigate = useNavigate();
  const { user } = useSession();

  useEffect(() => {
    if (!user) {
      navigate('/iniciarsesion-administrarpagina');
    }
  }, [user, navigate]);

  if (!user) {
    return null;
  }

  return children;
}

function App() {
  return (
    <>
    <BaseUrlProvider>
      <SessionProvider>
        <BrowserRouter basename="/">
          <Routes>
            <Route element={<Layout />}>
              <Route element={<Header />} />
              <Route path="/" element={<Home />} />
              <Route path="/leasing" element={<Cotizador />} />
              <Route path="/preaprobacion" element={<Preaprobacion />} />
              <Route
                path="/autofinanciamiento"
                element={<AutoFinanciamiento />}
              />
              <Route
                path="/preaprobacion-autofinanciamiento"
                element={<PreaprobacionFinanciamiento />}
              />
              <Route path="/planes" element={<Planes />} />
              <Route path="/pagos" element={<Pagos />} />
              <Route path="/contacto" element={<Contect />} />
              <Route
                path="/politicas-privacidad"
                element={<PoliticasPrivacidad />}
              />
              <Route path="/vehiculos" element={<Vehiculos />} />
              <Route
                path="/detalles-vehiculo/:id"
                element={<DetallesVehiculos />}
              />
              <Route
                path="/iniciarsesion-administrarpagina"
                element={<LoginAdminPage />}
              />
              <Route path="/administrar-vehiculos" element={<PrivateRoute><AdministrarVehiculos /></PrivateRoute>} />

              <Route path="/home-2" element={<Home2 />} />
              <Route path="/home-3" element={<Home3 />} />
              <Route path="/car-listing" element={<CarListing1 />} />
              <Route path="/car-listing-2" element={<CarListing2 />} />
              <Route path="/car-listing-3" element={<CarListing3 />} />
              <Route path="/new-car-search" element={<NewCarSearch />} />
              <Route
                path="/new-car-search-result-list"
                element={<SearchCarResult />}
              />
              <Route
                path="/new-car-search-result-column"
                element={<SearchCarColumn />}
              />
              <Route path="/latest-cars" element={<LetestCar />} />
              <Route path="/popular-cars" element={<PopulerCar />} />
              <Route path="/upcoming-cars" element={<UpcomingCar />} />
              <Route path="/car-dealers" element={<CarDealers />} />
              <Route path="/used-car-search-result" element={<MyCity />} />
              <Route path="/car-service-center" element={<ServiceCenter />} />
              <Route path="/on-road-price" element={<OnRoadPrice />} />
              <Route path="/used-car-search" element={<UsedSearchCar />} />
              <Route path="/used-car-details" element={<UseCarDeatail />} />
              <Route path="/used-car-sell" element={<UsedCarSell />} />
              <Route path="/used-car-valuation" element={<UsedCarValue />} />
              <Route path="/compare-car" element={<ComapeCar />} />
              <Route path="/compare-result" element={<CompareResult />} />
              <Route path="/write-review" element={<CarReview />} />
              <Route path="/car-review" element={<CarReviewDetail />} />
              <Route path="/car-details" element={<CarDetail_1 />} />
              <Route path="/car-details-2" element={<CarDetail_2 />} />
              <Route
                path="/car-detail-specifications"
                element={<CarSpacification />}
              />
              <Route path="/car-detail-price" element={<CarPrice />} />
              <Route path="/car-detail-compare" element={<CarCompare />} />
              <Route path="/car-detail-pictures" element={<CarPicture />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/services" element={<Service />} />
              <Route path="/location" element={<Location />} />
              <Route path="/blog-grid" element={<BlogGrid />} />
              <Route path="/blog-list-sidebar" element={<BlogListSidebar />} />
              <Route path="/blog-details" element={<BlogDetail />} />
              <Route path="/importar-autos" element={<PrivateRoute><ImportarAutos /></PrivateRoute>} />
              <Route
                path="/importar-autos-autofinanciamiento"
                element={<PrivateRoute><ImportarAutosAutoFinanciamiento /></PrivateRoute>}
              />

              <Route path="/error-404" element={<Error404 />} />
              <Route element={<Footer />} />
            </Route>
            <Route element={<Layout1 />}>
              <Route path="/home-4" element={<Home4 />} />
            </Route>
          </Routes>
          <ChatBot />
          <ScrollTop />
        </BrowserRouter>
      </SessionProvider>
    </BaseUrlProvider>
    </>
  );
}

const Layout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};
const Layout1 = () => {
  return (
    <>
      <Header2 />
      <Outlet />
      <Footer />
    </>
  );
};

export default App;
