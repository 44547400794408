import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";
import { IMAGE } from "../constent/theme";
import { Dropdown } from "react-bootstrap";
import { Form } from "react-bootstrap";

import { useBaseUrl } from "../contexts/BaseUrlContext";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Input from "@mui/material/Input";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const CarList = [
  { img: IMAGE.productPic1 },
  { img: IMAGE.productPic2 },
  { img: IMAGE.productPic3 },
  { img: IMAGE.productPic4 },
  { img: IMAGE.productPic5 },
  { img: IMAGE.productGridPic1 },
];

export const CarListSlideContent = (props) => {
  const {
    cars,
    handleSelectedCar,
    pageState,
    setPageState,
    pageLimit,
    setPageLimit,
    routeImg,
    getCarList,
  } = props;
  const defaultRoutesImgCar = routeImg;

  const [screenSize, setScreenSize] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [nameToDelete, setNameToDelete] = React.useState("");
  const [idToDelete, setIdToDelete] = React.useState("");
  const [openEdit, setOpenEdit] = React.useState(false);
  const [objectEdit, setObjectEdit] = React.useState("");

  const handleClickOpenDelete = (name, id) => {
    setNameToDelete(name);
    setIdToDelete(id);
    setOpenDelete(true);
  };

  const handleClickOpenEdit = (object) => {
    setObjectEdit(object);
    setOpenEdit(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const newScreenSize = window.innerWidth < 765;
      setScreenSize(newScreenSize);
      setScreenSize(newScreenSize);
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getCurrency2FormatMX = (number) => {
    //get type of variable number
    if (typeof number === "string") {
      number = parseFloat(number);
    }
    return number.toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN", // Puedes cambiar 'MXN' por el código de moneda correspondiente
    });
  };

  useEffect(() => {
    setPageLimit(Math.ceil(cars.length / 12));
    //console.log("limit", Math.ceil(cars.length / 12));
  }, [cars]);

  return (
    <>
      <div className="row lightgallery col-lg-12">
        {cars.map((list, listKey) => {
          if (listKey < pageState * 12 && listKey >= (pageState - 1) * 12) {
            return (
              <div
                className="col-xl-3 col-lg-6 col-md-12 m-b30"
                key={listKey}
                /* onClick={() => handleSelectedCar(list)} */
              >
                <div className="car-list-box">
                  <div className="media-box">
                    <Box
                      sx={{
                        "& > :not(style)": { m: 1 },
                        position: "absolute", // Posiciona el elemento de manera absoluta
                        top: 0, // Mueve el elemento a la parte superior del contenedor
                        right: 0, // Mueve el elemento a la derecha del contenedor
                      }}
                    >
                      <Fab
                        style={{ backgroundColor: "#000", color: "#fff" }}
                        aria-label="edit"
                        onClick={() => handleClickOpenEdit(list)}
                      >
                        <EditIcon />
                      </Fab>
                      <Fab
                        style={{ backgroundColor: "#000", color: "#fff" }}
                        aria-label="add"
                        onClick={() =>
                          handleClickOpenDelete(
                            list.marca +
                              " " +
                              list.linea +
                              " " +
                              list.modelo +
                              " " +
                              list.version,
                            list.id
                          )
                        }
                      >
                        <DeleteIcon />
                      </Fab>
                    </Box>
                    <img
                      src={
                        defaultRoutesImgCar +
                        list.fotografiaPrincipal +
                        "/1.png"
                      }
                      alt=""
                      style={{ width: "436px", height: "318px" }}
                    />
                  </div>
                  <div className="list-info">
                    <h4 className="title mb-0">
                      {/* <Link to={"/car-details"} data-splitting> */}
                      <Link data-splitting>
                        {list.marca + " " + list.linea + " " + list.modelo}
                      </Link>
                    </h4>
                    <div className="car-type">{list.version}</div>
                    <span className="badge m-b30">
                      Desde {getCurrency2FormatMX(list.precioLista)}
                    </span>
                    <div className="feature-list">
                      {!screenSize && (
                        <div>
                          <label>Transmisión</label>
                          <p className="value">{list.transmision}</p>
                        </div>
                      )}
                      <div>
                        <label>Combustible</label>
                        <p className="value">{list.combustible}</p>
                      </div>
                      {list.kilometraje && (
                        <div>
                          <label>Kilometraje</label>
                          {routeImg === "https://gminmediauto.com.mx/imgAutosAF/" ? (
                            <p className="value">{list.kilometraje}</p>
                          ) : (
                            <p className="value">{list.kilometraje} km</p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
      <Pagination
        totalPage={pageLimit}
        setCurrentPage={setPageState}
        currentPage={pageState}
      />
      <AlertDialogDelete
        open={openDelete}
        handleClose={handleCloseDelete}
        nameToDelete={nameToDelete}
        idToDelete={idToDelete}
        getCarList={getCarList}
      />
      <AlertDialogEdit
        open={openEdit}
        handleClose={handleCloseEdit}
        objectEdit={objectEdit}
        getCarList={getCarList}
      />
    </>
  );
};

export const AlertDialogDelete = (props) => {
  const { open, handleClose, nameToDelete, idToDelete, getCarList } = props;
  const { baseUrl } = useBaseUrl();

  const handleDelete = async () => {
    try {
      const response = await axios.post(
        baseUrl + "restautoaf/delete/" +
          idToDelete
      );
      //console.log(response.data);
      alert("Vehículo eliminado");
      window.location.reload();
    }
    catch (error) {
      console.error(error);
    }
    handleClose();
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Eliminar {nameToDelete}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estás seguro de que deseas eliminar el vehículo llamado:{" "}
            {nameToDelete}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="outlined"
            onClick={handleDelete}
            color="error"
            autoFocus
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export const AlertDialogAdd = (props) => {
  const { open, handleClose, getCarList } = props;
  const { baseUrl } = useBaseUrl();

  // Dentro de tu componente
  const [fileName, setFileName] = useState("");
  const [fileNames, setFileNames] = React.useState("");

  const handleFileChange = (event) => {
    let fileName =
      event.target.files[0].name.length > 35
        ? event.target.files[0].name.substring(0, 32) + "..."
        : event.target.files[0].name;
    setFileName(fileName);
  };

  const handleFilesChange = (event) => {
    const files = event.target.files;
    let fileNames = "";
    for (let i = 0; i < files.length; i++) {
      fileNames += (i !== 0 ? ", " : "") + files[i].name;
    }
    // Limitar la longitud a 150 caracteres y agregar puntos suspensivos si es necesario
    fileNames =
      fileNames.length > 35 ? fileNames.substring(0, 32) + "..." : fileNames;
    setFileNames(fileNames);
  };

  const handleClickSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    try {
      const response = await axios.post(
        baseUrl + "restautoaf/create",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //console.log(response.data);
      alert("Vehículo agregado");
      window.location.reload();
    } catch (error) {
      console.error(error);
    }

    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => handleClickSubmit(event),
        }}
      >
        <DialogTitle>Agregar Vehículo</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="marca"
            name="marca"
            label="Marca"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="linea"
            name="linea"
            label="Línea"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="version"
            name="version"
            label="Versión"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="modelo"
            name="modelo"
            label="Modelo"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="transmision"
            name="transmision"
            label="Transmisión"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="carroceria"
            name="carroceria"
            label="Carrocería"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="combustible"
            name="combustible"
            label="Combustible"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="kilometraje"
            name="kilometraje"
            label="Kilometraje"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="precioLista"
            name="precioLista"
            label="Precio de lista"
            type="number"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="descripcionAdicional"
            name="descripcionAdicional"
            label="Descripción adicional"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="mensualidad"
            name="mensualidad"
            label="Mensualidad"
            type="number"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="meses"
            name="meses"
            label="Meses"
            type="number"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="pagoInicial"
            name="pagoInicial"
            label="Pago inicial"
            type="number"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="costoTotal"
            name="costoTotal"
            label="Costo total"
            type="number"
            fullWidth
            variant="standard"
          />
          <input
            autoFocus
            required
            type="file"
            id="fotografiaPrincipal"
            name="fotografiaPrincipal"
            accept=".png" // Aceptar sólo archivos .png
            style={{ display: "none" }} // Ocultar el input por defecto
            onChange={handleFileChange} // Manejar el cambio de archivo
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "0",
              marginTop: "5px",
            }}
          >
            <label>Imagen Principal: </label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0",
              }}
            >
              <label htmlFor="fotografiaPrincipal">
                <Button variant="contained" component="span" color="error">
                  Subir imagen
                </Button>
              </label>
              <p
                style={{
                  marginLeft: "5px",
                  alignSelf: "bottom",
                  marginBottom: "0PX",
                }}
              >
                {fileName}
              </p>
            </div>
          </div>
          <input
            autoFocus
            required
            type="file"
            id="fotografias"
            name="fotografias[]"
            accept=".png" // Aceptar sólo archivos .png
            multiple // Permitir la selección de múltiples archivos
            style={{ display: "none" }} // Ocultar el input por defecto
            onChange={handleFilesChange} // Manejar el cambio de archivo
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "0",
              marginTop: "5px",
            }}
          >
            <label>Imágenes: </label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0",
              }}
            >
              <label htmlFor="fotografias">
                <Button variant="contained" component="span" color="error">
                  Subir imágenes
                </Button>
              </label>
              <p
                style={{
                  marginLeft: "5px",
                  alignSelf: "bottom",
                  marginBottom: "0PX",
                }}
              >
                {fileNames}
              </p>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" color="error" autoFocus type="submit">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export const AlertDialogEdit = (props) => {
  const { open, handleClose, objectEdit, getCarList } = props;
  const { baseUrl } = useBaseUrl();

  // Dentro de tu componente
  const [fileName, setFileName] = useState("");
  const [fileNames, setFileNames] = React.useState("");

  const yesNot = [
    {
      value: "No",
      label: "No",
    },
    {
      value: "Si",
      label: "Si",
    },
  ];

  const [changeMainPhoto, setChangeMainPhoto] = React.useState("No");
  const [changePhotos, setChangePhotos] = React.useState("No");

  const handleFileChange = (event) => {
    let fileName =
      event.target.files[0].name.length > 35
        ? event.target.files[0].name.substring(0, 32) + "..."
        : event.target.files[0].name;
    setFileName(fileName);
  };

  const handleFilesChange = (event) => {
    const files = event.target.files;
    let fileNames = "";
    for (let i = 0; i < files.length; i++) {
      fileNames += (i !== 0 ? ", " : "") + files[i].name;
    }
    // Limitar la longitud a 150 caracteres y agregar puntos suspensivos si es necesario
    fileNames =
      fileNames.length > 35 ? fileNames.substring(0, 32) + "..." : fileNames;
    setFileNames(fileNames);
  };

  const handleClickSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    try {
      const response = await axios.post(
        baseUrl + "restautoaf/update/" +
          objectEdit.id,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //console.log(response.data);
      alert("Vehículo actualizado");
      window.location.reload();

    } catch (error) {
      console.error(error);
    }

    handleClose();
  };

  const [updateComplete, setUpdateComplete] = useState(false);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => handleClickSubmit(event),
        }}
      >
        <DialogTitle>Editar Vehículo</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="marca"
            name="marca"
            label="Marca"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={objectEdit.marca}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="linea"
            name="linea"
            label="Línea"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={objectEdit.linea}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="version"
            name="version"
            label="Versión"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={objectEdit.version}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="modelo"
            name="modelo"
            label="Modelo"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={objectEdit.modelo}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="transmision"
            name="transmision"
            label="Transmisión"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={objectEdit.transmision}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="carroceria"
            name="carroceria"
            label="Carrocería"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={objectEdit.carroceria}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="combustible"
            name="combustible"
            label="Combustible"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={objectEdit.combustible}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="kilometraje"
            name="kilometraje"
            label="Kilometraje"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={objectEdit.kilometraje}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="precioLista"
            name="precioLista"
            label="Precio de lista"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={objectEdit.precioLista}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="descripcionAdicional"
            name="descripcionAdicional"
            label="Descripción adicional"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={objectEdit.descripcionAdicional}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="mensualidad"
            name="mensualidad"
            label="Mensualidad"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={objectEdit.mensualidad}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="meses"
            name="meses"
            label="Meses"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={objectEdit.meses}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="pagoInicial"
            name="pagoInicial"
            label="Pago inicial"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={objectEdit.pagoInicial}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="costoTotal"
            name="costoTotal"
            label="Costo total"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={objectEdit.costoTotal}
          />
          <TextField
            style={{ marginTop: "10px" }}
            id="updateFotografiaPrincipal"
            name="updateFotografiaPrincipal"
            select
            label="¿Quieres cambiar la imagen principal?"
            value={changeMainPhoto}
            onChange={(event) => setChangeMainPhoto(event.target.value)}
            variant="standard"
            fullWidth
          >
            {yesNot.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <input
            type="file"
            id="fotografiaPrincipal"
            name="fotografiaPrincipal"
            accept=".png" // Aceptar sólo archivos .png
            style={{ display: "none" }} // Ocultar el input por defecto
            onChange={handleFileChange} // Manejar el cambio de archivo
          />
          {changeMainPhoto === "Si" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0",
                marginTop: "5px",
              }}
            >
              <label>Imagen Principal: </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0",
                }}
              >
                <label htmlFor="fotografiaPrincipal">
                  <Button variant="contained" component="span" color="error">
                    Subir imagen
                  </Button>
                </label>
                <p
                  style={{
                    marginLeft: "5px",
                    alignSelf: "bottom",
                    marginBottom: "0PX",
                  }}
                >
                  {fileName}
                </p>
              </div>
            </div>
          )}
          <TextField
            style={{ marginTop: "10px" }}
            id="updateFotografias"
            name="updateFotografias"
            select
            label="¿Quieres cambiar las imagenes secundarias?"
            value={changePhotos}
            onChange={(event) => setChangePhotos(event.target.value)}
            variant="standard"
            fullWidth
          >
            {yesNot.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <input
            type="file"
            id="fotografias"
            name="fotografias[]"
            accept=".png" // Aceptar sólo archivos .png
            multiple // Permitir la selección de múltiples archivos
            style={{ display: "none" }} // Ocultar el input por defecto
            onChange={handleFilesChange} // Manejar el cambio de archivo
          />
          {changePhotos === "Si" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0",
                marginTop: "5px",
              }}
            >
              <label>Imágenes: </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0",
                }}
              >
                <label htmlFor="fotografias">
                  <Button variant="contained" component="span" color="error">
                    Subir imágenes
                  </Button>
                </label>
                <p
                  style={{
                    marginLeft: "5px",
                    alignSelf: "bottom",
                    marginBottom: "0PX",
                  }}
                >
                  {fileNames}
                </p>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" color="error" autoFocus type="submit">
            Editar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const Carlist = (props) => {
  const {
    cars,
    handleSelectedCar,
    routeImg,
    initialBrand,
    initialLine,
    initialModel,
    getCarList,
  } = props;
  const [pageState, setPageState] = useState(1);
  const [pageLimit, setPageLimit] = useState(1);
  const navigate = useNavigate();

  const [filteredCars, setFilteredCars] = useState(cars);

  //first filter
  const [brands, setBrands] = useState([]);
  const [lines, setLines] = useState([]);
  const [models, setModels] = useState([]);

  const [selectedBrand, setSelectedBrand] = useState("Marca");
  const [selectedLine, setSelectedLine] = useState("Vehículo");
  const [selectedModel, setSelectedModel] = useState("Modelo");

  //second filter
  const [search, setSearch] = useState("");

  const [selectBtn, setSelectBtn] = useState("Newest");
  const [bgColor, setColor] = useState(2);
  const [initialized, setInitialized] = useState(false);

  const [openAdd, setOpenAdd] = useState(false);

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  //methods for first filter
  const getUniqueBrands = () => {
    const uniqueBrands = [...new Set(cars.map((car) => car.marca))];
    return uniqueBrands;
  };

  const getUniqueLinesByBrand = (brand) => {
    let uniqueLines = [];
    if (brand === "Marca") {
      uniqueLines = [...new Set(cars.map((car) => car.linea))];
    } else {
      uniqueLines = [
        ...new Set(
          cars.filter((car) => car.marca === brand).map((car) => car.linea)
        ),
      ];
    }

    return uniqueLines;
  };

  const getUniqueModelsByLineAndBrand = (line, brand) => {
    let uniqueModels = [];
    if (line === "Vehículo" && brand === "Marca") {
      uniqueModels = [...new Set(cars.map((car) => car.modelo))];
    } else if (line === "Vehículo") {
      uniqueModels = [
        ...new Set(
          cars.filter((car) => car.marca === brand).map((car) => car.modelo)
        ),
      ];
    } else if (brand === "Marca") {
      uniqueModels = [
        ...new Set(
          cars.filter((car) => car.linea === line).map((car) => car.modelo)
        ),
      ];
    } else {
      uniqueModels = [
        ...new Set(
          cars
            .filter((car) => car.marca === brand && car.linea === line)
            .map((car) => car.modelo)
        ),
      ];
    }

    return uniqueModels;
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
    setSelectedLine("Vehículo");
    setSelectedModel("Modelo");
    setLines(getUniqueLinesByBrand(event.target.value));
    setModels(getUniqueModelsByLineAndBrand("Vehículo", event.target.value));

    //enviar filtro
    setFilteredCars(
      getFirstFilteredCars(event.target.value, "Vehículo", "Modelo")
    );

    setPageState(1);
  };

  const handleLineChange = (event) => {
    setSelectedLine(event.target.value);
    setSelectedModel("Modelo");
    setModels(getUniqueModelsByLineAndBrand(event.target.value, selectedBrand));

    //enviar filtro
    setFilteredCars(
      getFirstFilteredCars(selectedBrand, event.target.value, "Modelo")
    );

    setPageState(1);
  };

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);

    //enviar filtro
    setFilteredCars(
      getFirstFilteredCars(selectedBrand, selectedLine, event.target.value)
    );

    setPageState(1);
  };

  const getFirstFilteredCars = (brand, line, model) => {
    if (brand === "Marca" && line === "Vehículo" && model === "Modelo") {
      return cars;
    } else if (brand === "Marca" && line === "Vehículo") {
      return cars.filter((car) => car.modelo === model);
    } else if (brand === "Marca" && model === "Modelo") {
      return cars.filter((car) => car.linea === line);
    } else if (line === "Vehículo" && model === "Modelo") {
      return cars.filter((car) => car.marca === brand);
    } else if (brand === "Marca") {
      return cars.filter((car) => car.linea === line && car.modelo === model);
    } else if (line === "Vehículo") {
      return cars.filter((car) => car.marca === brand && car.modelo === model);
    } else if (model === "Modelo") {
      return cars.filter((car) => car.marca === brand && car.linea === line);
    } else {
      return cars.filter(
        (car) =>
          car.marca === brand && car.linea === line && car.modelo === model
      );
    }
  };

  //methods for second filter
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSearchSubmit = () => {
    setSelectedBrand("Marca");
    setSelectedLine("Vehículo");
    setSelectedModel("Modelo");

    setLines(getUniqueLinesByBrand("Marca"));
    setModels(getUniqueModelsByLineAndBrand("Vehículo", "Marca"));

    //enviar filtro
    setFilteredCars(getSecondFilteredCars(search));

    setPageState(1);
  };

  const getSecondFilteredCars = (search) => {
    if (search === "") {
      return cars;
    } else {
      return cars.filter(
        (car) =>
          car.marca.includes(search) ||
          car.linea.includes(search) ||
          car.modelo.includes(search) ||
          car.version.includes(search) ||
          (car.marca + " " + car.linea).includes(search) ||
          (car.linea + " " + car.version).includes(search) ||
          (car.linea + " " + car.version + " " + car.modelo).includes(search) ||
          (
            car.marca +
            " " +
            car.linea +
            " " +
            car.version +
            " " +
            car.modelo
          ).includes(search) ||
          (car.marca + " " + car.version).includes(search) ||
          (car.marca + " " + car.version + " " + car.modelo).includes(search)
      );
    }
  };

  const setInitialValues = () => {
    const valueInitialBrand = initialBrand !== null ? initialBrand : "Marca";
    const valueInitialLine = initialLine !== null ? initialLine : "Vehículo";
    const valueInitialModel = initialModel !== null ? initialModel : "Modelo";

    setSelectedBrand(valueInitialBrand);
    setSelectedLine(valueInitialLine);
    setSelectedModel(valueInitialModel);

    /* console.log(
      "initial values",
      valueInitialBrand,
      valueInitialLine,
      valueInitialModel
    ); */

    setFilteredCars(
      getFirstFilteredCars(
        valueInitialBrand,
        valueInitialLine,
        valueInitialModel
      )
    );
  };

  useEffect(() => {
    setInitialValues();
    setBrands(getUniqueBrands());
    setLines(getUniqueLinesByBrand(selectedBrand));
    setModels(getUniqueModelsByLineAndBrand(selectedLine, selectedBrand));
    setInitialized(true);
  }, []);

  const setColorBtn = (action) => {
    setColor(action);
  };

  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="col-xl-12 col-lg-12" style={{ padding: "32px" }}>
        <div className="catagory-result-row">
          {/*<h5 className="serch-result">
              Showing <strong>8 product from 40</strong>
              </h5>*/}
          <div className="Form-group row col-md-12">
            <div className="col-md-3">
              <div className="selected-box">
                <Form.Select
                  className="form-control sm"
                  value={selectedBrand}
                  onChange={handleBrandChange}
                >
                  <option>Marca</option>
                  {brands.map((brand, brandKey) => (
                    <option key={brandKey}>{brand}</option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="selected-box">
                <Form.Select
                  className="form-control sm"
                  value={selectedLine}
                  onChange={handleLineChange}
                >
                  <option>Vehículo</option>
                  {lines.map((line, lineKey) => (
                    <option key={lineKey}>{line}</option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="selected-box">
                <Form.Select
                  className="form-control sm"
                  value={selectedModel}
                  onChange={handleModelChange}
                >
                  <option>Modelo</option>
                  {models.map((model, modelKey) => (
                    <option key={modelKey}>{model}</option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <div className="col-md-3 search-bx">
              <div role="search">
                <div className="input-group">
                  <input
                    name="text"
                    className="form-control"
                    placeholder="Busca aquí..."
                    type="text"
                    value={search}
                    onChange={handleSearchChange}
                  />
                  <span className="input-group-btn">
                    <button
                      type="submit"
                      className="btn btn-primary sharp radius-no"
                      onClick={handleSearchSubmit}
                    >
                      <i className="la la-search scale3"></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12" style={{ paddingRight: "1.8rem" }}>
          <button
            name="submit"
            type="submit"
            value="submit"
            className="btn btn-primary w-100 d-block btn-rounded resumen-btn"
            onClick={handleClickOpenAdd}
          >
            <div className="row">
              <div className="col-11">
                Agregar un nuevo vehículo {"  "}
                <AddCircleIcon />
              </div>
            </div>
          </button>
        </div>
        <br />

        <CarListSlideContent
          pageState={pageState}
          setPageState={setPageState}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          cars={filteredCars}
          handleSelectedCar={handleSelectedCar}
          routeImg={routeImg}
          getCarList={getCarList}
        />
      </div>
      <AlertDialogAdd open={openAdd} handleClose={handleCloseAdd} getCarList={getCarList} />
    </>
  );
};

export default Carlist;
