import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useBaseUrl } from "../contexts/BaseUrlContext";

const ContectForm = () => {
  const { baseUrl } = useBaseUrl();
  const formRef = useRef();

  const handleClickSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    try {
      const response = await axios.post(baseUrl + "contacto", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        //console.log(response.data.data);
        alert("Mensaje enviado correctamente");
        formRef.current.reset(); // Limpia el formulario
      } else {
        console.log("Error sending message");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="col-xl-6 m-b40">
        <div className="contact-area1">
          <form
            ref={formRef}
            className="dlab-form style-1 dzForm"
            method="POST"
            onSubmit={(event) => handleClickSubmit(event)}
          >
            <div className="row sp10">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Nombre completo*</label>
                  <div className="input-group">
                    <input
                      type="text"
                      required
                      className="form-control"
                      name="name"
                      placeholder=""
                      id="name"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Correo electrónico*</label>
                  <div className="input-group">
                    <input
                      type="email"
                      required
                      className="form-control"
                      name="email"
                      placeholder=""
                      id="email"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Teléfono (Opcional)</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      placeholder=""
                      id="phone"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Asunto*</label>
                  <div className="input-group">
                    <input
                      type="text"
                      required
                      className="form-control"
                      name="subject"
                      placeholder=""
                      id="subject"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Mensaje*</label>
                  <div className="input-group">
                    <textarea
                      name="message"
                      required
                      className="form-control"
                      placeholder=""
                      id="message"
                    ></textarea>
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-12">
                <div className="form-group">
                  <div
                    className="g-recaptcha"
                    data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                    data-callback="verifyRecaptchaCallback"
                    data-expired-callback="expiredRecaptchaCallback"
                  ></div>
                  <input
                    className="form-control d-none"
                    style={{ display: "none" }}
                    data-recaptcha="true"
                    required
                    data-error="Please complete the Captcha"
                  />
                </div>
              </div> */}
              <div className="col-sm-12">
                <button
                  name="submit"
                  type="submit"
                  value="submit"
                  className="btn btn-primary w-100 d-block btn-rounded"
                >
                  Envíar mensaje
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContectForm;
