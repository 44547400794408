import React, { useState, useEffect } from "react";
import axios from "axios";
import { IMAGE } from "../constent/theme";
import Pasos from "../components/Pasos";
import DetalleAutoCotizador from "../components/DetalleAutoCotizador";
import { Form } from "react-bootstrap";
import { Link, useLocation  } from "react-router-dom";
import { CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, CheckBox as CheckBoxIcon } from '@mui/icons-material';

import { useBaseUrl } from "../contexts/BaseUrlContext";

import { G, pdf, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PreaprobacionPDF from "../components/preaprobacionPDF";
import CotizacionPDF from "../components/cotizacionPDF";



const CarList2 = [
  { img: IMAGE.productPic1 },
  { img: IMAGE.productPic2 },
  { img: IMAGE.productPic3 },
  { img: IMAGE.productPic4 },
  { img: IMAGE.productPic5 },
  { img: IMAGE.productGridPic1 },
];

const Preaprobacion = () => {
  const location = useLocation();
  const selectedData = location.state.data;
  const steps = [
    "Persona",
    "Empleo",
    "Referencias",
    "Domiciliacion",
    "Aval",
    "Completado"
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  //pasos completados
  const [completedSteps, setCompletedSteps] = useState([0]);
  //todos los pasos completados
  const [completed, setCompleted] = React.useState({});
  const [textTitleOn, setTextTitleOn] = useState(false);

  const title = "Pre-aprobación";

  const [selectBtn, setSelectBtn] = useState("Newest");
  const [bgColor, setColorBtn] = useState(2);

  /**TODO: Datos a actualizar con el diseño */
  const [data, setData] = useState({
    personal: {
      general: {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        fechaNacimiento: "",
        genero: "",
        rfc: "",
        curp: "",
        paisNacimiento: "",
        nacionalidad: "",
        tipoIdentificacion: "",
        estadoCivil: "",
        regimen: "",
        numeroDependientesEconomicos: "",
        correoElectronico: "",
        nivelEducativo: "",
        telefonoCelular: "",
      },
      direccion: {
        calle: "",
        numeroExterior: "",
        numeroInterior: "",
        colonia: "",
        municipio: "",
        ciudad: "",
        estado: "",
        cp: "",
        tiempoViviendo: "",
        tipoDeDomicilio: "",
        telefonoCelular: "",
      },
    },
    empleo: {
      empleo: {
        actividadEconomica: "",
        empresa: "",
        giroDeLaEmpresa: "",
        tipoEmpleo: "",
        jefeInmediato: "",
        tiempoEnElEmpleo: "",
        NumeroSeguroSocial: "",
      },

      direccionDeLaEmpresa: {
        calle: "",
        numeroExterior: "",
        numeroInterior: "",
        colonia: "",
        municipio: "",
        ciudad: "",
        estado: "",
        cp: "",
        tiempoEnElEmpleo: "",
        telefonoDelTrabajo: "",
        extension: "",
      },

      ingresosMensuales: {
        reciboNominaNeto: "",
        serviciosProfesionalesOEmpresariales: "",
        otros: "",
      },
    },
    referencias: {
      datosConyuge: {
        tienesConyuge: "",
        nombres: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        fechaNacimiento: "",
        telefonoCelular: "",
        ocupacion: "",
        telefonoDelTrabajo: "",
        extension: "",
        viveEnTuMismaDireccion: "",
        calle: "",
        numeroExterior: "",
        numeroInterior: "",
        colonia: "",
        municipio: "",
        ciudad: "",
        estado: "",
        cp: "",
      },
      referenciaFamiliar: {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        fechaNacimiento: "",
        parentesco: "",
        telefono: "",
        calle: "",
        numeroExterior: "",
        numeroInterior: "",
        colonia: "",
        municipio: "",
        ciudad: "",
        estado: "",
        cp: "",
      },
      referenciaFamiliarSegundo: {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        parentesco: "",
      },
      referenciaPersonal: {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        telefono: "",
        relacionConUsted: "",
      },
      referenciaPersonalSegundo: {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        telefono: "",
        relacionConUsted: "",
      },
    },
    domiciliacion: {
      bancoInstitucion: "",
      ClabeInterbancaria: "",
    },
    aval: {
      datosAval: {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        fechaNacimiento: "",
        genero: "",
        rfc: "",
        curp: "",
        paisNacimiento: "",
        nacionalidad: "",
        tipoIdentificacion: "",
        estadoCivil: "",
        regimen: "",
        numeroDependientesEconomicos: "",
        correoElectronico: "",
        nivelEducativo: "",
        telefonoCelular: "",
      },
      direccionDelObligadoOAval: {
        calle: "",
        numeroExterior: "",
        numeroInterior: "",
        colonia: "",
        municipio: "",
        ciudad: "",
        estado: "",
        cp: "",
        tiempoViviendo: "",
        tipoDeDomicilio: "",
        telefonoCasa: "",
      },
    },
  });

  
  return (
    <>
      <div className="page-content bg-white">
        <Pasos
          steps={steps}
          title={title}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          completed={completed}
          setCompleted={setCompleted}
          completedSteps={completedSteps}
          setCompletedSteps={setCompletedSteps}
        />
        <section className="content-preaprobacion">
          <div className="row justify-content-center">
            <div className="col-lg-6">
            <DetalleAutoCotizador textTitleOn={textTitleOn} data={selectedData}/>
            </div>
          </div>
          
          {activeStep === 0 && (
            <Paso1
              data={data}
              setData={setData}
              setActiveStep={setActiveStep}
            />
          )}
          {activeStep === 1 && (
            <Paso2
              data={data}
              setData={setData}
              setActiveStep={setActiveStep}
            />
          )}
          {activeStep === 2 && (
            <Paso3
              data={data}
              setData={setData}
              setActiveStep={setActiveStep}
            />
          )}
          {activeStep === 3 && (
            <Paso4
              data={data}
              setData={setData}
              setActiveStep={setActiveStep}
            />
          )}
          {activeStep === 4 && (
            <Paso5
              data={data}
              setData={setData}
              setActiveStep={setActiveStep}
              setTextTitleOn={setTextTitleOn}
              selectedData={selectedData}
            />
          )}
          {activeStep === 5 && (
            <Paso6
              data={data}
              setData={setData}
              setActiveStep={setActiveStep}
            />
          )}
        </section>
      </div>
    </>
  );
};

const Paso1 = (props) => {
  const { data, setData, setActiveStep } = props;

  const handleNameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          nombre: e.target.value,
        },
      },
    }));
  };

  const handleSurnameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          apellidoPaterno: e.target.value,
        },
      },
    }));
  };

  const handleSecondSurnameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          apellidoMaterno: e.target.value,
        },
      },
    }));
  };

  const handleBirthdateChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          fechaNacimiento: e.target.value,
        },
      },
    }));
  };

  const handleGenderChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          genero: e.target.value,
        },
      },
    }));
  };

  const handleRfcChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          rfc: e.target.value,
        },
      },
    }));
  };

  const handleCurpChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          curp: e.target.value,
        },
      },
    }));
  };

  const handleCountryBirthChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          paisNacimiento: e.target.value,
        },
      },
    }));
  };

  const handleNationalityChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          nacionalidad: e.target.value,
        },
      },
    }));
  };

  const handleIDTypeChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          tipoIdentificacion: e.target.value,
        },
      },
    }));
  };

  const handleCiviStatusChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          estadoCivil: e.target.value,
        },
      },
    }));
  };

  const handleRegimeChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          regimen: e.target.value,
        },
      },
    }));
  };

  const handleNumberEconomicDependentsChange = (e) => {
    /*Validar el campo de nss que solo reciba números */
    // Expresión regular para validar que solo se ingresen números
    const inputValue = e.target.value;
    // Expresión regular para validar que solo se ingresen exactamente 11 dígitos
    const numericRegex = /^[0-9]*$/;

    if (numericRegex.test(inputValue) || inputValue === "") {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          numeroDependientesEconomicos: e.target.value,
        },
      },
    }));
  };
  };

  const handleEmailChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          correoElectronico: e.target.value,
        },
      },
    }));
  };

  const handleEducationLevelChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          nivelEducativo: e.target.value,
        },
      },
    }));
  };

  const handleCellPhoneChange = (e) => {
    
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        general: {
          ...prevState.personal.general,
          telefonoCelular: e.target.value,
        },
      },
    }));
  };
 

  const handleStreetChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          calle: e.target.value,
        },
      },
    }));
  };

  const handleOutdoorNumberChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          numeroExterior: e.target.value,
        },
      },
    }));
  };

  const handleInteriorNumberChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          numeroInterior: e.target.value,
        },
      },
    }));
  };

  const handleCologneChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          colonia: e.target.value,
        },
      },
    }));
  };

  const handleMunicipalityChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          municipio: e.target.value,
        },
      },
    }));
  };

  const handleCityChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          ciudad: e.target.value,
        },
      },
    }));
  };

  const handleStateChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          estado: e.target.value,
        },
      },
    }));
  };

  const handlePostalCodeChange = (e) => {

    /*Validar el campo de nss que solo reciba números */
    // Expresión regular para validar que solo se ingresen números
    const inputValue = e.target.value;
    // Expresión regular para validar que solo se ingresen exactamente 11 dígitos
    const numericRegex = /^[0-9]*$/;

    if (numericRegex.test(inputValue) || inputValue === "") {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          cp: e.target.value,
        },
      },
    }));
  };
 };

  const handleTimeLivingChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          tiempoViviendo: e.target.value,
        },
      },
    }));
  };

  const handleTypeOfAddressChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          tipoDeDomicilio: e.target.value,
        },
      },
    }));
  };

  const handleHomePhoneChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      personal: {
        ...prevState.personal,
        direccion: {
          ...prevState.personal.direccion,
          telefonoCasa: e.target.value,
        },
      },
    }));
  };


  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
  };

  /*////////Validación del formulario//////// */
  const handleNext = (e) => {
    e.preventDefault();
    // Verificar si los campos obligatorios están llenos
    if (
      data.personal.general.nombre &&
      data.personal.general.apellidoPaterno &&
      data.personal.general.apellidoMaterno &&
      data.personal.general.fechaNacimiento &&
      data.personal.general.genero &&
      data.personal.general.rfc &&
      data.personal.general.curp &&
      data.personal.general.paisNacimiento &&
      data.personal.general.nacionalidad &&
      data.personal.general.tipoIdentificacion &&
      data.personal.general.estadoCivil &&
      data.personal.general.regimen &&
      data.personal.general.numeroDependientesEconomicos &&
      data.personal.general.correoElectronico &&
      data.personal.general.nivelEducativo &&
      data.personal.general.telefonoCelular &&
      data.personal.direccion.calle &&
      data.personal.direccion.numeroExterior &&
      data.personal.direccion.colonia &&
      data.personal.direccion.municipio &&
      data.personal.direccion.ciudad &&
      data.personal.direccion.estado &&
      data.personal.direccion.cp &&
      data.personal.direccion.tiempoViviendo &&
      data.personal.direccion.tipoDeDomicilio &&
      data.personal.direccion.telefonoCasa
    ) {
      // Avanzar al siguiente paso
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      // Mostrar mensaje de error o realizar alguna otra acción
      
    }

    /* if (isChecked) {
      console.log('Avanzando al siguiente paso...');
      // Aquí puedes agregar cualquier otra lógica que desees ejecutar cuando el checkbox esté marcado
  } else {
      console.log('Acepta la política de privacidad.');
      // Puedes mostrar un mensaje al usuario indicando que debe marcar el checkbox antes de continuar
      
  } */
  };
  /* /////////**************/ /////////// */

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 m-b40">
            <div className="contact-area-preaprobacion">
              <form
                className="dlab-form style-1 dzForm">
                <input
                  type="hidden"
                  className="form-control"
                  name="dzToDo"
                  value="Contact"
                />
                <input
                  type="hidden"
                  className="form-control"
                  name="reCaptchaEnable"
                  value="0"
                />
                <div className="dzFormMsg"></div>
                <div className="row sp10">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="font-weight-800">General:</label>
                    </div>
                  </div>
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Nombre(s)*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleNameChange}
                          value={data.personal.general.nombre}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzName"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Paterno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleSurnameChange}
                          value={data.personal.general.apellidoPaterno}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Surname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Materno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleSecondSurnameChange}
                          value={data.personal.general.apellidoMaterno}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[SecondSurname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Fecha de nacimiento*
                      </label>
                      <div className="input-group">
                        <input
                          type="date"
                          onChange={handleBirthdateChange}
                          value={data.personal.general.fechaNacimiento}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Birthdate]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Género*</label>
                      <div className="col-lg-12 border-bottom ">
                        <div className="selected-box">
                          <Form.Select
                            className="form-control sm-preaprobacion "
                            onChange={handleGenderChange}
                            value={data.personal.general.genero}>
                            <option></option>
                            <option>Masculino</option>
                            <option>Femenino</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">RFC*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleRfcChange}
                          value={data.personal.general.rfc}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Rfc]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">CURP*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleCurpChange}
                          value={data.personal.general.curp}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Curp]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        País de nacimiento*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleCountryBirthChange}
                          value={data.personal.general.paisNacimiento}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[CountryBirth]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Nacionalidad*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleNationalityChange}
                          value={data.personal.general.nacionalidad}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Nationality]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Tipo de identificación*
                      </label>
                      <div className="col-lg-12 border-bottom">
                        <div className="selected-box ">
                          <Form.Select
                            className="form-control sm-preaprobacion"
                            onChange={handleIDTypeChange}
                            value={data.personal.general.tipoIdentificacion}>
                            <option></option>
                            <option>INE</option>
                            <option>Pasaporte</option>
                            <option>FM2</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Estado civil*</label>
                      <div className="col-lg-12 border-bottom">
                        <div className="selected-box">
                          <Form.Select
                            className="form-control sm-preaprobacion"
                            onChange={handleCiviStatusChange}
                            value={data.personal.general.estadoCivil}>
                            <option></option>
                            <option>Soltero(a)</option>
                            <option>Casado(a)</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Régimen*</label>
                      <div className="col-lg-12 border-bottom">
                        <div className="selected-box">
                          <Form.Select
                            className="form-control sm-preaprobacion"
                            onChange={handleRegimeChange}
                            value={data.personal.general.regimen}>
                            <option></option>
                            <option>Bienes separados</option>
                            <option>Bienes mancomunados</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Número de dependientes económicos*
                      </label>
                      <div className="input-group">
                        <input
                          type="Text"
                          onChange={handleNumberEconomicDependentsChange}
                          value={
                            data.personal.general.numeroDependientesEconomicos
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[NumberEconomicDependents]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Correo electrónico*
                      </label>
                      <div className="input-group">
                        <input
                          type="email"
                          onChange={handleEmailChange}
                          value={data.personal.general.correoElectronico}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Email]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Nivel educativo*
                      </label>
                      <div className="col-lg-12 border-bottom">
                        <div className="selected-box">
                          <Form.Select
                            className="form-control sm-preaprobacion"
                            onChange={handleEducationLevelChange}
                            value={data.personal.general.nivelEducativo}>
                            <option></option>
                            <option>Universidad/Posgrado</option>
                            <option>Preparatoria/Técnica</option>
                            <option>Secundaria</option>
                            <option>Otro</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Teléfono celular con lada*
                      </label>
                      <div className="input-group">
                        <input
                          type="tel"
                          onChange={handleCellPhoneChange}
                          value={data.personal.general.telefonoCelular}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[CellPhone]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-800">Dirección:</label>
                    </div>
                  </div>
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Calle*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleStreetChange}
                          value={data.personal.direccion.calle}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Street]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group ">
                      <div className="row justify-content-center">
                        <div className="col-lg3 col-md-6 col-sm-12 ">
                          <label className="font-weight-400">
                            Número exterior*
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleOutdoorNumberChange}
                              value={data.personal.direccion.numeroExterior}
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[OutdoorNumber]"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-lg3 col-md-6 col-sm-12">
                          <label className="font-weight-400">
                            Número interior
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleInteriorNumberChange}
                              value={data.personal.direccion.numeroInterior}
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              name="dzOther[InteriorNumber]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Colonia*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleCologneChange}
                          value={data.personal.direccion.colonia}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Cologne]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Delegación/municipio*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleMunicipalityChange}
                          value={data.personal.direccion.municipio}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Municipality]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Ciudad*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleCityChange}
                          value={data.personal.direccion.ciudad}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[City]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Estado*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleStateChange}
                          value={data.personal.direccion.estado}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[State]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Código postal*</label>
                      <div className="input-group">
                      <input
                          type="text"
                          onChange={handlePostalCodeChange}
                          value={data.personal.direccion.cp}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[PostalCode]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Tiempo viviendo ahí*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleTimeLivingChange}
                          value={data.personal.direccion.tiempoViviendo}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[TimeLiving]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Tipo de domicilio*
                      </label>
                      <div className="col-lg-12 border-bottom">
                        <div className="selected-box">
                          <Form.Select
                            className="form-control sm-preaprobacion"
                            onChange={handleTypeOfAddressChange}
                            value={data.personal.direccion.tipoDeDomicilio}>
                            <option></option>
                            <option>Familiares</option>
                            <option>Renta</option>
                            <option>Hipoteca</option>
                            <option>Propia</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Teléfono de casa con lada*
                      </label>
                      <div className="input-group">
                        <input
                          type="tel"
                          onChange={handleHomePhoneChange}
                          value={data.personal.direccion.telefonoCasa}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[City]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="resumen-row-space"></div>
                  
                  <div className="section text-center">  
                              
                    <label htmlFor="miCheckbox">
                    {isChecked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}  Acepto la <Link to="https://gminmediauto.com/politica-de-privacidad/?v=0b98720dcb2c" className="text-primary" target="_blank">política de privacidad</Link> de GMInmediAuto
                    <input 
                    type="checkbox" 
                    id="miCheckbox" 
                    name="miCheckbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    style={{ display: 'none' }}             
                />
                    </label>
         
                  </div>
                 <div className="resumen-row-space"></div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <button
                        name="submit"
                        type="submit"
                        value="submit"
                        onClick={handleNext}
                        className={`btn btn-primary w-100 d-block btn-rounded resumen-btn ${!isChecked ? 'disabled' : ''}`}
                        disabled={!isChecked}>
                        Siguiente paso
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Paso2 = (props) => {
  const { data, setData, setActiveStep } = props;

  const handleEconomyActivityChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        empleo: {
          ...prevState.empleo.empleo,
          actividadEconomica: e.target.value,
        },
      },
    }));
  };
  const handleCompanyChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        empleo: {
          ...prevState.empleo.empleo,
          empresa: e.target.value,
        },
      },
    }));
  };
  const handleBusinessLineChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        empleo: {
          ...prevState.empleo.empleo,
          giroDeLaEmpresa: e.target.value,
        },
      },
    }));
  };
  const handleJobTypeChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        empleo: {
          ...prevState.empleo.empleo,
          tipoEmpleo: e.target.value,
        },
      },
    }));
  };
  const handleImmediateBossChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        empleo: {
          ...prevState.empleo.empleo,
          jefeInmediato: e.target.value,
        },
      },
    }));
  };
  const handleTimeInEmploymentChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        empleo: {
          ...prevState.empleo.empleo,
          tiempoEnElEmpleo: e.target.value,
        },
      },
    }));
  };
  const handleSocialSecurityNumberChange = (e) => {
    /*Validar el campo de nss que solo reciba números */
    // Expresión regular para validar que solo se ingresen números
    const inputValue = e.target.value;
    // Expresión regular para validar que solo se ingresen exactamente 11 dígitos
    const numericRegex = /^[0-9]*$/;

    if (numericRegex.test(inputValue) || inputValue === "") {
      setData((prevState) => ({
        ...prevState,
        empleo: {
          ...prevState.empleo,
          empleo: {
            ...prevState.empleo.empleo,
            NumeroSeguroSocial: e.target.value,
          },
        },
      }));
    }
   
  };
  const handleStreetChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          calle: e.target.value,
        },
      },
    }));
  };
  const handleOutdoorNumberChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          numeroExterior: e.target.value,
        },
      },
    }));
  };
  const handleInteriorNumberChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          numeroInterior: e.target.value,
        },
      },
    }));
  };
  const handleCologneChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          colonia: e.target.value,
        },
      },
    }));
  };
  const handleMunicipalityChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          municipio: e.target.value,
        },
      },
    }));
  };
  const handleCityChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          ciudad: e.target.value,
        },
      },
    }));
  };
  const handleStateChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          estado: e.target.value,
        },
      },
    }));
  };
  const handlePostalCodeChange = (e) => {
    /*Validar el campo de nss que solo reciba números */
    // Expresión regular para validar que solo se ingresen números
    const inputValue = e.target.value;
    // Expresión regular para validar que solo se ingresen exactamente 11 dígitos
    const numericRegex = /^[0-9]*$/;

if (numericRegex.test(inputValue) || inputValue === "") {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          cp: e.target.value,
        },
      },
    }));
  };
 };
  const handleTimeWorkingChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          tiempoEnElEmpleo: e.target.value,
        },
      },
    }));
  };
  const handleWorkPhoneChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          telefonoDelTrabajo: e.target.value,
        },
      },
    }));
  };
 
  const handleExtensionChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        direccionDeLaEmpresa: {
          ...prevState.empleo.direccionDeLaEmpresa,
          extension: e.target.value,
        },
      },
    }));
  };
  const handlePayrollReceiptChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        ingresosMensuales: {
          ...prevState.empleo.ingresosMensuales,
          reciboNominaNeto: e.target.value,
        },
      },
    }));
  };
  const handleProfessionalOrBusinessServicesChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        ingresosMensuales: {
          ...prevState.empleo.ingresosMensuales,
          serviciosProfesionalesOEmpresariales: e.target.value,
        },
      },
    }));
  };
  const handleOthersChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      empleo: {
        ...prevState.empleo,
        ingresosMensuales: {
          ...prevState.empleo.ingresosMensuales,
          otros: e.target.value,
        },
      },
    }));
  };
  /* validación del formulario */
  const handleNext = (e) => {
    e.preventDefault();
    // Verificar si los campos obligatorios están llenos
    if (
      data.empleo.empleo.actividadEconomica &&
      data.empleo.empleo.empresa &&
      data.empleo.empleo.giroDeLaEmpresa &&
      data.empleo.empleo.tipoEmpleo &&
      data.empleo.empleo.jefeInmediato &&
      data.empleo.empleo.tiempoEnElEmpleo &&
      data.empleo.empleo.NumeroSeguroSocial &&
      data.empleo.direccionDeLaEmpresa.calle &&
      data.empleo.direccionDeLaEmpresa.numeroExterior &&
      data.empleo.direccionDeLaEmpresa.colonia &&
      data.empleo.direccionDeLaEmpresa.municipio &&
      data.empleo.direccionDeLaEmpresa.ciudad &&
      data.empleo.direccionDeLaEmpresa.estado &&
      data.empleo.direccionDeLaEmpresa.cp &&
      data.empleo.direccionDeLaEmpresa.tiempoEnElEmpleo &&
      data.empleo.direccionDeLaEmpresa.telefonoDelTrabajo &&
      data.empleo.ingresosMensuales.reciboNominaNeto
    ) {
      // Avanzar al siguiente paso
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      // Mostrar mensaje de error o realizar alguna otra acción
      
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 m-b40">
            <div className="contact-area-preaprobacion">
              <form
                className="dlab-form style-1 dzForm">
                <input
                  type="hidden"
                  className="form-control"
                  name="dzToDo"
                  value="Contact"
                />
                <input
                  type="hidden"
                  className="form-control"
                  name="reCaptchaEnable"
                  value="0"
                />
                <div className="dzFormMsg"></div>
                <div className="row sp10">
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <label className="font-weight-800">Empleo:</label>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Actividad económica*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleEconomyActivityChange}
                          value={data.empleo.empleo.actividadEconomica}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzEconomicActivity"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Empresa*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleCompanyChange}
                          value={data.empleo.empleo.empresa}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Company]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Giro de la empresa*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleBusinessLineChange}
                          value={data.empleo.empleo.giroDeLaEmpresa}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[BusinessLine]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Tipo de empleo*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleJobTypeChange}
                          value={data.empleo.empleo.tipoEmpleo}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[JobType]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Jefe inmediato*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleImmediateBossChange}
                          value={data.empleo.empleo.jefeInmediato}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[ImmediateBoss]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Tiempo en ese empleo*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleTimeInEmploymentChange}
                          value={data.empleo.empleo.tiempoEnElEmpleo}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[TimeInEmployment]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Número de seguro social*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleSocialSecurityNumberChange}
                          value={data.empleo.empleo.NumeroSeguroSocial}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[SocialSecurityNumber]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group"></div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <label className="font-weight-800">
                      Dirección de la empresa donde trabajas:
                    </label>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Calle*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleStreetChange}
                          value={data.empleo.direccionDeLaEmpresa.calle}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Street]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group ">
                      <div className="row justify-content-center">
                        <div className="col-lg3 col-md-6 col-sm-12 ">
                          <label className="font-weight-400">
                            Número exterior*
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleOutdoorNumberChange}
                              value={
                                data.empleo.direccionDeLaEmpresa.numeroExterior
                              }
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[OutdoorNumber]"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-lg3 col-md-6 col-sm-12">
                          <label className="font-weight-400">
                            Número interior
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleInteriorNumberChange}
                              value={
                                data.empleo.direccionDeLaEmpresa.numeroInterior
                              }
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              name="dzOther[InteriorNumber]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Colonia*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleCologneChange}
                          value={data.empleo.direccionDeLaEmpresa.colonia}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Cologne]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Delegación/municipio*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleMunicipalityChange}
                          value={data.empleo.direccionDeLaEmpresa.municipio}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Municipality]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Ciudad*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleCityChange}
                          value={data.empleo.direccionDeLaEmpresa.ciudad}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[City]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Estado*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleStateChange}
                          value={data.empleo.direccionDeLaEmpresa.estado}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[State]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Código postal*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePostalCodeChange}
                          value={data.empleo.direccionDeLaEmpresa.cp}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[PostalCode]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Tiempo trabajando ahí*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleTimeWorkingChange}
                          value={
                            data.empleo.direccionDeLaEmpresa.tiempoEnElEmpleo
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[TimeLiving]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Teléfono de trabajo con lada*
                      </label>
                      <div className="input-group">
                        <input
                          type="tel"
                          onChange={handleWorkPhoneChange}
                          value={
                            data.empleo.direccionDeLaEmpresa.telefonoDelTrabajo
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[WorkPhone]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Extensión</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleExtensionChange}
                          value={data.empleo.direccionDeLaEmpresa.extension}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          name="dzOther[Extension]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group"></div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <label className="font-weight-800">
                      Ingresos mensuales
                    </label>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Recibo de nómina (neto)*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePayrollReceiptChange}
                          value={data.empleo.ingresosMensuales.reciboNominaNeto}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[TimeLiving]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Servicios profesionales o empresariales $
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleProfessionalOrBusinessServicesChange}
                          value={data.empleo.ingresosMensuales.serviciosProfesionalesOEmpresariales}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          name="dzOther[ProfessionalOrBusinessServices]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Otros $</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleOthersChange}
                          value={data.empleo.ingresosMensuales.otros}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          name="dzOther[Extension]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="resumen-row-space"></div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <button
                        name="submit"
                        type="submit"
                        value="submit"
                        onClick={handleNext}
                        className="btn btn-primary w-100 d-block btn-rounded resumen-btn">
                        Siguiente paso
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Paso3 = (props) => {
  const { data, setData, setActiveStep } = props;
  const [youHaveSpouse, setYouHaveSpouse] = useState("");
  const [spouseName, setSpouseName] = useState("");
  const [spouseSurname, setSpouseSurname] = useState("");

  const [LiveAtTheSameAddressAsYou, setLiveAtTheSameAddressAsYou] =
    useState("");

  const handleYouHaveSpouseChange = (e) => {
    const selectedValue = e.target.value;
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          tienesConyuge: e.target.value,
        },
      },
    }));

    setYouHaveSpouse(selectedValue);

    // Si selecciona "sí", avanzar automáticamente al siguiente paso
    if (selectedValue === "si") {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleNext = (e) => {
      e.preventDefault();
      // Verificar si se debe avanzar al siguiente paso
      if (
        youHaveSpouse === "no" ||
        (youHaveSpouse === "si" && spouseName !== "" && spouseSurname !== "")
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        alert(
          "Por favor, complete los datos de su cónyuge antes de continuar."
        );
      }
    };
  };
  const handleNamesChange = (e) => {
    const value = e.target.value;
    setSpouseName(value);
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          nombres: e.target.value,
        },
      },
    }));
  };
  const handleSurnameChange = (e) => {
    const value = e.target.value;
    setSpouseSurname(value);
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          apellidoPaterno: e.target.value,
        },
      },
    }));
  };
  const handleSecondSurnameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          apellidoMaterno: e.target.value,
        },
      },
    }));
  };
  const handleBirthdateChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          fechaNacimiento: e.target.value,
        },
      },
    }));
  };
  const handleCellPhoneChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          telefonoCelular: e.target.value,
        },
      },
    }));
  };
  const handleOccupationChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          ocupacion: e.target.value,
        },
      },
    }));
  };
  const handleWorkPhoneChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          telefonoDelTrabajo: e.target.value,
        },
      },
    }));
  };
  const handleExtensionChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          extension: e.target.value,
        },
      },
    }));
  };
  const handleLiveAtTheSameAddressAsYouChange = (e) => {
    const selectedValue = e.target.value;
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          viveEnTuMismaDireccion: e.target.value,
        },
      },
    }));
    setLiveAtTheSameAddressAsYou(selectedValue);

    // Si selecciona "sí", avanzar automáticamente al siguiente paso
    if (selectedValue === "no") {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleNext = (e) => {
      e.preventDefault();
      // Verificar si se debe avanzar al siguiente paso
      if (LiveAtTheSameAddressAsYou === "si") {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        alert("Por favor, complete los datos antes de continuar.");
      }
    };
  };
  const handleStreetChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          calle: e.target.value,
        },
      },
    }));
  };
  const handleOutdoorNumberChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          numeroExterior: e.target.value,
        },
      },
    }));
  };
  const handleInteriorNumberChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          numeroInterior: e.target.value,
        },
      },
    }));
  };
  const handleCologneChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          colonia: e.target.value,
        },
      },
    }));
  };
  const handleMunicipalityChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          municipio: e.target.value,
        },
      },
    }));
  };
  const handleCityChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          ciudad: e.target.value,
        },
      },
    }));
  };
  const handleStateChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          estado: e.target.value,
        },
      },
    }));
  };
  const handlePostalCodeChange = (e) => {
    /*Validar el campo de nss que solo reciba números */
    // Expresión regular para validar que solo se ingresen números
    const inputValue = e.target.value;
    // Expresión regular para validar que solo se ingresen exactamente 11 dígitos
    const numericRegex = /^[0-9]*$/;

if (numericRegex.test(inputValue) || inputValue === "") {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        datosConyuge: {
          ...prevState.referencias.datosConyuge,
          cp: e.target.value,
        },
      },
    }));
  };
 };
  const handleFamilyNameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliar: {
          ...prevState.referencias.referenciaFamiliar,
          nombre: e.target.value,
        },
      },
    }));
  };
  const handleFamilySurnameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliar: {
          ...prevState.referencias.referenciaFamiliar,
          apellidoPaterno: e.target.value,
        },
      },
    }));
  };
  const handleFamilySecondSurnameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliar: {
          ...prevState.referencias.referenciaFamiliar,
          apellidoMaterno: e.target.value,
        },
      },
    }));
  };
  const handleFamilyBirthdateChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliar: {
          ...prevState.referencias.referenciaFamiliar,
          fechaNacimiento: e.target.value,
        },
      },
    }));
  };
  const handleFamilyRelationshipChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliar: {
          ...prevState.referencias.referenciaFamiliar,
          parentesco: e.target.value,
        },
      },
    }));
  };
  const handlePhoneChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliar: {
          ...prevState.referencias.referenciaFamiliar,
          telefono: e.target.value,
        },
      },
    }));
  };

  const handleFamilyStreetChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliar: {
          ...prevState.referencias.referenciaFamiliar,
          calle: e.target.value,
        },
      },
    }));
  };
  const handleFamilyOutdoorNumberChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliar: {
          ...prevState.referencias.referenciaFamiliar,
          numeroExterior: e.target.value,
        },
      },
    }));
  };
  const handleFamilyInteriorNumberChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliar: {
          ...prevState.referencias.referenciaFamiliar,
          numeroInterior: e.target.value,
        },
      },
    }));
  };
  const handleFamilyCologneChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliar: {
          ...prevState.referencias.referenciaFamiliar,
          colonia: e.target.value,
        },
      },
    }));
  };
  const handleFamilyMunicipalityChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliar: {
          ...prevState.referencias.referenciaFamiliar,
          municipio: e.target.value,
        },
      },
    }));
  };
  const handleFamilyCityChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliar: {
          ...prevState.referencias.referenciaFamiliar,
          ciudad: e.target.value,
        },
      },
    }));
  };
  const handleFamilyStateChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliar: {
          ...prevState.referencias.referenciaFamiliar,
          estado: e.target.value,
        },
      },
    }));
  };
  const handleFamilyPostalCodeChange = (e) => {
    /*Validar el campo de nss que solo reciba números */
    // Expresión regular para validar que solo se ingresen números
    const inputValue = e.target.value;
    // Expresión regular para validar que solo se ingresen exactamente 11 dígitos
    const numericRegex = /^[0-9]*$/;

if (numericRegex.test(inputValue) || inputValue === "") {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliar: {
          ...prevState.referencias.referenciaFamiliar,
          cp: e.target.value,
        },
      },
    }));
  };
 };
  const handleFamilyNameSecondChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliarSegundo: {
          ...prevState.referencias.referenciaFamiliarSegundo,
          nombre: e.target.value,
        },
      },
    }));
  };
  const handleFamilySurnameSecondChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliarSegundo: {
          ...prevState.referencias.referenciaFamiliarSegundo,
          apellidoPaterno: e.target.value,
        },
      },
    }));
  };
  const handleFamilySecondSurnameSecondChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliarSegundo: {
          ...prevState.referencias.referenciaFamiliarSegundo,
          apellidoMaterno: e.target.value,
        },
      },
    }));
  };
  const handleFamilyPhoneSecondChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliarSegundo: {
          ...prevState.referencias.referenciaFamiliarSegundo,
          telefono: e.target.value,
        },
      },
    }));
  };
 
  const handleSecondFamilyRelationshipChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaFamiliarSegundo: {
          ...prevState.referencias.referenciaFamiliarSegundo,
          parentesco: e.target.value,
        },
      },
    }));
  };
  const handlePersonalNameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonal: {
          ...prevState.referencias.referenciaPersonal,
          nombre: e.target.value,
        },
      },
    }));
  };
  const handlePersonalSurnameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonal: {
          ...prevState.referencias.referenciaPersonal,
          apellidoPaterno: e.target.value,
        },
      },
    }));
  };
  const handlePersonalSecondSurnameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonal: {
          ...prevState.referencias.referenciaPersonal,
          apellidoMaterno: e.target.value,
        },
      },
    }));
  };
  const handlePersonalPhoneChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonal: {
          ...prevState.referencias.referenciaPersonal,
          telefono: e.target.value,
        },
      },
    }));
  };
 
  const handleRelationshipWithYouChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonal: {
          ...prevState.referencias.referenciaPersonal,
          relacionConUsted: e.target.value,
        },
      },
    }));
  };
  const handlePersonalNameSecondChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonalSegundo: {
          ...prevState.referencias.referenciaPersonalSegundo,
          nombre: e.target.value,
        },
      },
    }));
  };
  const handlePersonalSurnameSecondChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonalSegundo: {
          ...prevState.referencias.referenciaPersonalSegundo,
          apellidoPaterno: e.target.value,
        },
      },
    }));
  };
  const handlePersonalSecondSurnameSecondChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonalSegundo: {
          ...prevState.referencias.referenciaPersonalSegundo,
          apellidoMaterno: e.target.value,
        },
      },
    }));
  };
  const handlePersonalPhoneSecondChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonalSegundo: {
          ...prevState.referencias.referenciaPersonalSegundo,
          telefono: e.target.value,
        },
      },
    }));
  };
 
  const handleRelationshipWithYouSecondChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      referencias: {
        ...prevState.referencias,
        referenciaPersonalSegundo: {
          ...prevState.referencias.referenciaPersonalSegundo,
          relacionConUsted: e.target.value,
        },
      },
    }));
  };

  /* validación del formulario */
  const handleNext = (e) => {
    e.preventDefault();
    // Verificar si los campos obligatorios están llenos
    if (data.referencias.datosConyuge.tienesConyuge === "Sí") {
      if(data.referencias.datosConyuge.viveEnTuMismaDireccion === "No") {
        if (
          data.referencias.datosConyuge.nombres &&
          data.referencias.datosConyuge.apellidoPaterno &&
          data.referencias.datosConyuge.apellidoMaterno &&
          data.referencias.datosConyuge.fechaNacimiento &&
          data.referencias.datosConyuge.telefonoCelular &&
          data.referencias.datosConyuge.ocupacion &&
          data.referencias.datosConyuge.calle &&
          data.referencias.datosConyuge.numeroExterior &&
          data.referencias.datosConyuge.colonia &&
          data.referencias.datosConyuge.municipio &&
          data.referencias.datosConyuge.ciudad &&
          data.referencias.datosConyuge.estado &&
          data.referencias.datosConyuge.cp &&
          data.referencias.referenciaFamiliar.nombre &&
          data.referencias.referenciaFamiliar.apellidoPaterno &&
          data.referencias.referenciaFamiliar.apellidoMaterno &&
          data.referencias.referenciaFamiliar.fechaNacimiento &&
          data.referencias.referenciaFamiliar.parentesco &&
          data.referencias.referenciaFamiliar.telefono &&
          data.referencias.referenciaFamiliar.calle &&
          data.referencias.referenciaFamiliar.numeroExterior &&
          data.referencias.referenciaFamiliar.colonia &&
          data.referencias.referenciaFamiliar.municipio &&
          data.referencias.referenciaFamiliar.ciudad &&
          data.referencias.referenciaFamiliar.estado &&
          data.referencias.referenciaFamiliar.cp &&
          data.referencias.referenciaFamiliarSegundo.nombre &&
          data.referencias.referenciaFamiliarSegundo.apellidoPaterno &&
          data.referencias.referenciaFamiliarSegundo.apellidoMaterno &&
          data.referencias.referenciaFamiliarSegundo.telefono &&
          data.referencias.referenciaFamiliarSegundo.parentesco &&
          data.referencias.referenciaPersonal.nombre &&
          data.referencias.referenciaPersonal.apellidoPaterno &&
          data.referencias.referenciaPersonal.apellidoMaterno &&
          data.referencias.referenciaPersonal.telefono &&
          data.referencias.referenciaPersonal.relacionConUsted &&
          data.referencias.referenciaPersonalSegundo.nombre &&
          data.referencias.referenciaPersonalSegundo.apellidoPaterno &&
          data.referencias.referenciaPersonalSegundo.apellidoMaterno &&
          data.referencias.referenciaPersonalSegundo.telefono &&
          data.referencias.referenciaPersonalSegundo.relacionConUsted
        ) {
          // Avanzar al siguiente paso
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        else {
          // Mostrar mensaje de error o realizar alguna otra acción
          
        }
      }
      else {
        if (
          data.referencias.datosConyuge.nombres &&
          data.referencias.datosConyuge.apellidoPaterno &&
          data.referencias.datosConyuge.apellidoMaterno &&
          data.referencias.datosConyuge.fechaNacimiento &&
          data.referencias.datosConyuge.telefonoCelular &&
          data.referencias.datosConyuge.ocupacion &&
          data.referencias.referenciaFamiliar.nombre &&
          data.referencias.referenciaFamiliar.apellidoPaterno &&
          data.referencias.referenciaFamiliar.apellidoMaterno &&
          data.referencias.referenciaFamiliar.fechaNacimiento &&
          data.referencias.referenciaFamiliar.parentesco &&
          data.referencias.referenciaFamiliar.telefono &&
          data.referencias.referenciaFamiliar.calle &&
          data.referencias.referenciaFamiliar.numeroExterior &&
          data.referencias.referenciaFamiliar.colonia &&
          data.referencias.referenciaFamiliar.municipio &&
          data.referencias.referenciaFamiliar.ciudad &&
          data.referencias.referenciaFamiliar.estado &&
          data.referencias.referenciaFamiliar.cp &&
          data.referencias.referenciaFamiliarSegundo.nombre &&
          data.referencias.referenciaFamiliarSegundo.apellidoPaterno &&
          data.referencias.referenciaFamiliarSegundo.apellidoMaterno &&
          data.referencias.referenciaFamiliarSegundo.telefono &&
          data.referencias.referenciaFamiliarSegundo.parentesco &&
          data.referencias.referenciaPersonal.nombre &&
          data.referencias.referenciaPersonal.apellidoPaterno &&
          data.referencias.referenciaPersonal.apellidoMaterno &&
          data.referencias.referenciaPersonal.telefono &&
          data.referencias.referenciaPersonal.relacionConUsted &&
          data.referencias.referenciaPersonalSegundo.nombre &&
          data.referencias.referenciaPersonalSegundo.apellidoPaterno &&
          data.referencias.referenciaPersonalSegundo.apellidoMaterno &&
          data.referencias.referenciaPersonalSegundo.telefono &&
          data.referencias.referenciaPersonalSegundo.relacionConUsted
        ){
          // Avanzar al siguiente paso
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        else {
          // Mostrar mensaje de error o realizar alguna otra acción
          
        }
      }
    }
    else {
      if (
        data.referencias.referenciaFamiliar.nombre &&
        data.referencias.referenciaFamiliar.apellidoPaterno &&
        data.referencias.referenciaFamiliar.apellidoMaterno &&
        data.referencias.referenciaFamiliar.fechaNacimiento &&
        data.referencias.referenciaFamiliar.parentesco &&
        data.referencias.referenciaFamiliar.telefono &&
        data.referencias.referenciaFamiliar.calle &&
        data.referencias.referenciaFamiliar.numeroExterior &&
        data.referencias.referenciaFamiliar.colonia &&
        data.referencias.referenciaFamiliar.municipio &&
        data.referencias.referenciaFamiliar.ciudad &&
        data.referencias.referenciaFamiliar.estado &&
        data.referencias.referenciaFamiliar.cp &&
        data.referencias.referenciaFamiliarSegundo.nombre &&
        data.referencias.referenciaFamiliarSegundo.apellidoPaterno &&
        data.referencias.referenciaFamiliarSegundo.apellidoMaterno &&
        data.referencias.referenciaFamiliarSegundo.telefono &&
        data.referencias.referenciaFamiliarSegundo.parentesco &&
        data.referencias.referenciaPersonal.nombre &&
        data.referencias.referenciaPersonal.apellidoPaterno &&
        data.referencias.referenciaPersonal.apellidoMaterno &&
        data.referencias.referenciaPersonal.telefono &&
        data.referencias.referenciaPersonal.relacionConUsted &&
        data.referencias.referenciaPersonalSegundo.nombre &&
        data.referencias.referenciaPersonalSegundo.apellidoPaterno &&
        data.referencias.referenciaPersonalSegundo.apellidoMaterno &&
        data.referencias.referenciaPersonalSegundo.telefono &&
        data.referencias.referenciaPersonalSegundo.relacionConUsted
      ) {
        // Avanzar al siguiente paso
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      else {
        // Mostrar mensaje de error o realizar alguna otra acción
        
      }
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 m-b40">
            <div className="contact-area-preaprobacion">
              <form
                className="dlab-form style-1 dzForm">
                <input
                  type="hidden"
                  className="form-control"
                  name="dzToDo"
                  value="Contact"
                />
                <input
                  type="hidden"
                  className="form-control"
                  name="reCaptchaEnable"
                  value="0"
                />
                <div className="dzFormMsg"></div>
                <div className="row sp10">
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <label className="font-weight-800">
                      Datos del cónyuge:
                    </label>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <label className="font-weight-400">¿Tienes cónyuge?*</label>
                    <div className="col-lg-12 border-bottom">
                      <div className="selected-box">
                        <Form.Select
                          className="form-control sm-preaprobacion"
                          name="select"
                          id="select"
                          onChange={handleYouHaveSpouseChange}
                          value={data.referencias.datosConyuge.tienesConyuge}>
                          <option></option>
                          <option>Sí</option>
                          <option>No</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                  {data.referencias.datosConyuge.tienesConyuge === "Sí" && (
                    <>
                      <div className="form-group"></div>
                      <div className="col-lg-6">
                        <div className="form-group" id="opc1">
                          <label className="font-weight-400">Nombres(s)*</label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleNamesChange}
                              value={data.referencias.datosConyuge.nombres}
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzName"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="font-weight-400">
                            Apellido Paterno*
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleSurnameChange}
                              value={
                                data.referencias.datosConyuge.apellidoPaterno
                              }
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[Surname]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="font-weight-400">
                            Apellido Materno*
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleSecondSurnameChange}
                              value={
                                data.referencias.datosConyuge.apellidoMaterno
                              }
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[SecondSurname]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="font-weight-400">
                            Fecha de nacimiento*
                          </label>
                          <div className="input-group">
                            <input
                              type="date"
                              onChange={handleBirthdateChange}
                              value={
                                data.referencias.datosConyuge.fechaNacimiento
                              }
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[Birthdate]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="font-weight-400">
                            Teléfono de celular con lada*
                          </label>
                          <div className="input-group">
                            <input
                              type="tel"
                              onChange={handleCellPhoneChange}
                              value={
                                data.referencias.datosConyuge.telefonoCelular
                              }
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[CellPhone]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="font-weight-400">Ocupación*</label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleOccupationChange}
                              value={data.referencias.datosConyuge.ocupacion}
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[Occupation]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="font-weight-400">
                            Teléfono de trabajo con lada
                          </label>
                          <div className="input-group">
                            <input
                              type="tel"
                              onChange={handleWorkPhoneChange}
                              value={
                                data.referencias.datosConyuge.telefonoDelTrabajo
                              }
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[WorkPhone]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="font-weight-400">Extensión</label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleExtensionChange}
                              value={data.referencias.datosConyuge.extension}
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              name="dzOther[CountryBirth]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        ¿Vive en tu misma dirección?*
                      </label>
                      <div className="col-lg-12 border-bottom">
                        <div className="selected-box">
                          <Form.Select
                            className="form-control sm-preaprobacion"
                            onChange={handleLiveAtTheSameAddressAsYouChange}
                            value={
                              data.referencias.datosConyuge
                                .viveEnTuMismaDireccion
                            }>
                            <option></option>
                            <option>Sí</option>
                            <option>No</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {data.referencias.datosConyuge.viveEnTuMismaDireccion ===
                    "No" && (
                    <>
                      <div className="form-group"></div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="font-weight-400">Calle*</label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleStreetChange}
                              value={data.referencias.datosConyuge.calle}
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[Street]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group ">
                          <div className="row justify-content-center">
                            <div className="col-lg3 col-md-6 col-sm-12 ">
                              <label className="font-weight-400">
                                Número exterior*
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  onChange={handleOutdoorNumberChange}
                                  value={
                                    data.referencias.datosConyuge.numeroExterior
                                  }
                                  className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                                  required
                                  name="dzOther[OutdoorNumber]"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="col-lg3 col-md-6 col-sm-12">
                              <label className="font-weight-400">
                                Número interior
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  onChange={handleInteriorNumberChange}
                                  value={
                                    data.referencias.datosConyuge.numeroInterior
                                  }
                                  className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                                  name="dzOther[InteriorNumber]"
                                  placeholder=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="font-weight-400">Colonia*</label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleCologneChange}
                              value={data.referencias.datosConyuge.colonia}
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[Cologne]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="font-weight-400">
                            Delegación/municipio*
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleMunicipalityChange}
                              value={data.referencias.datosConyuge.municipio}
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[Municipality]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="font-weight-400">Ciudad*</label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleCityChange}
                              value={data.referencias.datosConyuge.ciudad}
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[City]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="font-weight-400">Estado*</label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleStateChange}
                              value={data.referencias.datosConyuge.estado}
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[State]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="font-weight-400">
                            Código postal*
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handlePostalCodeChange}
                              value={data.referencias.datosConyuge.cp}
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[PostalCode]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                    </>
                  )}
                  
                  <div className="form-group"></div>
                  <div>
                    <label className="font-weight-800">
                      Referencia de familiar (1)
                    </label>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Nombre(s)*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleFamilyNameChange}
                          value={data.referencias.referenciaFamiliar.nombre}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzName"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Paterno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleFamilySurnameChange}
                          value={
                            data.referencias.referenciaFamiliar.apellidoPaterno
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[FamilySurname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Materno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleFamilySecondSurnameChange}
                          value={
                            data.referencias.referenciaFamiliar.apellidoMaterno
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[FamilySecondSurname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Fecha de nacimiento*
                      </label>
                      <div className="input-group">
                        <input
                          type="date"
                          onChange={handleFamilyBirthdateChange}
                          value={
                            data.referencias.referenciaFamiliar.fechaNacimiento
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[FamilyBirthdate]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Parentesco*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleFamilyRelationshipChange}
                          value={data.referencias.referenciaFamiliar.parentesco}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Occupation]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Teléfono con lada*
                      </label>
                      <div className="input-group">
                        <input
                          type="tel"
                          onChange={handlePhoneChange}
                          value={data.referencias.referenciaFamiliar.telefono}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Phone]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Calle*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleFamilyStreetChange}
                          value={data.referencias.referenciaFamiliar.calle}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[FamilyStreet]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group ">
                      <div className="row justify-content-center">
                        <div className="col-lg3 col-md-6 col-sm-12 ">
                          <label className="font-weight-400">
                            Número exterior*
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleFamilyOutdoorNumberChange}
                              value={
                                data.referencias.referenciaFamiliar.numeroExterior
                              }
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[FamilyOutdoorNumber]"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-lg3 col-md-6 col-sm-12">
                          <label className="font-weight-400">
                            Número interior
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleFamilyInteriorNumberChange}
                              value={
                                data.referencias.referenciaFamiliar.numeroInterior
                              }
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              name="dzOther[FamilyInteriorNumber]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Colonia*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleFamilyCologneChange}
                          value={data.referencias.referenciaFamiliar.colonia}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[FamilyCologne]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Delegación/municipio*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleFamilyMunicipalityChange}
                          value={data.referencias.referenciaFamiliar.municipio}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[FamilyMunicipality]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Ciudad*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleFamilyCityChange}
                          value={data.referencias.referenciaFamiliar.ciudad}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[FamilyCity]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Estado*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleFamilyStateChange}
                          value={data.referencias.referenciaFamiliar.estado}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[FamilyState]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Código postal*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleFamilyPostalCodeChange}
                          value={data.referencias.referenciaFamiliar.cp}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[FamilyPostalCode]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group"></div>
                  <div className="form-group"></div>
                  <div>
                    <label className="font-weight-800">
                      Referencia de familiar (2)
                    </label>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Nombre(s)*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleFamilyNameSecondChange}
                          value={
                            data.referencias.referenciaFamiliarSegundo.nombre
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzName"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Paterno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleFamilySurnameSecondChange}
                          value={
                            data.referencias.referenciaFamiliarSegundo.apellidoPaterno
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[FamilySurname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Materno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleFamilySecondSurnameSecondChange}
                          value={
                            data.referencias.referenciaFamiliarSegundo.apellidoMaterno
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[FamilySecondSurname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Teléfono con lada*
                      </label>
                      <div className="input-group">
                        <input
                          type="tel"
                          onChange={handleFamilyPhoneSecondChange}
                          value={
                            data.referencias.referenciaFamiliarSegundo.telefono
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Phone]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Parentesco*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleSecondFamilyRelationshipChange}
                          value={
                            data.referencias.referenciaFamiliarSegundo.parentesco
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Occupation]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group"></div>
                  <div className="form-group"></div>
                  <div>
                    <label className="font-weight-800">
                      Referencia personal que no viva con usted ni sea un
                      familiar (1)
                    </label>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Nombre(s)*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePersonalNameChange}
                          value={data.referencias.referenciaPersonal.nombre}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzName"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Paterno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePersonalSurnameChange}
                          value={
                            data.referencias.referenciaPersonal.apellidoPaterno
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[PersonalSurname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Materno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePersonalSecondSurnameChange}
                          value={
                            data.referencias.referenciaPersonal.apellidoMaterno
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[PersonalSecondSurname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Teléfono con lada*
                      </label>
                      <div className="input-group">
                        <input
                          type="tel"
                          onChange={handlePersonalPhoneChange}
                          value={data.referencias.referenciaPersonal.telefono}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[PersonalPhone]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Relación con usted*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleRelationshipWithYouChange}
                          value={
                            data.referencias.referenciaPersonal.relacionConUsted
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Occupation]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group"></div>
                  <div className="form-group"></div>
                  <div>
                    <label className="font-weight-800">
                      Referencia personal que no viva con usted ni sea un
                      familiar (2)
                    </label>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Nombre(s)*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePersonalNameSecondChange}
                          value={
                            data.referencias.referenciaPersonalSegundo.nombre
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzName"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Paterno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePersonalSurnameSecondChange}
                          value={
                            data.referencias.referenciaPersonalSegundo.apellidoPaterno
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[PersonalSurname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Materno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePersonalSecondSurnameSecondChange}
                          value={
                            data.referencias.referenciaPersonalSegundo.apellidoMaterno
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[PersonalSecondSurname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Teléfono con lada*
                      </label>
                      <div className="input-group">
                        <input
                          type="tel"
                          onChange={handlePersonalPhoneSecondChange}
                          value={
                            data.referencias.referenciaPersonalSegundo.telefono
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[PersonalPhone]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Relación con usted*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleRelationshipWithYouSecondChange}
                          value={
                            data.referencias.referenciaPersonalSegundo.relacionConUsted
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Occupation]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="resumen-row-space"></div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <button
                        name="submit"
                        type="submit"
                        value="submit"
                        onClick={handleNext}
                        className="btn btn-primary w-100 d-block btn-rounded resumen-btn">
                        Siguiente paso
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Paso4 = (props) => {
  const { data, setData, setActiveStep } = props;

  const handleBankInstitutionChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      domiciliacion: {
        ...prevState.domiciliacion,
        bancoInstitucion: e.target.value,
      },
    }));
  };
  const handleInterbankCodeChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      domiciliacion: {
        ...prevState.domiciliacion,
        ClabeInterbancaria: e.target.value,
      },
    }));
  };

  /* validación del formulario */
  const handleNext = (e) => {
    e.preventDefault();
    // Verificar si los campos obligatorios están llenos
    if (
      data.domiciliacion.bancoInstitucion &&
      data.domiciliacion.ClabeInterbancaria
    ) {
      // Avanzar al siguiente paso
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      // Mostrar mensaje de error o realizar alguna otra acción
      
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 m-b40">
            <div className="contact-area-preaprobacion">
              <form
                className="dlab-form style-1 dzForm">
                <input
                  type="hidden"
                  className="form-control"
                  name="dzToDo"
                  value="Contact"
                />
                <input
                  type="hidden"
                  className="form-control"
                  name="reCaptchaEnable"
                  value="0"
                />
                <div className="dzFormMsg"></div>
                <div className="row sp10">
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <label className="font-weight-800">
                      Domiciliacion (la cuenta debe estar a nombre del titular)
                    </label>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6"></div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Banco o institución*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleBankInstitutionChange}
                          value={data.domiciliacion.bancoInstitucion}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzBankInstitution"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        CLABE interbancaria*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleInterbankCodeChange}
                          value={data.domiciliacion.ClabeInterbancaria}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Interbanck]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="resumen-row-space"></div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <button
                        name="submit"
                        type="submit"
                        value="submit"
                        onClick={handleNext}
                        className="btn btn-primary w-100 d-block btn-rounded resumen-btn">
                        Siguiente paso
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
};

const Paso5 = (props) => {
  const { data, setData, setActiveStep, setTextTitleOn, selectedData } = props;
  const { baseUrl } = useBaseUrl();

  const handleNameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        datosAval: {
          ...prevState.aval.datosAval,
          nombre: e.target.value,
        },
      },
    }));
  };
  const handleSurnameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        datosAval: {
          ...prevState.aval.datosAval,
          apellidoPaterno: e.target.value,
        },
      },
    }));
  };
  const handleSecondSurnameChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        datosAval: {
          ...prevState.aval.datosAval,
          apellidoMaterno: e.target.value,
        },
      },
    }));
  };
  const handleBirthdateChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        datosAval: {
          ...prevState.aval.datosAval,
          fechaNacimiento: e.target.value,
        },
      },
    }));
  };
  const handleGenderChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        datosAval: {
          ...prevState.aval.datosAval,
          genero: e.target.value,
        },
      },
    }));
  };
  const handleRFCChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        datosAval: {
          ...prevState.aval.datosAval,
          rfc: e.target.value,
        },
      },
    }));
  };
  const handleCurpChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        datosAval: {
          ...prevState.aval.datosAval,
          curp: e.target.value,
        },
      },
    }));
  };
  const handleCountryOfBirthChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        datosAval: {
          ...prevState.aval.datosAval,
          paisNacimiento: e.target.value,
        },
      },
    }));
  };
  const handleNationalityChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        datosAval: {
          ...prevState.aval.datosAval,
          nacionalidad: e.target.value,
        },
      },
    }));
  };
  const handleIDTypeChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        datosAval: {
          ...prevState.aval.datosAval,
          tipoIdentificacion: e.target.value,
        },
      },
    }));
  };
  const handleCivilStatusChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        datosAval: {
          ...prevState.aval.datosAval,
          estadoCivil: e.target.value,
        },
      },
    }));
  };
  const handleRegimeChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        datosAval: {
          ...prevState.aval.datosAval,
          regimen: e.target.value,
        },
      },
    }));
  };
  const handleNumberOfEconomicDependentsChange = (e) => {
    /*Validar el campo de nss que solo reciba números */
    // Expresión regular para validar que solo se ingresen números
    const inputValue = e.target.value;
    // Expresión regular para validar que solo se ingresen exactamente 11 dígitos
    const numericRegex = /^[0-9]*$/;

if (numericRegex.test(inputValue) || inputValue === "") {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        datosAval: {
          ...prevState.aval.datosAval,
          numeroDependientesEconomicos: e.target.value,
        },
      },
    }));
  };
 };
  const handleEmailChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        datosAval: {
          ...prevState.aval.datosAval,
          correoElectronico: e.target.value,
        },
      },
    }));
  };
  const handleEducationLevelChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        datosAval: {
          ...prevState.aval.datosAval,
          nivelEducativo: e.target.value,
        },
      },
    }));
  };
  const handleCellPhoneChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        datosAval: {
          ...prevState.aval.datosAval,
          telefonoCelular: e.target.value,
        },
      },
    }));
  };
  const handleStreetChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        direccionDelObligadoOAval: {
          ...prevState.aval.direccionDelObligadoOAval,
          calle: e.target.value,
        },
      },
    }));
  };
  const handleOutdoorNumberChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        direccionDelObligadoOAval: {
          ...prevState.aval.direccionDelObligadoOAval,
          numeroExterior: e.target.value,
        },
      },
    }));
  };
  const handleInteriorNumberChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        direccionDelObligadoOAval: {
          ...prevState.aval.direccionDelObligadoOAval,
          numeroInterior: e.target.value,
        },
      },
    }));
  };
  const handleCologneChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        direccionDelObligadoOAval: {
          ...prevState.aval.direccionDelObligadoOAval,
          colonia: e.target.value,
        },
      },
    }));
  };
  const handleMunicipalityChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        direccionDelObligadoOAval: {
          ...prevState.aval.direccionDelObligadoOAval,
          municipio: e.target.value,
        },
      },
    }));
  };
  const handleCityChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        direccionDelObligadoOAval: {
          ...prevState.aval.direccionDelObligadoOAval,
          ciudad: e.target.value,
        },
      },
    }));
  };
  const handleStateChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        direccionDelObligadoOAval: {
          ...prevState.aval.direccionDelObligadoOAval,
          estado: e.target.value,
        },
      },
    }));
  };
  const handlePostalCodeChange = (e) => {
    /*Validar el campo de nss que solo reciba números */
    // Expresión regular para validar que solo se ingresen números
    const inputValue = e.target.value;
    // Expresión regular para validar que solo se ingresen exactamente 11 dígitos
    const numericRegex = /^[0-9]*$/;

if (numericRegex.test(inputValue) || inputValue === "") {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        direccionDelObligadoOAval: {
          ...prevState.aval.direccionDelObligadoOAval,
          cp: e.target.value,
        },
      },
    }));
  };
 };
  const handleTimeLivingChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        direccionDelObligadoOAval: {
          ...prevState.aval.direccionDelObligadoOAval,
          tiempoViviendo: e.target.value,
        },
      },
    }));
  };
  const handleTypeOfAddressChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        direccionDelObligadoOAval: {
          ...prevState.aval.direccionDelObligadoOAval,
          tipoDeDomicilio: e.target.value,
        },
      },
    }));
  };
  const handleHomePhoneChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      aval: {
        ...prevState.aval,
        direccionDelObligadoOAval: {
          ...prevState.aval.direccionDelObligadoOAval,
          telefonoCasa: e.target.value,
        },
      },
    }));
  };
 

  /*////////Validación del formulario//////// */

  const handleNext = (e) => {
    e.preventDefault();
    // Verificar si los campos obligatorios están llenos
    if (
      data.aval.datosAval.nombre &&
      data.aval.datosAval.apellidoPaterno &&
      data.aval.datosAval.apellidoMaterno &&
      data.aval.datosAval.fechaNacimiento &&
      data.aval.datosAval.genero &&
      data.aval.datosAval.rfc &&
      data.aval.datosAval.curp &&
      data.aval.datosAval.paisNacimiento &&
      data.aval.datosAval.nacionalidad &&
      data.aval.datosAval.tipoIdentificacion &&
      data.aval.datosAval.estadoCivil &&
      data.aval.datosAval.regimen &&
      data.aval.datosAval.numeroDependientesEconomicos &&
      data.aval.datosAval.correoElectronico &&
      data.aval.datosAval.nivelEducativo &&
      data.aval.datosAval.telefonoCelular &&
      data.aval.direccionDelObligadoOAval.calle &&
      data.aval.direccionDelObligadoOAval.numeroExterior &&
      data.aval.direccionDelObligadoOAval.colonia &&
      data.aval.direccionDelObligadoOAval.municipio &&
      data.aval.direccionDelObligadoOAval.ciudad &&
      data.aval.direccionDelObligadoOAval.estado &&
      data.aval.direccionDelObligadoOAval.cp &&
      data.aval.direccionDelObligadoOAval.tiempoViviendo &&
      data.aval.direccionDelObligadoOAval.tipoDeDomicilio &&
      data.aval.direccionDelObligadoOAval.telefonoCasa
    ) {

      const generateCotizadorPDF = async () => {
        const blob = await pdf(<CotizacionPDF data={selectedData}/>).toBlob();
        sendCotizadorPDF(blob);
      };
  
      generateCotizadorPDF();
  
      const sendCotizadorPDF = async (blob) => {
        try {
          const formData = new FormData();
          formData.append("file", blob, "leasing.pdf");
          formData.append("nombre", data.personal.general.nombre);
          formData.append("apellidoPaterno", data.personal.general.apellidoPaterno);
          formData.append("apellidoMaterno", data.personal.general.apellidoMaterno);
          formData.append("correoElectronico", data.personal.general.correoElectronico);
          
          const response = await axios.post(
            baseUrl + "preaprobacionprospecto",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.status === 200) {
            //console.log(response.data.data);
          } else {
            console.log("Error loading leasing");
          }
        } catch (error) {
          console.error(error);
        }
      };

      const generatePreaprobacionPDF = async () => {
        const blob = await pdf(<PreaprobacionPDF data={data} />).toBlob();
        const blob2 = await pdf(<CotizacionPDF data={selectedData}/>).toBlob();
        sendPreaprobacionPDF(blob, blob2);
      };

      generatePreaprobacionPDF();

      const sendPreaprobacionPDF = async (blob, blob2) => {
        try {
          const formData = new FormData();
          formData.append("file", blob, "preaprobacion.pdf");
          formData.append("file2", blob2, "leasing.pdf");
          formData.append("nombre", data.personal.general.nombre);
          formData.append("apellidoPaterno", data.personal.general.apellidoPaterno);
          formData.append("apellidoMaterno", data.personal.general.apellidoMaterno);
          formData.append("fechaNacimiento", data.personal.general.fechaNacimiento);
          formData.append("genero", data.personal.general.genero);
          formData.append("rfc", data.personal.general.rfc);
          formData.append("curp", data.personal.general.curp);
          formData.append("paisNacimiento", data.personal.general.paisNacimiento);
          formData.append("nacionalidad", data.personal.general.nacionalidad);
          formData.append("tipoIdentificacion", data.personal.general.tipoIdentificacion);
          formData.append("estadoCivil", data.personal.general.estadoCivil);
          formData.append("regimen", data.personal.general.regimen);
          formData.append("numeroDependientesEconomicos", data.personal.general.numeroDependientesEconomicos);
          formData.append("correoElectronico", data.personal.general.correoElectronico);
          formData.append("nivelEducativo", data.personal.general.nivelEducativo);
          formData.append("telefonoCelular", data.personal.general.telefonoCelular);

          
          const response = await axios.post(
            baseUrl + "preaprobacionadmin",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.status === 200) {
            //console.log(response.data.data);
          } else {
            console.log("Error loading leasing");
          }
        } catch (error) {
          console.error(error);
        }
      };

      //Cambiar la activacion del titulo a true
      setTextTitleOn(true);
      // Avanzar al siguiente paso
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      // Mostrar mensaje de error o realizar alguna otra acción      
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 m-b40">
            <div className="contact-area-preaprobacion">
              <form
                className="dlab-form style-1 dzForm"
                >
                <input
                  type="hidden"
                  className="form-control"
                  name="dzToDo"
                  value="Contact"
                />
                <input
                  type="hidden"
                  className="form-control"
                  name="reCaptchaEnable"
                  value="0"
                />
                <div className="dzFormMsg"></div>
                <div className="row sp10">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-800">
                        Datos generales del obligado solidario o aval
                      </label>
                    </div>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Nombre(s)*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleNameChange}
                          value={data.aval.datosAval.nombre}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzName"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Paterno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleSurnameChange}
                          value={data.aval.datosAval.apellidoPaterno}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Surname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Apellido Materno*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleSecondSurnameChange}
                          value={data.aval.datosAval.apellidoMaterno}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[SecondSurname]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Fecha de nacimiento*
                      </label>
                      <div className="input-group">
                        <input
                          type="date"
                          onChange={handleBirthdateChange}
                          value={data.aval.datosAval.fechaNacimiento}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Birthdate]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Género*</label>
                      <div className="col-lg-12 border-bottom">
                        <div className="selected-box">
                          <Form.Select
                            className="form-control sm-preaprobacion"
                            onChange={handleGenderChange}
                            value={data.aval.datosAval.genero}>
                            <option></option>
                            <option>Masculino</option>
                            <option>Femenino</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">RFC*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleRFCChange}
                          value={data.aval.datosAval.rfc}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Rfc]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">CURP*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleCurpChange}
                          value={data.aval.datosAval.curp}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Curp]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        País de nacimiento*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleCountryOfBirthChange}
                          value={data.aval.datosAval.paisNacimiento}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[CountryBirth]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Nacionalidad*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleNationalityChange}
                          value={data.aval.datosAval.nacionalidad}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Nationality]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Tipo de identificación*
                      </label>
                      <div className="col-lg-12 border-bottom">
                        <div className="selected-box">
                          <Form.Select
                            className="form-control sm-preaprobacion"
                            onChange={handleIDTypeChange}
                            value={data.aval.datosAval.tipoIdentificacion}>
                            <option></option>
                            <option>INE</option>
                            <option>Pasaporte</option>
                            <option>FM2</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Estado civil*</label>
                      <div className="col-lg-12 border-bottom">
                        <div className="selected-box">
                          <Form.Select
                            className="form-control sm-preaprobacion"
                            onChange={handleCivilStatusChange}
                            value={data.aval.datosAval.estadoCivil}>
                            <option></option>
                            <option>Soltero(a)</option>
                            <option>Casado(a)</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Régimen*</label>
                      <div className="col-lg-12 border-bottom">
                        <div className="selected-box">
                          <Form.Select
                            className="form-control sm-preaprobacion"
                            onChange={handleRegimeChange}
                            value={data.aval.datosAval.regimen}>
                            <option></option>
                            <option>Bienes separados</option>
                            <option>Bienes mancomunados</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Número de dependientes económicos*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleNumberOfEconomicDependentsChange}
                          value={
                            data.aval.datosAval.numeroDependientesEconomicos
                          }
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[NumberEconomicDependents]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Correo electrónico*
                      </label>
                      <div className="input-group">
                        <input
                          type="email"
                          onChange={handleEmailChange}
                          value={data.aval.datosAval.correoElectronico}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Email]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Nivel educativo*
                      </label>
                      <div className="col-lg-12 border-bottom">
                        <div className="selected-box">
                          <Form.Select
                            className="form-control sm-preaprobacion"
                            onChange={handleEducationLevelChange}
                            value={data.aval.datosAval.nivelEducativo}>
                            <option></option>
                            <option>Universidad/Posgrado</option>
                            <option>Preparatoria/Técnica</option>
                            <option>Secundaria</option>
                            <option>Otro</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Teléfono celular con lada*
                      </label>
                      <div className="input-group">
                        <input
                          type="tel"
                          onChange={handleCellPhoneChange}
                          value={data.aval.datosAval.telefonoCelular}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[CellPhone]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-800">
                        Dirección del obligado solidario o aval
                      </label>
                    </div>
                  </div>
                  <div className="form-group"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Calle*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleStreetChange}
                          value={data.aval.direccionDelObligadoOAval.calle}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Street]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group ">
                      <div className="row justify-content-center">
                        <div className="col-lg3 col-md-6 col-sm-12 ">
                          <label className="font-weight-400">
                            Número exterior*
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleOutdoorNumberChange}
                              value={data.aval.direccionDelObligadoOAval.numeroExterior}
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              required
                              name="dzOther[OutdoorNumber]"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-lg3 col-md-6 col-sm-12">
                          <label className="font-weight-400">
                            Número interior
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={handleInteriorNumberChange}
                              value={data.aval.direccionDelObligadoOAval.numeroInterior}
                              className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                              name="dzOther[InteriorNumber]"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Colonia*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleCologneChange}
                          value={data.aval.direccionDelObligadoOAval.colonia}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Cologne]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Delegación/municipio*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleMunicipalityChange}
                          value={data.aval.direccionDelObligadoOAval.municipio}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[Municipality]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-00">Ciudad*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleCityChange}
                          value={data.aval.direccionDelObligadoOAval.ciudad}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[City]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Estado*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleStateChange}
                          value={data.aval.direccionDelObligadoOAval.estado}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[State]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">Código postal*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handlePostalCodeChange}
                          value={data.aval.direccionDelObligadoOAval.cp}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[PostalCode]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Tiempo viviendo ahí*
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={handleTimeLivingChange}
                          value={data.aval.direccionDelObligadoOAval.tiempoViviendo}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[TimeLiving]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Tipo de domicilio*
                      </label>
                      <div className="col-lg-12 border-bottom">
                        <div className="selected-box">
                          <Form.Select
                            className="form-control sm-preaprobacion"
                            onChange={handleTypeOfAddressChange}
                            value={data.aval.direccionDelObligadoOAval.tipoDeDomicilio}>
                            <option></option>
                            <option>Familiares</option>
                            <option>Renta</option>
                            <option>Hipoteca</option>
                            <option>Propia</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-weight-400">
                        Teléfono de casa con lada*
                      </label>
                      <div className="input-group">
                        <input
                          type="tel"
                          onChange={handleHomePhoneChange}
                          value={data.aval.direccionDelObligadoOAval.telefonoCasa}
                          className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                          required
                          name="dzOther[City]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="resumen-row-space"></div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <button
                        name="submit"
                        type="submit"
                        value="submit"
                        onClick={handleNext}
                        className="btn btn-primary w-100 d-block btn-rounded resumen-btn">
                        Envíar solicitud
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Paso6 = (props) => {
  const { data, setData, setActiveStep } = props;

  return (
    <>
      <div className="section-head text-center">
        <div className="dlab-post-title">
          <h2>¡Tu solicitud de pre-aprobación ha sido enviada!</h2>
          <p>
            Gracias por preferirnos. En un lapso no
            mayor a 24 hrs recibirás por correo electrónico la respuesta de tu
            solicitud y en dado caso de ser aprobada un asesor se comunicará
            contigo...
          </p>
        </div>
      </div>
    </>
  );
};




export default Preaprobacion;
