import React from "react";
import { Link } from "react-router-dom";
import CarlistingBanner from "../element/CarlistingBanner";
import { IMAGE } from "../constent/theme";
import { Autoplay, EffectFade, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Bestcar from "../components/Bestcar";
import TopDeal from "../components/TopDeal";
import ContectUs from "../components/ContectUs";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import PriceCheckOutlinedIcon from "@mui/icons-material/PriceCheckOutlined";

import ImgBannerPagos from "../assets/images/gminmediauto/banner-pagos.png";
import ImgConduceTuCarro from "../assets/images/gminmediauto/conduce-tu-carro.png";

import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaymentsIcon from '@mui/icons-material/Payments';
import StoreIcon from '@mui/icons-material/Store';
import PaidIcon from '@mui/icons-material/Paid';

const Pagos = () => {
  const progressCard = [
    {
      icon: (
        <CreditCardIcon style={{ width: 60, height: 55 }} />
      ),
      title: "Transferencia Bancaria",
      text: (
        <ul>
          <li>
            <strong>Clabe interbancaria:</strong> 012650001215960398
          </li>
          <li>
            <strong>Banco:</strong> BBVA Bancomer
          </li>
          <li>
            <strong>Nombre:</strong> GM Orientación Financiera Automotriz
          </li>
        </ul>
      ),
    },
    {
      icon: (
        <PaymentsIcon style={{ width: 60, height: 55 }} />
      ),
      title: "Depósito de banco",
      text: (
        <ul>
          <li>
            <strong>Cuenta:</strong> 0121596039
          </li>
          <li>
            <strong>Banco:</strong> BBVA Bancomer
          </li>
          <li>
            <strong>Nombre:</strong> GM Orientación Financiera Automotriz
          </li>
        </ul>
      ),
    },
    {
      icon: (
        <StoreIcon style={{ width: 60, height: 55 }} />
      ),
      title: "Pago en Tiendas de Conveniencia (OXXO, 7-Eleven, etc.)",
      text: (
        <ul>
          <li>
            <strong>Tarjeta:</strong> 4555113012646743
          </li>
          <li>
            <strong>Banco:</strong> BBVA Bancomer
          </li>
          <li>
            <strong>Nombre:</strong> GM Orientación Financiera Automotriz
          </li>
        </ul>
      ),
    },
    {
      icon: (
        <PaidIcon style={{ width: 60, height: 55 }} />
      ),
      title: "OpenPay",
      text: (
        <ul>
          <li>
            Puedes pagar desde casa con el link generado. Se cobra a través de tu
            tarjeta de débito o crédito. Para activar este servicio, por favor
            solicita la liga de cobro a tu asesor cada vez que lo requieras.
          </li>
        </ul>
      ),
    },
  ];

  return (
    <>
      <div className="page-content bg-white">
        <CarlistingBanner img={ImgBannerPagos} title={"Pagos"} />
        <section className="content-inner-2">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 m-b30 d-flex align-items-stretch">
                <div
                  className="icon-bx-wraper style-1 hover-overlay-effect background-black"
                  style={{ background: "#000" }}
                >
                  <div className="icon-md m-b40">
                    <DescriptionOutlinedIcon
                      style={{ width: 60, height: 55 }}
                    />
                  </div>
                  <div className="icon-content">
                    <h4 className="title m-b10" style={{ color: "#fff" }}>
                      Facturación
                    </h4>
                    <ol>
                      <li style={{ listStyleType: "disc", fontSize: 20 }}>
                        Puedes solicitar tu facturación en línea.
                      </li>
                      <li style={{ listStyleType: "disc", fontSize: 20 }}>
                        Solicita tu factura sin complicaciones
                      </li>
                    </ol>
                  </div>
                  <div className="effect bg-primary"></div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 m-b30 d-flex align-items-stretch">
                <div className="icon-bx-wraper style-1 hover-overlay-effect">
                  <div className="icon-md m-b40">
                    <LocalAtmOutlinedIcon style={{ width: 60, height: 55 }} />
                  </div>
                  <div className="icon-content">
                    <h4 className="title m-b10">Pago inicial</h4>
                    <p>
                      Recuerda que este pago incluye:
                      <ol>
                        <li style={{ listStyleType: "disc" }}>
                          Primera mensualidad.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Seguro del vehículo por 1 año con cobertura amplia.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Seguro de Vida.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Seguro de Desempleo.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Gastos Administrativos e impuestos.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Garantía contra fallas mecánicas (Nuevos y
                          Seminuevos).
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Instalación y mantenimiento del GPS Anti-robo.
                        </li>
                      </ol>
                    </p>
                  </div>
                  <div className="effect bg-primary"></div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 m-b30 d-flex align-items-stretch">
                <div className="icon-bx-wraper style-1 hover-overlay-effect">
                  <div className="icon-md m-b40">
                    <EventAvailableOutlinedIcon
                      style={{ width: 60, height: 55 }}
                    />
                  </div>
                  <div className="icon-content">
                    <h4 className="title m-b10">Mensualidades</h4>
                    <ol>
                      <li style={{ listStyleType: "disc", fontSize: 20 }}>
                        No te olvides de tus mensualidades. Recuerda los días de
                        tus pagos puntuales para evitar retrasos y costos
                        adicionales.
                      </li>
                    </ol>
                  </div>
                  <div className="effect bg-primary"></div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 m-b30 d-flex align-items-stretch">
                <div className="icon-bx-wraper style-1 hover-overlay-effect">
                  <div className="icon-md m-b40">
                    <PriceCheckOutlinedIcon style={{ width: 60, height: 55 }} />
                  </div>
                  <div className="icon-content">
                    <h4 className="title m-b10">Aporte a capital</h4>
                    <ol>
                      <li style={{ listStyleType: "disc" }}>
                        Sin Penalización.
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        No olvides que no cobramos ninguna penalización por
                        aportes a capital. Esto te ayudará en gran medida para
                        reajustar tus mensualidades. Es importante que avises a
                        tu asesor para poder hacer los ajustes correspondientes.
                      </li>
                    </ol>
                  </div>
                  <div className="effect bg-primary"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Pagos page >>> */}
        <section className="content-inner-2">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 me-auto">
                <div className="section-head">
                  <h6 className="text-primary sub-title">Proceso de pago</h6>
                  <h2 className="title">¿Cómo realizar tus pagos?</h2>
                </div>
              </div>
              <div className="col-lg-6 m-b30">
                <p>
                  Realizar tus pagos con GMInmediauto es un proceso sencillo y
                  seguro. Ofrecemos varias opciones para que puedas elegir la
                  que más te convenga. Aquí te explicamos detalladamente cada
                  una de ellas:
                </p>
              </div>
            </div>
            <div className="row process-wrapper m-t40">
              {progressCard.map((cards, index) => (
                <div className="col-xl-3 col-md-6" key={index}>
                  <div className="icon-bx-wraper style-1 shadow-none rounded-0">
                    <div className="icon-md m-b40">{cards.icon}</div>
                    <div className="icon-content">
                      <h4 className="title m-b10">{cards.title}</h4>
                      <div>{cards.text}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* Best Car page >>> */}
        <section className="content-inner-2">
          <div className="container">
            <div className="row features-box">
              <div className="row features-box">
                <div className="col-lg-6 align-self-center m-b30">
                  <div className="content-box right">
                    <div className="section-head">
                      <h6 className="sub-title style-1">
                        Financia tu carro con confianza
                      </h6>
                      <h2 className="title">
                        ¿Listo para conducir tu propio auto?
                      </h2>
                    </div>
                    <p className="m-b40">
                      En GMInmediauto, hacemos que comprar un auto sea fácil y
                      accesible. Con nuestros planes de financiamiento flexibles
                      y sin penalizaciones por aportes a capital, puedes tener
                      el auto de tus sueños sin complicaciones. ¡Contáctanos
                      ahora y comienza tu viaje hoy mismo!
                    </p>
                    <Link to="/contacto" className="btn btn-primary effect-1">
                      <span>Contactanos</span>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 m-b30 m-sm-b0">
                  <div className="image-slider-box">
                    <img
                      className="img1 move-1"
                      src={IMAGE.patternImg2}
                      alt=""
                    />
                    <Swiper
                      className="swiper-container feature-swiper"
                      effect="fade"
                      speed={1500}
                      autoplay={{
                        delay: 1500,
                      }}
                      modules={[EffectFade, Pagination, Autoplay]}
                      pagination={{
                        el: ".swiper-pagination",
                        clickable: true,
                      }}
                    >
                      <SwiperSlide className="swiper-slide">
                        <div className="dlab-media">
                          <img src={ImgConduceTuCarro} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Pagos;
