import React from 'react'
import  { Results } from './CompareResult'

const CarDetailCompare = () => {
  return (
    <>
      <Results />
    </>
  )
}

export default CarDetailCompare