import {
  Document,
  Text,
  Page,
  StyleSheet,
  Image,
  View,
} from "@react-pdf/renderer";

import logo from "../assets/images/gminmediauto/logo1.jpg";

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 12,
    width: "70%",
    border: "1px solid #8c8c8c",
    backgroundColor: "rgba(250, 193, 193, 1)",
    borderRadius: 5,
    padding: 5,
    margin: 1,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  column3: {
    width: "70%",
    backgroundColor: "rgba(238, 49, 49, 1)",
    fontSize: 10,
    color: "#fff",
    border: "1px solid rgba(238, 49, 49, 1)",
    borderRadius: 5,
    margin: 1,
    padding: 5,
  },
  column4: {
    fontSize: 10,
    width: "30%",
    backgroundColor: "rgba(238, 49, 49, 1)",
    color: "#fff",
    border: "1px solid rgba(238, 49, 49, 1)",
    borderRadius: 5,
    margin: 1,
    padding: 5,
  },
  column: {
    fontSize: 10,
    width: "70%",
    border: "1px solid #f0f0f0",
    borderRadius: 5,
    margin: 1,
    padding: 5,
  },
  column2: {
    fontSize: 10,
    width: "30%",
    border: "1px solid #f0f0f0",
    borderRadius: 5,
    margin: 1,
    padding: 5,
  },
  column5: {
    fontSize: 10,
    width: "70%",
    border: "1px solid #8c8c8c",
    backgroundColor: "rgba(250, 193, 193, 1)",
    borderRadius: 5,
    margin: 1,
    padding: 5,
  },
  column6: {
    fontSize: 10,
    width: "30%",
    border: "1px solid #8c8c8c",
    backgroundColor: "rgba(250, 193, 193, 1)",
    borderRadius: 5,
    margin: 1,
    padding: 5,
  },
});

const PDF = (props) => {
  const { data } = props;

  const getCurrency2FormatMX = (number) => {
    //get type of variable number
    if (typeof number === "string") {
      number = parseFloat(number);
    }
    return number.toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN", // Puedes cambiar 'MXN' por el código de moneda correspondiente
    });
  };

  const getValueByMonthly = (value, months) => {
    let valueByMonthly = 0;
    if (months === 24) {
      valueByMonthly = value[0];
    }
    if (months === 36) {
      valueByMonthly = value[1];
    }
    if (months === 48) {
      valueByMonthly = value[2];
    }
    return getCurrency2FormatMX(valueByMonthly);
  };

  const getCurrentDate = () => {
    const date = new Date();
    return date.toLocaleDateString("es-MX", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text ></Text>
            <Image src={{ uri: '../img/logo1.jpg', method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} style={{ width: 150, textAlign: "right" }} />
          </View>
        </View>
        <View >
          <View style={styles.row}>
            <Text ></Text>
            <Text style={{fontSize: 8, marginRight: 1}}>Torre Albertina en Tehuacán Sur #60,</Text>
          </View>
        </View>
        <View >
          <View style={styles.row}>
            <Text ></Text>
            <Text style={{fontSize: 8, marginRight: 1}}>Puebla, Puebla</Text>
          </View>
        </View>
        <View >
          <View style={styles.row}>
            <Text ></Text>
            <Text style={{fontSize: 8, marginRight: 1}}>222 632 3835</Text>
          </View>
        </View>
        <View style={styles.section}>
            <Text style={{fontSize: 10, marginLeft: 1, fontWeight: 700}}>Cotización de Arrendamiento Puro</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.title}>
            Vehículo: {data.auto.marca} {data.auto.linea} {data.auto.version}{" "}
            {data.auto.modelo}
          </Text>
          <Text style={styles.title}>
            Precio con IVA: {getCurrency2FormatMX(data.cotizacion.precioIVA)}
          </Text>
        </View>

        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.column3}>Renta</Text>
            <Text style={styles.column4}>{data.filtros.plazos} meses</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Renta Mensual</Text>
            <Text style={styles.column2}>
              {getValueByMonthly(
                data.cotizacion.renta.rentaMensual,
                data.filtros.plazos
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>I.V.A.</Text>
            <Text style={styles.column2}>
              {getValueByMonthly(
                data.cotizacion.renta.IVA,
                data.filtros.plazos
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Renta Total</Text>
            <Text style={styles.column6}>
              {getValueByMonthly(
                data.cotizacion.renta.rentaTotal,
                data.filtros.plazos
              )}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.column3}>Pago Inicial</Text>
            <Text style={styles.column4}>Monto</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Aportacion Inicial</Text>
            <Text style={styles.column2}>
              {getValueByMonthly(
                data.cotizacion.pagoInicial.aportacionInicial,
                data.filtros.plazos
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Seguro por el primer año</Text>
            <Text style={styles.column2}>
              {getValueByMonthly(
                data.cotizacion.pagoInicial.seguroPrimerAnio,
                data.filtros.plazos
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Localizador</Text>
            <Text style={styles.column2}>
              {getValueByMonthly(
                data.cotizacion.pagoInicial.localizador,
                data.filtros.plazos
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Comisión por apertura</Text>
            <Text style={styles.column2}>
              {getValueByMonthly(
                data.cotizacion.pagoInicial.comisionApertura,
                data.filtros.plazos
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Total Pago Inicial</Text>
            <Text style={styles.column6}>
              {getValueByMonthly(
                data.cotizacion.pagoInicial.totalPagoInicial,
                data.filtros.plazos
              )}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.column3}>Valor residual Con I.V.A.</Text>
            <Text style={styles.column6}>
              {getValueByMonthly(
                data.cotizacion.valorResidualConIVA.valorResidualConIVA,
                data.filtros.plazos
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Desembolso Total</Text>
            <Text style={styles.column2}>
              {getValueByMonthly(
                data.cotizacion.valorResidualConIVA.desembolsoTotal,
                data.filtros.plazos
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Ahorro I.S.R.</Text>
            <Text style={styles.column2}>
              {getValueByMonthly(
                data.cotizacion.valorResidualConIVA.ahorroISR,
                data.filtros.plazos
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>I.V.A. Acreditable</Text>
            <Text style={styles.column2}>
              {getValueByMonthly(
                data.cotizacion.valorResidualConIVA.IVAAcreditable,
                data.filtros.plazos
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Costo Neto</Text>
            <Text style={styles.column2}>
              {getValueByMonthly(
                data.cotizacion.valorResidualConIVA.costoNeto,
                data.filtros.plazos
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Costo Neto + Valor residual</Text>
            <Text style={styles.column2}>
              {getValueByMonthly(
                data.cotizacion.valorResidualConIVA.costoNetoMasValorResidual,
                data.filtros.plazos
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Costo Neto VS Precio de la Unidad</Text>
            <Text style={styles.column6}>
              {getValueByMonthly(
                data.cotizacion.valorResidualConIVA.costoNetoVSPrecioUnidad,
                data.filtros.plazos
              )}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.column3}>
              Cálculo Aplicado Con Opción de Gasto
            </Text>
            <Text style={styles.column4}></Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Ahorro I.S.R.</Text>
            <Text style={styles.column2}>
              {getValueByMonthly(
                data.cotizacion.calculoAplicadoConOpcionDeGasto.ahorroISR,
                data.filtros.plazos
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>I.V.A. Acreditable</Text>
            <Text style={styles.column2}>
              {getValueByMonthly(
                data.cotizacion.calculoAplicadoConOpcionDeGasto.IVAAcreditable,
                data.filtros.plazos
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Costo Neto</Text>
            <Text style={styles.column2}>
              {getValueByMonthly(
                data.cotizacion.calculoAplicadoConOpcionDeGasto.costoNeto,
                data.filtros.plazos
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Costo Neto + Valor residual</Text>
            <Text style={styles.column2}>
              {getValueByMonthly(
                data.cotizacion.calculoAplicadoConOpcionDeGasto
                  .costoNetoMasValorResidual,
                data.filtros.plazos
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Costo Neto VS Precio de la Unidad</Text>
            <Text style={styles.column6}>
              {getValueByMonthly(
                data.cotizacion.calculoAplicadoConOpcionDeGasto
                  .costoNetoVSPrecioUnidad,
                data.filtros.plazos
              )}
            </Text>
          </View>
        </View>

        {/* Repite el patrón anterior para las secciones restantes (pagoInicial, valorResidualConIVA, CalculoAplicadoConOpcionDeGasto) */}
      </Page>
    </Document>
  );
};

export default PDF;
