import React, { useEffect, useReducer, useState, } from 'react'
import { Link, useLocation } from 'react-router-dom'


export const MenusItem = [
    {
        menu: 'Política de privacidad',
        link: `/`,
        subMenu: [
            { childe: 'Política de privacidad', link: '/politicas-privacidad' },
        ]
    },
    {
        menu: 'Planes',
        link: '/new',

        subMenu: [
            { childe: 'Planes', link: '/planes' },
        ]
    },
    {
        menu: 'Cotizador',
        link: '/compare',
        subMenu: [
            { childe: 'Leasing', link: '/leasing' },
            { childe: 'Auto Financiamiento', link: '/autofinanciamiento' },
        ]
    },
    {
        menu: 'Vehículos',
        link: '/used',

        subMenu: [
            { childe: 'Vehículos', link: '/vehiculos' },
        ]
    },
    {
        menu: 'Pagos',
        link: '/used',

        subMenu: [
            { childe: 'Pagos', link: '/pagos' },
        ]
    },
    {
        menu: 'Contacto',
        link: '/compare',
        subMenu: [
            { childe: 'Contacto', link: '/contacto' },
        ]
    },
]




const Menus = ({ setOpen }) => {

    const pathname = useLocation();
    const [activeLink, setActiveLink] = useState('');

    const reducer = (previousState, updatedState) => ({
        ...previousState,
        ...updatedState,
    });
    const initialState = {
        activeSubmenu: "",
    }

    const [state, setState] = useReducer(reducer, initialState);
    const menuHandler = (status) => {
        setState({ activeSubmenu: status })
        if (state.activeSubmenu === status) {
            setState({ activeSubmenu: "" })
        }
    }
    //sub menu toggle
    const reducermenu = (menuPreviousState, menuUpdatedState) => ({
        ...menuPreviousState,
        ...menuUpdatedState,
    });
    const menuInitialState = {
        activemenu: "",
    }

    const [menuState, setMenuState] = useReducer(reducermenu, menuInitialState);
    const submenuHandler = (menuStatus) => {
        setMenuState({ activemenu: menuStatus })
        if (menuState.activemenu === menuStatus) {
            setMenuState({ activemenu: "" })
        }
    }


    useEffect(() => {
        MenusItem.map((data) => {
            data.subMenu.map((item) => {
                if (item.link === pathname.pathname) {
                    setActiveLink(data.menu);
                }
                item?.submenus?.map((el) => {
                    if (el.link === pathname.pathname) {
                        setActiveLink(data.menu);
                    }
                })
            })
        })
    }, [pathname.pathname])


    return (
        <>
            <ul className="nav navbar-nav navbar navbar-left p-t50">
                {MenusItem.map((menu, index) => {
                    return (
                        <li key={index}
                            className={
                                `sub-menu-down ${state.activeSubmenu === menu.menu ? 'open' : ''} 
                                ${menu.menu === activeLink ? "active" : ""}
                                `                            }>
                            <Link to={`#`} onClick={() => { menuHandler(menu.menu); }}>
                                {menu.menu}
                                <i className="fa fa-angle-down"></i>
                            </Link>
                            <ul className="sub-menu">
                                {menu.subMenu.map((item, ind) => {
                                    let subMenuClass = item.dropdown;
                                    if (subMenuClass === "sub-menu-down") {
                                        return (
                                            <li key={ind} className={`sub-menu-down ${menuState.activemenu === ind ? 'open' : ''}`}>
                                                <Link
                                                    onClick={() => {
                                                        submenuHandler(ind);
                                                    }}
                                                >{item.childe}<i className={`fa fa-angle-${menuState.activemenu === ind ? 'down' : 'right'}`}></i>
                                                </Link>
                                                <ul className='sub-menu'>
                                                    {item.submenus.map((childe, mapKey) => {
                                                        return (
                                                            <li key={mapKey} className='sub-menu-down'>
                                                                <Link
                                                                    to={childe.link}
                                                                >{childe.shildes}</Link>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </li>
                                        )
                                    } else {
                                        return (
                                            <li key={ind} className='sub-menu-down'>
                                                <Link to={item.link}>{item.childe}</Link>
                                            </li>
                                        )
                                    }

                                })}
                            </ul>
                        </li>
                    )
                })}
            </ul>
            <div className="dlab-social-icon p-t50">
                <ul>
                    <li><Link to={'https://www.facebook.com/profile.php?id=100086990271639'} className="fab fa-facebook-f"></Link></li>
                    {/*<li><Link to={'#'} className="fab fa-twitter"></Link></li>
                    <li><Link to={'#'} className="fab fa-linkedin-in"></Link></li>
                    <li><Link to={'#'} className="fab fa-instagram"></Link></li>*/}
                </ul>
            </div>
        </>
    )
}

export default Menus