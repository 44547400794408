import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { IMAGE } from "../constent/theme";

import imgTestimonial1 from "../assets/images/gminmediauto/testimonial1.jpg";
import imgTestimonial2 from "../assets/images/gminmediauto/testimonial2.jpg";
import imgTestimonial3 from "../assets/images/gminmediauto/testimonial3.jpg";

const testimonial = [
  { img: IMAGE.testimonialPic1 },
  { img: IMAGE.testimonialPic2 },
  { img: IMAGE.testimonialPic3 },
  { img: IMAGE.testimonialPic1 },
  { img: IMAGE.testimonialPic2 },
  { img: IMAGE.testimonialPic3 },
];
const Testimonial = () => {
  const swiperRef = React.useRef(null);
  return (
    <>
      <div className="section-content" style={{ overflow: "hidden" }}>
        <Swiper
          className="testimonial-one swiper-container"
          slidesPerView={3}
          spaceBetween={30}
          speed={1200}
          ref={swiperRef}
          breakpoints={{
            1200: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 2,
            },
            300: {
              slidesPerView: 1,
            },
          }}
        >
          <SwiperSlide className="swiper-slide">
            <div
              className="testimonial-1 wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="0.2s"
            >
              <div className="testimonial-text">
                <p>
                  Antes de GMInmediauto, había perdido la esperanza de obtener
                  un crédito automotriz. Ningún otro lugar me lo autorizaba.
                  Gracias a ellos, ahora tengo mi propio trabajo en plataformas.{" "}
                </p>
              </div>
              <div className="testimonial-detail clearfix">
                <div className="testimonial-pic quote-left radius shadow">
                  <img src={imgTestimonial1} width="100" height="100" alt="" />
                </div>
                <strong className="testimonial-name">Javier Solis</strong>{" "}
                <span className="testimonial-position">Plataforma</span>{" "}
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div
              className="testimonial-1 wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="0.2s"
            >
              <div className="testimonial-text">
                <p>
                  Al principio, fue un poco complicado porque no cumplía con los
                  requisitos para el primer plan. Estaba muy nerviosa y tenía
                  muchas dudas. Sin embargo, ahora estoy segura de recomendar
                  este excelente servicio.{" "}
                </p>
              </div>
              <div className="testimonial-detail clearfix">
                <div className="testimonial-pic quote-left radius shadow">
                  <img src={imgTestimonial2} width="100" height="100" alt="" />
                </div>
                <strong className="testimonial-name">Rosalia Martínez</strong>{" "}
                <span className="testimonial-position">Comerciante</span>{" "}
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div
              className="testimonial-1 wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="0.2s"
            >
              <div className="testimonial-text">
                <p>
                  Fue realmente extraordinario lo rápido que sucedió todo. Es
                  reconfortante saber que puedo contar con una empresa que está
                  realmente interesada en apoyar a la gente y que me brinda la
                  oportunidad de ser responsable con mis pagos.{" "}
                </p>
              </div>
              <div className="testimonial-detail clearfix">
                <div className="testimonial-pic quote-left radius shadow">
                  <img src={imgTestimonial3} width="100" height="100" alt="" />
                </div>
                <strong className="testimonial-name">
                  Andrea Jhoanna Domínguez
                </strong>{" "}
                <span className="testimonial-position">Empleada</span>{" "}
              </div>
            </div>
          </SwiperSlide>
          <div className="testimonial-pagination text-center m-t50">
            <div
              className="btn-prev swiper-button-prev7 cursorPointer"
              onClick={() => {
                swiperRef.current.swiper.slidePrev();
              }}
            >
              <i className="las la-arrow-left"></i>
            </div>
            <div
              className="btn-next swiper-button-next7 cursorPointer"
              onClick={() => {
                swiperRef.current.swiper.slideNext();
              }}
            >
              <i className="las la-arrow-right"></i>
            </div>
          </div>
        </Swiper>
      </div>
    </>
  );
};

export default Testimonial;
