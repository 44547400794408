import React from "react";
import { Link } from "react-router-dom";
import MultiRangeSlider from "../components/MultiRangeSlider";
import { Form } from "react-bootstrap";
import RangeSliderMUI from "../components/RangeSliderMUI";

const FiltroCotizador = (props) => {
  const { data, setData } = props;
  return (
    <>
      <div className="col-xl-4 col-lg-4 m-b30">
        <aside className="side-bar sticky-top left">
          <div className="section-head"></div>
          <form>
            <div className="widget widget_search">
              <div className="form-group m-b20">
                <label className="font-weight-400">Personalidad Fiscal</label>
                <div className="col-lg-12 border-bottom">
                  <div className="selected-box">
                    <Form.Select
                      className="form-control sm-preaprobacion"
                      name="select"
                      id="select"
                      //onChange={handleYouHaveSpouseChange}
                      //value={data.referencias.datosConyuge.tienesConyuge}
                    >
                      <option>Fisica</option>
                      <option>Fisica con actividad empresarial</option>
                      <option>Moral</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div>

            <div className="widget widget_price_range">
              <h6 className="sub-title style-1">{"Configura tu crédito"}</h6>
              <RangeSliderMUI data={data} setData={setData} min={24} max={48} steps={12}/>
            </div>

            <div className="widget widget_others">
              <div className="form-group m-b20">
                <label className="font-weight-400">Aseguradora</label>
                <div className="col-lg-12 border-bottom">
                  <div className="selected-box">
                    <Form.Select
                      className="form-control sm-preaprobacion"
                      name="select"
                      id="select"
                      //onChange={handleYouHaveSpouseChange}
                      //value={data.referencias.datosConyuge.tienesConyuge}
                    >
                      <option>Quálitas</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
              <div className="form-group m-b20">
                <label className="font-weight-400">GPS</label>
                <div className="col-lg-12 border-bottom">
                  <div className="selected-box">
                    <Form.Select
                      className="form-control sm-preaprobacion"
                      name="select"
                      id="select"
                      //onChange={handleYouHaveSpouseChange}
                      //value={data.referencias.datosConyuge.tienesConyuge}
                    >
                      <option>Si</option>
                      <option>No</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
              <div className="form-group m-b20">
                <label className="font-weight-400">Seguro de desempleo</label>
                <div className="col-lg-12 border-bottom">
                  <div className="selected-box">
                    <Form.Select
                      className="form-control sm-preaprobacion"
                      name="select"
                      id="select"
                      //onChange={handleYouHaveSpouseChange}
                      //value={data.referencias.datosConyuge.tienesConyuge}
                    >
                      <option>Si</option>
                      <option>No</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
              <div className="form-group m-b20">
                <label className="font-weight-400">Seguro de vida</label>
                <div className="col-lg-12 border-bottom">
                  <div className="selected-box">
                    <Form.Select
                      className="form-control sm-preaprobacion"
                      name="select"
                      id="select"
                      //onChange={handleYouHaveSpouseChange}
                      //value={data.referencias.datosConyuge.tienesConyuge}
                    >
                      <option>Si</option>
                      <option>No</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </aside>
      </div>
    </>
  );
};

export default FiltroCotizador;
