import React, {useState} from "react";
import { Link } from "react-router-dom";
import MultiRangeSlider from "../components/MultiRangeSlider";
import { Form } from "react-bootstrap";
import RangeSliderMUI from "../components/RangeSliderMUIAutoFinanciamiento";

const FiltroCotizadorAutoFinanciamiento = (props) => {
  const { data, setData } = props;
  const [hitch, setHitch] = useState(data.filtros.enganche);
  const [errorMinHitch, setErrorMinHitch] = useState(false);

  const onlyNumbers = (e) => {
    if (isNaN(e.key) && e.key !== 'Backspace') {
      e.preventDefault();
    }
  };

  const getCurrency2FormatMX = (number) => {
    //get type of variable number
    if (typeof number === "string") {
      number = parseFloat(number);
    }
    return number.toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN", // Puedes cambiar 'MXN' por el código de moneda correspondiente
    });
  };

  const calculateCarFinancingHitch = (hitch) => {
    let auxMonths = ((data.auto.precioLista - (hitch - data.auto.pagoInicial)) * 0.019935) > 3500 ? 60 : (((data.auto.precioLista - (hitch - data.auto.pagoInicial)) * 0.019935 * 60) / 3500);
    let auxMonthlyPayment = ((data.auto.precioLista - (hitch - data.auto.pagoInicial)) * 0.019935) > 3500 ? ((data.auto.precioLista - (hitch - data.auto.pagoInicial)) * 0.019935) : 3500;
    let auxTotalCost = hitch  + ((auxMonths -1) * auxMonthlyPayment);

    /* auxMonths = Math.ceil(auxMonths);
    auxMonthlyPayment = Math.ceil(auxMonthlyPayment);
    auxTotalCost = Math.ceil(auxTotalCost); */

    setData({
      ...data,
      filtros: {
        ...data.filtros,
        enganche: hitch,
        plazos: auxMonths,
      },
      cotizacion: {
        ...data.cotizacion,
        mensualidad: auxMonthlyPayment,
        costoTotal: auxTotalCost,
      },
    });

  }; 

  const handleHitchChange = (e) => {
      setHitch(e.target.value);
  };

  const submitHitch = () => {
    const hitchInt = parseInt(hitch);
    if(hitchInt >= data.filtros.engancheMinimo){
      calculateCarFinancingHitch(hitchInt);
      setErrorMinHitch(false);
    }
    else{
      alert(`El enganche mínimo es de ${getCurrency2FormatMX(data.filtros.engancheMinimo)}, por favor ponga un enganche mayor o igual a este.`);
      setErrorMinHitch(true);
    }
  }
  

  return (
    <>
      <div className="col-xl-4 col-lg-4 m-b30">
        <aside className="side-bar sticky-top left">
          <div className="section-head"></div>
          <div>
            <div className="widget widget_search">
              <div className="form-group m-b20">
                <label className="font-weight-400">Personalidad Fiscal</label>
                <div className="col-lg-12 border-bottom">
                  <div className="selected-box">
                    <Form.Select
                      className="form-control sm-preaprobacion"
                      name="select"
                      id="select"
                    >
                      <option>Fisica</option>
                      <option>Fisica con actividad empresarial</option>
                      <option>Moral</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div>
            {/*input para el enganche*/}
            <div className="widget widget_hitch">
              <label className="font-weight-400">Apertura (Min. {data.filtros.engancheMinimo})</label>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control border-start-0 border-end-0 border-top-0 preaprobacion"
                  name="dzHitch"
                  value={hitch}
                  onChange={handleHitchChange}
                  onKeyDown={onlyNumbers}
                />
                <span className="input-group-btn">
                  <button
                    type="submit"
                    className="btn btn-primary sharp radius-no"
                    onClick={submitHitch}
                  >
                    <i className="la la-arrow-circle-right scale3"></i>
                  </button>
                </span>
              </div>
              {errorMinHitch && <span style={{ color: 'red', fontSize: 13, fontWeight: 400 }}>Digita un monto de apertura mayor a {getCurrency2FormatMX(data.filtros.engancheMinimo)}</span>}
            </div>

            <div className="widget widget_price_range">
              <h6 className="sub-title style-1">{"Configura tu crédito"}</h6>
              <RangeSliderMUI
                data={data}
                setData={setData}
                min={12}
                max={60}
                steps={1}
              />
            </div>

            {/* <div className="widget widget_others">
              <div className="form-group m-b20">
                <label className="font-weight-400">Aseguradora</label>
                <div className="col-lg-12 border-bottom">
                  <div className="selected-box">
                    <Form.Select
                      className="form-control sm-preaprobacion"
                      name="select"
                      id="select"
                    >
                      <option>Quálitas</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
              <div className="form-group m-b20">
                <label className="font-weight-400">GPS</label>
                <div className="col-lg-12 border-bottom">
                  <div className="selected-box">
                    <Form.Select
                      className="form-control sm-preaprobacion"
                      name="select"
                      id="select"
                    >
                      <option>Si</option>
                      <option>No</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
              <div className="form-group m-b20">
                <label className="font-weight-400">Seguro de desempleo</label>
                <div className="col-lg-12 border-bottom">
                  <div className="selected-box">
                    <Form.Select
                      className="form-control sm-preaprobacion"
                      name="select"
                      id="select"
                    >
                      <option>Si</option>
                      <option>No</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
              <div className="form-group m-b20">
                <label className="font-weight-400">Seguro de vida</label>
                <div className="col-lg-12 border-bottom">
                  <div className="selected-box">
                    <Form.Select
                      className="form-control sm-preaprobacion"
                      name="select"
                      id="select"
                    >
                      <option>Si</option>
                      <option>No</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </aside>
      </div>
    </>
  );
};

export default FiltroCotizadorAutoFinanciamiento;
