import {
  Document,
  Text,
  Page,
  StyleSheet,
  Image,
  View,
} from "@react-pdf/renderer";

import Logo from "../assets/images/gminmediauto/logo1.jpg";

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  section: {
    marginBottom: 10,
  },
  mainTitle: {
    fontSize: 12,
    fontWeight: 700,
    color: "rgba(238, 49, 49, 1)",
  },
  title: {
    fontSize: 10,
    width: "50%",
    border: "2px solid #f0f0f0",
    borderRadius: 5,
    margin: 1,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  column3: {
    width: "70%",
    backgroundColor: "rgba(238, 49, 49, 1)",
    fontSize: 10,
    color: "#fff",
    border: "1px solid rgba(238, 49, 49, 1)",
    borderRadius: 5,
    margin: 1,
    padding: 5,
  },
  column4: {
    fontSize: 10,
    width: "30%",
    backgroundColor: "rgba(238, 49, 49, 1)",
    color: "#fff",
    border: "1px solid rgba(238, 49, 49, 1)",
    borderRadius: 5,
    margin: 1,
    padding: 5,
  },
  column: {
    fontSize: 10,
    width: "70%",
    border: "1px solid #f0f0f0",
    borderRadius: 5,
    margin: 1,
    padding: 5,
  },
  column2: {
    fontSize: 10,
    width: "30%",
    border: "1px solid #f0f0f0",
    borderRadius: 5,
    margin: 1,
    padding: 5,
  },
  column5: {
    fontSize: 10,
    width: "70%",
    border: "1px solid #8c8c8c",
    backgroundColor: "rgba(250, 193, 193, 1)",
    borderRadius: 5,
    margin: 1,
    padding: 5,
  },
  column6: {
    fontSize: 10,
    width: "30%",
    border: "1px solid #8c8c8c",
    backgroundColor: "rgba(250, 193, 193, 1)",
    borderRadius: 5,
    margin: 1,
    padding: 5,
  },
});

const PDF = (props) => {
  const { data } = props;

  const getCurrency2FormatMX = (number) => {
    //get type of variable number
    if (typeof number === "string") {
      number = parseFloat(number);
    }
    return number.toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN", // Puedes cambiar 'MXN' por el código de moneda correspondiente
    });
  };

  const getValueByMonthly = (value, months) => {
    let valueByMonthly = 0;
    if (months === 24) {
      valueByMonthly = value[0];
    }
    if (months === 36) {
      valueByMonthly = value[1];
    }
    if (months === 48) {
      valueByMonthly = value[2];
    }
    return getCurrency2FormatMX(valueByMonthly);
  };

  const getCurrentDate = () => {
    const date = new Date();
    return date.toLocaleDateString("es-MX", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.mainTitle}>
              COTIZACIÓN DE AUTO FINANCIAMIENTO
            </Text>
            <Image src={{ uri: '../img/logo1.jpg', method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} style={{ width: 150, textAlign: "right" }} />
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <Text></Text>
            <Text style={{ fontSize: 8, marginRight: 1 }}>
              Col. La Paz  en Tehuacán Sur #60,
            </Text>
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <Text></Text>
            <Text style={{ fontSize: 8, marginRight: 1 }}>Puebla, Puebla</Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.row}>
            <View style={{marginTop: 50, marginBottom: 30}}>
              <Text style={styles.title}>
                Vehículo: {data.auto.marca} {data.auto.linea}{" "}
                {data.auto.version} {data.auto.modelo}{" "}
                {data.auto.descripcionAdicional}
              </Text>
              <Text style={styles.title}>
                Costo Total: {getCurrency2FormatMX(data.cotizacion.costoTotal)}
              </Text>
              <Text style={styles.title}>
                Transmisión: {data.auto.transmision}
              </Text>
              <Text style={styles.title}>
                Carrocería: {data.auto.carroceria}
              </Text>
              <Text style={styles.title}>
                Combustible: {data.auto.combustible}
              </Text>
              <Text style={styles.title}>
                Kilometraje: {data.auto.kilometraje}
              </Text>
            </View>

            <View style={{width: "40%"}}>
              <Image src={{ uri: '../imgAutosAF/'+data.auto.fotografiaPrincipal+'/1.png', method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} style={{ width: "60%", marginTop: 50}} />
            </View>

          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.column3}>Periodo de pago</Text>
            <Text style={styles.column4}>{data.filtros.plazos} meses</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Mensualidades de</Text>
            <Text style={styles.column2}>
              {getCurrency2FormatMX(data.cotizacion.mensualidad)}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>El pago de inscripción es de</Text>
            <Text style={styles.column2}>
              {getCurrency2FormatMX(data.filtros.enganche)}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.column}>Costo total</Text>
            <Text style={styles.column6}>
              {getCurrency2FormatMX(data.cotizacion.costoTotal)}
            </Text>
          </View>
        </View>

        <Text style={{ fontSize: 10, textAlign: "center", marginTop: 30 }}>¡Listo para estrenar tu auto nuevo? Si la cotización te convence, puedes solicitar tu preaprobación en</Text>
        <Text style={{ fontSize: 10, textAlign: "center", color: "rgba(238, 49, 49, 1)" }}>https://gminmediauto.com.mx/autofinanciamiento</Text>
        <Text style={{ fontSize: 10, textAlign: "center" }}>o comunicarte con uno de nuestros asesores de ventas al número 221 433 3335.</Text>
        <Text style={{ fontSize: 10, textAlign: "center" }}>Horario Lun - Vie. 10:00 am - 6:00 pm.</Text>
        <Text style={{ fontSize: 10, textAlign: "center" }}>Sábado. 10:00 am - 3:00 pm.</Text>
        <Text style={{ fontSize: 10, textAlign: "center" }}>Domingo. 11:00 am - 3:00 pm.</Text>
        <Text style={{ fontSize: 10, textAlign: "center" }}>Atendemos tu cita, Agendala Aquí.</Text>
        <Text style={{ fontSize: 10, textAlign: "center" }}>¡Hazlo realidad ahora mismo!</Text>

        {/* Repite el patrón anterior para las secciones restantes (pagoInicial, valorResidualConIVA, CalculoAplicadoConOpcionDeGasto) */}
      </Page>
    </Document>
  );
};

export default PDF;
